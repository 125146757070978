// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import NavbarLandingJobs from "./NavbarLandingJobs";
import Loader from "./Loader"; // Importa el componente Loader

const FalabellaLayoutDark = (props) => {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    // Simula un tiempo de carga
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loader /> // Muestra el Loader mientras se carga
        ) : (
          <Fragment>
            <NavbarLandingJobs transparent variant="dark" />
            <div className="py-lg-16">{props.children}</div>
            <Footer id="FOOTER" />
          </Fragment>
      )}
    </Fragment>
  );
};

export default FalabellaLayoutDark;
