import React, {useState} from'react';
import MensajesIngles from './../lang/en-US.json';
import MensajesEspanol from './../lang/es-ES.json';
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({children}) =>{
    let localePorDefecto;
    let mensajesPorDefecto;

    let url =window.location.pathname;
    const lang = localStorage.getItem('lang');

    if(lang)
    {
        localePorDefecto = lang;
        if(lang === 'es-ES')
        {   
            mensajesPorDefecto = MensajesEspanol;
        } else if(lang === 'en-US'){

            mensajesPorDefecto = MensajesIngles;

        } else {
            localePorDefecto='es-ES';
            mensajesPorDefecto = MensajesEspanol;
        }

    }else{
        localePorDefecto='es-ES';
        mensajesPorDefecto = MensajesEspanol;
    }

    if(url === "/es")
    {
        localePorDefecto='es-ES';
        mensajesPorDefecto = MensajesEspanol;
    }else if(url === "/en")
    {
        localePorDefecto='en-US';
        mensajesPorDefecto = MensajesIngles;
    }else
    {
        localePorDefecto='es-ES';
        mensajesPorDefecto = MensajesEspanol;
    }



    const [mensajes,establecerMensajes] = useState(mensajesPorDefecto); 
    const [locale,establecerLocale] = useState(localePorDefecto); 

    const establecerLenguaje = (lenguaje) =>{
        switch (lenguaje) {
            case 'es-ES':
                establecerMensajes(MensajesEspanol);
                establecerLocale('es-ES');
                localStorage.setItem('lang','es-ES');
                
                break;
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang','en-US');
                
                break;
            default:
                establecerMensajes(MensajesEspanol);
                localStorage.setItem('lang','es-ES');
                establecerLocale('es-ES');
        }
    };

    return(
        <langContext.Provider value={{ establecerLenguaje: establecerLenguaje }}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export{LangProvider, langContext};