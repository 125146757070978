// ** Import from react dom
import { Route, Switch, Redirect, useLocation, useHistory} from "react-router-dom";

// ** Import core SCSS styles
import "assets/scss/_theme.scss";
import FalabellaIndex from "layouts/falabella/FalabellaIndex";
import FalabellaInternoIndex from "layouts/falabella/FalabellaInternoIndex";
import FalabellaTechIndex from "layouts/falabella/FalabellaTechIndex";
import FalabellaJovenesIndex from "layouts/falabella/FalabellaJovenesIndex";
import FalabellaLayout from "./falabella/FalabellaLayout";
import FalabellaLayoutTech from "./falabella/FalabellaLayoutTech";
import FalabellaInternoLayout from "./falabella/FalabellaInternoLayout";
import FalabellaLayoutDark from "./falabella/FalabellaLayoutDark";
import FalabellaExplore from "./falabella/FalabellaExplore";
import OfferDetail from "components/falabella/explore/OfferDetail";
import { FilterContext } from "../hooks/FilterContext";
import { useFilters } from "../hooks/useFilters";
import Falabella404 from "./falabella/Falabella404";
import FalabellaLayoutJovenes from "./falabella/FalabellaLayoutJovenes";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";


const AppRoute = ({
  component: Component,
  layout: Layout,
  render: renderFn,
  extraProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>{renderFn ? renderFn(props) : <Component {...props} extraProps={extraProps} />}</Layout>
    )}
  ></Route>
);
const validCommerces = [
  "Sodimac",
  "Tottus",
  "Falabella.com",
  "Falabella Corporativo",
  "IKEA",
  "Fpay",
  "Fazil",
  "Falabella Soriana",
  "Falabella Retail",
  "Banco Falabella",
  "Falabella Financiero",
  "Seguros Falabella",
  "Linio",
  "Mallplaza",
  "Falabella Inmobiliario",
];

const validCountryMap = {
  chile: "Chile",
  argentina: "Argentina",
  peru: "Perú",
  colombia: "Colombia",
  mexico: "México",
  india: "India",
};

const validCommerceMap = validCommerces.reduce(
  (acc, commerce) => ({
    ...acc,
    [commerce.replace(/\s/g, "").toLowerCase()]: commerce,
  }),
  {}
);

function removeAccents(str) {
  const accents = "ÁÉÍÓÚáéíóú";
  const accentsOut = "AEIOUaeiou";
  str = str.split("");
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  });
  return str.join("");
}

function FalabellaRoutes() {
  const location = useLocation();
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak(); // Obtén también 'initialized' de useKeycloak
  const [isLoginInitiated, setIsLoginInitiated] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const isInternalPath = pathParts.includes('internal');
    const isAuthenticated = keycloak.authenticated;

    // Asegúrate de que keycloak esté inicializado y que no se haya iniciado un login previamente.
    if (isInternalPath && !isAuthenticated && !isLoginInitiated && initialized) {
      setIsLoginInitiated(true);
      keycloak.login({
        redirectUri: window.location.origin + location.pathname
      });
    } else if (!isInternalPath || isAuthenticated) {
      setIsLoginInitiated(false);
    }
  }, [history,keycloak, location.pathname, isLoginInitiated, initialized]);

  
  const [filters, setFilters] = useFilters({
    country: [],
    area: [],
    company: [],
    jobtype: [],
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      <Switch>
        <Route exact path="/fpay" render={() => <Redirect to="/bancofalabella" />} />
        <Route exact path="/fazil" render={() => <Redirect to="/tottus" />} />
        <AppRoute
          exact
          path="/sodimac"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "sodimac" }}
        />
        <AppRoute
          exact
          path="/tottus"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "tottus" }}
        />
        <AppRoute
          exact
          path="/linio"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellaretail" }}
        />
        <AppRoute
          exact
          path="/falabellacorp"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellacorp" }}
        />
        <AppRoute
          exact
          path="/falabellaretail"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellaretail" }}
        />
        <AppRoute
          exact
          path="/falabellainmobiliario"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellainmobiliario" }}
        />
        <AppRoute
          exact
          path="/falabellafinanciero"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellafinanciero" }}
        />
        <AppRoute
          exact
          path="/falabellasoriana"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "falabellasoriana" }}
        />
        <AppRoute
          exact
          path="/segurosfalabella"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "segurosfalabella" }}
        />
        <AppRoute
          exact
          path="/mallplaza"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "mallplaza" }}
        />
        <AppRoute
          exact
          path="/bancofalabella"
          layout={FalabellaInternoLayout}
          component={FalabellaInternoIndex}
          extraProps={{ variante: "bancofalabella" }}
        />

        <AppRoute
          exact
          path="/explorar/:country/:commerce"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          render={(props) => {
            let paisParam = props.match.params.country;
            let commerceParam = props.match.params.commerce;
            let variante = "explorar";

            let paisParamNormalized = removeAccents(paisParam).toLowerCase();
            let pais = validCountryMap[paisParamNormalized] || "";
            let commerce =
              (commerceParam &&
                validCommerceMap[
                  commerceParam.replace(/\s/g, "").toLowerCase()
                ]) ||
              commerceParam;
            return (
              <FalabellaExplore
                extraProps={{
                  pais,
                  commerce,
                  variante,
                }}
              />
            );
          }}
        />
        <AppRoute
          exact
          path="/explorar/:country"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          render={(props) => {
            let paisParam = props.match.params.country;
            let variante = "explorar";

            let pais =
              (paisParam &&
                validCountryMap[removeAccents(paisParam).toLowerCase()]) ||
              paisParam;

            return (
              <FalabellaExplore
                extraProps={{
                  pais,
                  variante,
                }}
              />
            );
          }}
        />

        <AppRoute
          exact
          path="/explorar"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          extraProps={{ variante: "explorar" }}
        />
        <AppRoute
          exact
          path="/explorar-tech"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          extraProps={{ variante: "explorar" }}
        />
        <AppRoute
          exact
          path="/explorar-jovenes"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          extraProps={{ variante: "explorar" }}
        />
        <AppRoute
          exact
          path="/muevete/:filter/:value"
          layout={FalabellaLayoutDark}
          component={FalabellaExplore}
          extraProps={{ variante: "muevete" }}
        />
        <AppRoute
          exact
          path="/muevete"
          layout={FalabellaLayout}
          component={FalabellaExplore}
          extraProps={{ variante: "muevete" }}
        />
        <AppRoute
          exact
          path="/detalle-oferta/:id/:type"
          layout={FalabellaLayoutDark}
          component={OfferDetail}
        />
        <AppRoute
          exact
          path="/"
          layout={FalabellaLayout}
          component={FalabellaIndex}
          extraProps=""
        />
        <AppRoute
          exact
          path="/es"
          layout={FalabellaLayout}
          component={FalabellaIndex}
          extraProps={{ langVar: "es-ES" }}
        />
        <AppRoute
          exact
          path="/en"
          layout={FalabellaLayout}
          component={FalabellaIndex}
          extraProps={{ langVar: "en-US" }}
        />
        <AppRoute
          exact
          path="/falabellatech"
          layout={FalabellaLayoutTech}
          component={FalabellaTechIndex}
          extraProps=""
        />
        <AppRoute
          exact
          path="/jovenes-profesionales"
          layout={FalabellaLayoutJovenes}
          component={FalabellaJovenesIndex}
          extraProps=""
        />
        <AppRoute
          path="/"
          layout={FalabellaLayoutDark}
          component={Falabella404}
        />
      </Switch>
    </FilterContext.Provider>
  );
}

export default FalabellaRoutes;
