/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import ItemViveExperienciaCargo from "./components/ItemViveExperienciaCargo";
import Head from "./components/headInterno";
import { OfertasLaboralesService } from "../../../../services/OfertasLaborales.service";
import { Spinner } from "react-bootstrap";
import "./ViveExperienciaCargos.style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dataEmpresas from "../../../falabella/explore/Filters/mueveteData.json";
import { useKeycloak } from "@react-keycloak/web";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={
        "arrowDerechaViveExperienciaCargo slick-next-ViveExperienciaCargo"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaCargo slick-prev-ViveExperienciaCargo"}
      onClick={onClick}
    ></div>
  );
}

export const logos = [
  {
    id: "1",
    srcWeb: require("assets/images/img/logos-color/corporativo.png"),
    company: "Falabella Equipo Corporativo",
  },
  {
    id: "2",
    srcWeb: require("assets/images/img/logos-color/falabella-retail.png"),
    company: "Falabella Retail",
  },
  {
    id: "3",
    srcWeb: require("assets/images/img/logos-color/sodimac.png"),
    company: "Sodimac",
  },
  {
    id: "4",
    srcWeb: require("assets/images/img/logos-color/tottus.png"),
    company: "Tottus",
  },
  {
    id: "5",
    srcWeb: require("assets/images/img/logos-color/banco.png"),
    company: "Banco Falabella",
  },
  {
    id: "6",
    srcWeb: require("assets/images/img/logos-color/linio.png"),
    company: "Linio",
  },
  {
    id: "7",
    srcWeb: require("assets/images/img/logos-color/mallplaza.png"),
    company: "Mallplaza",
  },
  {
    id: "8",
    srcWeb: require("assets/images/img/logos-color/inmobiliario.png"),
    company: "Falabella Inmobiliario",
  },
  {
    id: "11",
    srcWeb: require("assets/images/img/logos-color/fcom.png"),
    company: "Falabella.com",
  },
  {
    id: "12",
    srcWeb: require("assets/images/img/logos-color/tottus.png"),
    company: "Tottus Chile",
  },
  {
    id: "13",
    srcWeb: require("assets/images/img/logos-color/falabellafinanciero.png"),
    company: "Falabella Financiero",
  },
  {
    id: "14",
    srcWeb: require("assets/images/img/logos-color/falabellasoriana.png"),
    company: "Falabella Soriana",
  },
  {
    id: "15",
    srcWeb: require("assets/images/img/logos-color/segurosfalabella.png"),
    company: "Seguros Falabella",
  },
  {
    id: "16",
    srcWeb: require("assets/images/img/logos-color/inmobiliario.png"),
    company: "Falabella Inmobiliario",
  },
];

const ViveExperienciaCargos = (param) => {
      const { keycloak } = useKeycloak();

  let data;
  const filterJson = dataEmpresas.filter(function (dataSource) {
    if (param.format === "Falabella Equipo Corporativo") {
      data = "Falabella Corporativo";
    } else {
      data = param.format;
    }
    return dataSource.muevete === data;
  });
  const valoresEspecificosCompay = filterJson.map((element) => element.muevete);
  const uniqueArrayCompany = [...new Set(valoresEspecificosCompay)];

  let filter = {
    country: [],
    area: [],
    company: uniqueArrayCompany,
    jobtype: [],
    page: 1,
    perPage: 50,
    search: "",
    type: "external",
  };

  const [offerList, setOfferList] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCompanyLogo = () => {
    const logo = logos.find((item) => {
      return item.company === param.format;
    });

    if (logo) {
      return logo.srcWeb;
    }

    return require("assets/images/img/logos-color/corporativo.png");
  };

  const getIconByCountry = (country) => {
    switch (country) {
      case "Colombia":
        return require("assets/images/falabella/banderas/colombia.png");
      case "Perú":
        return require("assets/images/falabella/banderas/peru.png");
      case "Argentina":
        return require("assets/images/falabella/banderas/argentina.png");
      case "Chile":
        return require("assets/images/falabella/banderas/chile.png");
      case "Uruguay":
        return require("assets/images/falabella/banderas/uruguay.png");
      case "Brasil":
        return require("assets/images/falabella/banderas/brasil.png");
      case "México":
        return require("assets/images/falabella/banderas/mexico.png");
      case "China":
        return require("assets/images/falabella/banderas/china.png");
      case "India":
        return require("assets/images/falabella/banderas/india.png");
      default:
        return "";
    }
  };
  const minSlidesToShow = Math.min(offerList.length, 4);

  const settings = {
    infinite: true,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useLayoutEffect(() => {
    const getOfertasLaborales = async () => {
      setLoading(true);
      const offerType = keycloak.authenticated ? "internal" : "external";
      const service = new OfertasLaboralesService(offerType);
      const response = await service.getOfertasLaboralesByFilter(filter);
      setLoading(false);
      setOfferList(response.data.data.list);
    };
    getOfertasLaborales();
  }, []);

  return (
    <div
      className="py-8 bg-experiencia-cargos"
      id="ofertasinternos"
      name="ofertasinternos"
    >
      {offerList.length <= 3 ? (
        <div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="container">
              <Head format={param.format} />
              <Row>
                {offerList.length > 0 &&
                  offerList.map((item, index) => (
                    <Col xs lg="4">
                      <ItemViveExperienciaCargo
                        key={index}
                        item={item}
                        extraclass="mx-2"
                        logo={getCompanyLogo()}
                        icon={getIconByCountry(item.country)}
                      />
                    </Col>
                  ))}
              </Row>
            </div>
          )}
        </div>
      ) : (
        <div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="container">
              <Head format={param.format} />
              <Slider
                {...settings}
                className="pb-sm-5 mb-5 slick-slider-wrapper"
              >
                {offerList.length > 0 &&
                  offerList.map((item, index) => (
                    <div
                      className="px-md-1 wow zoomIn"
                      data-wow-duration="1s"
                      data-wow-delay={`${index * 0.2}s`}
                      data-wow-offset="5"
                      key={item.offer_id}
                    >
                      <ItemViveExperienciaCargo
                        key={index}
                        item={item}
                        extraclass="mx-2"
                        logo={getCompanyLogo(item.company)}
                        icon={getIconByCountry(item.country)}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViveExperienciaCargos;
