import { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import FalabellaRoutes from "layouts/FalabellaRoutes";
import ScrollToTop from "components/falabella/common/ScrollToTop";
import { useIntl } from "react-intl";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak/keycloak";

function App() {
  const intl = useIntl();

  return (
    <Router>
      <LocationEffect intl={intl} />
      <div className="App bg-white">
          <ReactKeycloakProvider authClient={keycloak}>
          <ScrollToTop />
          <FalabellaRoutes />
        </ReactKeycloakProvider>
      </div>
    </Router>
  );
}

const validCommerces = {
  sodimac: "Sodimac",
  tottus: "Tottus",
  falabellacorp: "Falabella Corporativo",
  ikea: "IKEA",
  fpay: "Fpay",
  fazil: "Fazil",
  falabellasoriana: "Falabella Soriana",
  falabellaretail: "Falabella Retail",
  bancofalabella: "Banco Falabella",
  falabellafinanciero: "Falabella Financiero",
  segurosfalabella: "Seguros Falabella",
  linio: "Falabella.com",
  mallplaza: "Mallplaza",
  falabellainmobiliario: "Falabella Inmobiliario",
};
function LocationEffect({ intl }) {
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    let currentPath = pathParts[1];

    if (intl.locale === "es-ES") {
      document.title = "Trabaja en Falabella - Empleos en el Grupo Falabella";
    } else if (intl.locale === "en-US") {
      document.title = "Falabella Careers - Jobs in the Holding Falabella";
    }

    if (currentPath === "explorar") {
      const country = pathParts[2];
      const commerce = pathParts[3];
      if (commerce && validCommerces[commerce.toLowerCase()]) {
        document.title += ` - ${
          validCommerces[commerce.toLowerCase()]
        } ${capitalizeFirstLetter(country)}`;
      } else if (country) {
        document.title += ` - ${capitalizeFirstLetter(country)}`;
      }
    } else if (currentPath && validCommerces[currentPath.toLowerCase()]) {
      document.title += ` - Portal ${
        validCommerces[currentPath.toLowerCase()]
      }`;
    } else if (currentPath) {
      document.title += ` - ${capitalizeFirstLetter(currentPath)}`;
    }
  }, [intl.locale, location.pathname]);

  return null;
}

// Función para capitalizar la primera letra de una palabra
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default App;