import { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import ItemViveExperiencia from "./components/ItemViveExperiencia";
import { AllExperienciaData } from "./components/AllData";
import { OfertasLaboralesService } from "../../../../services/OfertasLaborales.service";
import Head from "./components/head";
import "./ViveExperiencia.style.css";
import { Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowDerechaViveExperiencia slick-next-ViveExperiencia"}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperiencia slick-prev-ViveExperiencia"}
      onClick={onClick}
    ></div>
  );
}




const ViveExperiencia = () => {
  const [offerList, setOfferList] = useState([]);
  const [loading, setLoading] = useState(true);
    const { keycloak } = useKeycloak();
    const minSlidesToShow = Math.min(offerList.length, 4);

  const settings = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: minSlidesToShow,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  
  useLayoutEffect(() => {
    const getOfertasLaborales = async () => {
      setLoading(true);
      const offerType = keycloak.authenticated ? "internal" : "external";
  
      const service = new OfertasLaboralesService(offerType);
      try {
        const response = await service.getOfertasLaborales("1/12");
        setOfferList(response.data);
      } catch (error) {
        console.error("Error al obtener las ofertas laborales", error);
      } finally {
        setLoading(false);
      }
    };
  
    getOfertasLaborales();
  }, [keycloak.authenticated]);
  return (
    <div className="py-8 bg-experiencia w-100" name="areas" id="areas">
       {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
      <div className="container">
        <Head />
        <Slider {...settings}>
          {AllExperienciaData.map((item, index) => (
            <div
              className="px-md-1 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay={`${index * 0.2}s`}
              data-wow-offset="5"
              key={index}
            >
              <ItemViveExperiencia
                key={item.id}
                item={item}
                extraclass="mx-2"
              />
            </div>
          ))}
        </Slider>
      </div>
       )}
    </div>
  );
};

export default ViveExperiencia;
