import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'

const Paginate = (props) => {
  const [limitPages, setLimitPages] = useState(0)
  const [startCountPage, setStartCountPage] = useState(0)

  const calculateStartCountPage = useCallback(() => {
    let start =
      props.currentPage - Math.floor(limitPages / 2) > 0 &&
      props.totalPages > limitPages
        ? props.currentPage - Math.floor(limitPages / 2)
        : 0
    if (start + limitPages > props.totalPages) {
      start = props.totalPages - limitPages
    }
    return start
  }, [props.currentPage, props.totalPages, limitPages])

  useEffect(() => {
    setStartCountPage(calculateStartCountPage())
  }, [calculateStartCountPage, props.currentPage, props.totalPages])

  const {
    totalPages,
    currentPage,
    maxPages,
    moveToPage,
    moveToPrev,
    moveToNext,
  } = props

  useEffect(() => {
    setLimitPages(totalPages > maxPages ? maxPages : totalPages)

    return () => {
      setLimitPages(0)
    }
  }, [totalPages, maxPages])

  return (
    <Pagination {...props}>
      <Pagination.First
        disabled={currentPage === 1 ? true : false}
        onClick={() => moveToPage(1)}
      />
      <Pagination.Prev
        disabled={currentPage === 1 ? true : false}
        onClick={() => moveToPrev()}
      />
      {startCountPage > 1 && (
        <Fragment>
          <Pagination.Ellipsis disabled />
        </Fragment>
      )}
      {Array.from({ length: limitPages }, (_, index) => (
        <Pagination.Item
          active={index + 1 + startCountPage === currentPage ? true : false}
          onClick={() => moveToPage(index + 1 + startCountPage)}
        >
          {index + 1 + startCountPage}
        </Pagination.Item>
      ))}
      {currentPage + 4 < totalPages && (
        <Fragment>
          <Pagination.Ellipsis disabled />
        </Fragment>
      )}
      <Pagination.Next
        disabled={currentPage === totalPages ? true : false}
        onClick={() => moveToNext()}
      />
      <Pagination.Last
        onClick={() => moveToPage(totalPages)}
        disabled={currentPage === totalPages ? true : false}
      />
    </Pagination>
  )
}

Paginate.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  moveToPage: PropTypes.func,
  moveToPrev: PropTypes.func,
  moveToNext: PropTypes.func,
  maxPages: PropTypes.number,
}

export default Paginate
