/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import ItemViveExperienciaInterno from "./components/ItemViveExperienciaInterno";
import { OfertasLaboralesService } from "../../../../services/OfertasLaborales.service";
import { AllExperienciaData } from "./components/AllData";
import HeadInterno from "./components/headinterno";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ViveExperiencia.style.css";
import dataEmpresas from "../../explore/Filters/mueveteData.json";
import { useKeycloak } from "@react-keycloak/web";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowDerechaViveExperiencia slick-next-ViveExperiencia"}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperiencia slick-prev-ViveExperiencia"}
      onClick={onClick}
    ></div>
  );
}
const ViveExperiencia = (param) => {
      const { keycloak } = useKeycloak();

  const [offerList, setOfferList] = useState([]);
  let data;
  const filterJson = dataEmpresas.filter(function (dataSource) {
    if (param.format === "Falabella Equipo Corporativo") {
      data = "Falabella Corporativo";
    } else {
      data = param.format;
    }
    return dataSource.muevete === data;
  });

  const valoresEspecificosCompay = filterJson.map((element) => element.muevete);

  const uniqueCompany = [...new Set(valoresEspecificosCompay)];

  let filter = {
    country: [],
    area: [],
    company: uniqueCompany,
    jobtype: [],
    page: 1,
    perPage: 100,
    search: "",
    type: "external",
  };

  const minSlidesToShow = Math.min(offerList.length, 4);

  const settings = {
    infinite: true,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useLayoutEffect(() => {
    const getOfertasLaborales = async () => {
      const offerType = keycloak.authenticated ? "internal" : "external";
      const service = new OfertasLaboralesService(offerType);
      const response = await service.getOfertasLaboralesByFilter(filter);
      setOfferList(response.data.data.list);
    };
    getOfertasLaborales();
  }, []);

  const obtenerAreaUnicas = offerList.reduce((acc, item) => {
    if (!acc.includes(item.area)) {
      acc.push(item.area);
    }
    return acc;
  }, []);

  let filterByArea = [];
  obtenerAreaUnicas.forEach((item) => {
    AllExperienciaData.filter((arr) =>
      arr.param.some((dato) => dato === item)
    ).map((data) => {
      filterByArea.push(data);
    });
  });

  return (
    <div
      className="py-8 bg-experiencia w-100"
      name="propositointernos"
      id="propositointernos"
    >
      <div className="container">
        <HeadInterno format={param.format} />
        {filterByArea.length <= 3 ? (
          <div className="text-center">
            <Row>
              {filterByArea.map((item, index) => (
                <Col xs lg="4">
                  <ItemViveExperienciaInterno
                    key={item.id}
                    item={item}
                    extraclass="mx-2"
                    format={uniqueCompany}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div>
            <Slider {...settings}>
              {filterByArea.map((item, index) => (
                <div
                  className="px-md-1 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay={`${index * 0.2}s`}
                  data-wow-offset="5"
                  key={index}
                >
                  <ItemViveExperienciaInterno
                    key={item.id}
                    item={item}
                    extraclass="mx-2"
                    format={param.format}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViveExperiencia;
