import React, { Fragment, useState, useEffect } from "react";
import Footer from "./Footer";
import NavbarLandingJobs from "./NavbarLandingJobs";
import { SideScroll } from "components/falabella/jobs/SideScroll/SideScroll";
import Loader from "./Loader";
import { useKeycloak } from "@react-keycloak/web";
import { useIntl } from "react-intl";

const FalabellaLayout = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [loading, setLoading] = useState(true);
  const intl = useIntl();
  
  const authRequiredVariants = ['muevete', 'explorar', 'explorar-tech', 'explorar-jovenes'];

  const handleLogin = () => {
    const { variante } = children.props.extraProps;
    if (authRequiredVariants.includes(variante) && !keycloak.authenticated) {
      keycloak.login();
    }
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    if (!initialized) return;

    handleLogin();
    setLoading(false);
    //eslint-disable-next-line 
  }, [keycloak, initialized, children.props.extraProps.variante]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <NavbarLandingJobs transparent variant="light" />
          {keycloak.authenticated && authRequiredVariants.includes(children.props.extraProps.variante) && (
            <button onClick={handleLogout} className="logout-button">
              {intl.formatMessage({ id: "app.logout" })}
              {keycloak.tokenParsed ? `(${keycloak.tokenParsed.email ? keycloak.tokenParsed.email : keycloak.tokenParsed.preferred_username})` : ""}
            </button>
          )}

          {children}
          <Footer id="FOOTER" />
          <SideScroll />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FalabellaLayout;
