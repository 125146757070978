/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "hooks/FilterContext";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { OfertasLaboralesService } from "services/OfertasLaborales.service";
import dataEmpresas from "./mueveteData.json";
import { FormattedMessage } from "react-intl";
export const Filters = (props) => {
  const [offerList, setOfferList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [areas, setAreas] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [tags, setTags] = useState([]);
  const history = useHistory();
  const [options, setOptions] = useState({
    countries: [],
    areas: [],
    companies: [],
    jobTypes: [],
    tags: [],
  });
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState("");

useEffect(() => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchQuery = searchParams.get('search');
  if (searchQuery ) {
    setSearchTerm(searchQuery);
  }
}, [location]);

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

  const currentPath = location.pathname;

  const { type } = props;
  const contextValue = useContext(FilterContext);
  const filters = contextValue?.filters || {
    country: [],
    area: [],
    company: [],
    jobtype: [],
    tags: [],
    page: 1,
    perPage: 20,
    type: type,
  };

  const pageFilters = useRef(filters);
  const [loading, setLoading] = useState(false);

  const getOptionsFromArray = (data, type, filtros) => {
    if (typeof type !== "undefined") {
      switch (type) {
        case "country":
          function getDifferenceCountry(data, dataEmpresas) {
            return data.filter((object1) => {
              return dataEmpresas.some((object2) => {
                return object1 === object2.Country;
              });
            });
          }
          let countries = getDifferenceCountry(data, dataEmpresas);
          let resultCountries = countries.map((item) => {
            let opt = {
              label: item,
              value: item,
            };
            return opt;
          });
          return resultCountries;
        case "company":
          let datos = [];
          function getDifferenceCompany(data, dataEmpresas) {
            return data.filter((object1) => {
              return dataEmpresas.some((object2) => {
                //AQUI BUSCO POR EL JSON Y CON AIRA COMPARANDO SI EL COMPANY_CODE ES EL MISMO PARA MOSTRAR EL TITULO DE MUEVETE
                if (object1 === object2.Company_Code) {
                  datos.push({ item: object2.muevete, value: object2.muevete });
                }
              });
            });
          }
          getDifferenceCompany(data, dataEmpresas);
          let arregloUnicoFinal = [...new Set(datos.map((item) => item))];
          let resultCompanies = arregloUnicoFinal.map((item) => {
            let opt = {
              label: item.item,
              value: item.value,
            };
            return opt;
          });

          let uniqueItemsCompanies = resultCompanies.reduce((acc, curr) => {
            let found = acc.find((i) => i.value === curr.value);
            if (!found) acc.push(curr);
            return acc;
          }, []);
          return uniqueItemsCompanies;
        case "area":
          function getDifferenceAreaCountry(data, dataEmpresas) {
            return data.filter((object1) => {
              return dataEmpresas.some((object2) => {
                return object1[1] === object2.Country;
              });
            });
          }
          function getDifferenceAreaMuevete(data, dataEmpresas) {
            return data.filter((object1) => {
              return dataEmpresas.some((object2) => {
                return object1[2] === object2.muevete;
              });
            });
          }
          let areaCountry = getDifferenceAreaCountry(data, dataEmpresas);
          let areaMuevete = getDifferenceAreaMuevete(data, dataEmpresas);

          let resultAreaCountry = areaCountry.map((item) => ({
            label: item[0],
            value: item[0],
          }));

          let resultAreaMuevete = areaMuevete.map((item) => ({
            label: item[0],
            value: item[0],
          }));

          let mergedObj = [...resultAreaCountry, ...resultAreaMuevete];

          function eliminarDuplicados(arr, llave) {
            const uniqueObj = {};
            const uniqueArray = [];

            for (const item of arr) {
              if (!uniqueObj[item[llave]]) {
                uniqueObj[item[llave]] = true;
                uniqueArray.push(item);
              }
            }

            return uniqueArray;
          }

          const eliminaDuplicados = eliminarDuplicados(mergedObj, "value");
          return eliminaDuplicados;
        case "tags":
          const tagsOptions = data
            .filter((tag) => tag)
            .map((tagString) => {
              try {
                const tagObj = JSON.parse(tagString);
                return tagObj.tag;
              } catch {
                return null;
              }
            })
            .filter((tag) => tag)
            .map((tag) => ({
              label: tag,
              value: tag,
            }));
          return tagsOptions;
        default:
          let result = data.map((item) => {
            let opt = {
              label: item,
              value: item,
            };
            return opt;
          });
          return result;
      }
    }
  };

  const getCountries = useCallback(async (listado) => {
    const countriesList = listado.map((item) => item.country);
    let list = [...new Set(countriesList)];
    return list;
  }, []);

  const getAreas = useCallback(async (listado) => {
    const areasList = listado.map((item) => [
      item.area,
      item.country,
      item.company,
    ]);
    let list = [...new Set(areasList)];
    return list;
  }, []);

  const getCompany = useCallback(async (listado) => {
    const companyList = listado.map((item) => item.company_code);
    let list = [...new Set(companyList)];
    return list;
  }, []);

  const getJobTypes = useCallback(async (listado) => {
    const jobtypesList = listado.map((item) => item.jobtype);
    let list = [...new Set(jobtypesList)];
    return list;
  }, []);

  const getTagsTypes = useCallback(async (listado) => {
    const tagsList = listado.map((item) => item.tags);
    let list = [...new Set(tagsList)];
    return list;
  }, []);


  const getListOptions = useCallback(
    async (offers, filtros) => {
      let countryList = getOptionsFromArray(
        await getCountries(offers),
        "country",
        filtros
      );
      let areaList = getOptionsFromArray(
        await getAreas(offers),
        "area",
        filtros
      );
      let companyList = getOptionsFromArray(
        await getCompany(offers),
        "company",
        filtros
      );
      let jobtypeList = getOptionsFromArray(
        await getJobTypes(offers),
        "jobtype",
        filtros
      );
      let tagsList = getOptionsFromArray(
        await getTagsTypes(offers),
        "tags",
        filtros
      );
      return {
        areaList: areaList,
        countryList: countryList,
        companyList: companyList,
        jobtypeList: jobtypeList,
        tagsList: tagsList,
      };
    },
    [getAreas, getCompany, getCountries, getJobTypes, getTagsTypes]
  );

  const getOfferList = useCallback(async () => {
    setLoading(true);
    const service = new OfertasLaboralesService(type);

    let path = "";

    if (currentPath === "/explorar-tech") {
      path = "FalabellaTech";
    }

    if (currentPath === "/explorar-jovenes") {
      path = "JP";
    }

    const response = await service.getAllOfertasLaborales("", path);
    const parsedOfferList = response.data.map((offer) => {
      try {
        const tagsObj = offer.tags ? JSON.parse(offer.tags) : null;
        return {
          ...offer,
          parsedTags: tagsObj ? tagsObj.tag : null,
        };
      } catch (error) {
        console.error("Error al parsear tags:", error);
        return {
          ...offer,
          parsedTags: null,
        };
      }
    });
    setOfferList(parsedOfferList);
    if (response.data.length > 0) {
      let result = response.data.filter(
        (item) =>
          pageFilters.current.country.length === 0 ||
          pageFilters.current.country.includes(item.country)
      );
      let { countryList, areaList, companyList, jobtypeList, tagsList } =
        await getListOptions(result);
      setOptions({
        areas: areaList,
        countries: countryList,
        companies: companyList,
        jobTypes: jobtypeList,
        tags: tagsList,
      });
      setLoading(false);
    }
  }, [type, currentPath, getListOptions]);

  const handleChangeFilter = useCallback(
    async (type, selected, action) => {
      let values = selected.map((item) => item.value);
      let result = [];
      let dataMuevete = [];
      let filtros = {
        country: countries,
        area: areas,
        company: companies,
        jobtype: jobTypes,
        tags: tags,
        page: 1,
        perPage: 20,
        type: type,
      };
    pageFilters.current = filtros;
      if (offerList.length > 0) {
        switch (type) {
          case "country":
            setCountries(values);
            filtros.country = values;
            result = offerList.filter(
              (item) =>
                (filtros.country.length === 0 ||
                  filtros.country.includes(item.country)) &&
                (filtros.area.length === 0 ||
                  filtros.area.includes(item.area)) &&
                (filtros.company.length === 0 ||
                  filtros.company.includes(item.company_code)) &&
                (filtros.jobtype.length === 0 ||
                  filtros.jobtype.includes(item.jobtype))
            );
            let countryResOptions = await getListOptions(result, filtros);
            setOptions((prev) => {
              return {
                countries:
                  action === "select-option"
                    ? prev.countries
                    : countryResOptions.countryList,
                areas: countryResOptions.areaList,
                companies: countryResOptions.companyList,
                jobTypes: countryResOptions.jobtypeList,
                tags: countryResOptions.tagsList,
              };
            });
            break;
          case "company":
            let newValues = [];
            filtros.company = values;
            setCompanies(values);
            dataMuevete = dataEmpresas.filter(
              (data) =>
                filtros.company.length === 0 ||
                filtros.company.includes(data.muevete)
            );

            newValues = dataMuevete.map((item) => item.Company_Code);

            newValues = newValues.filter((v, i, a) => a.indexOf(v) === i);
            filtros.company = newValues; 
            result = offerList.filter(
              (item) =>
                (filtros.country.length === 0 ||
                  filtros.country.includes(item.country)) &&
                (filtros.area.length === 0 ||
                  filtros.area.includes(item.area)) &&
                (filtros.company.length === 0 ||
                  filtros.company.includes(item.company_code)) &&
                (filtros.jobtype.length === 0 ||
                  filtros.jobtype.includes(item.jobtype))
            );

            let companyResOptions = await getListOptions(result, filtros);
            setOptions((prev) => {
              return {
                companies:
                  action === "select-option"
                    ? prev.companies
                    : companyResOptions.companyList,
                countries: companyResOptions.countryList,
                areas: companyResOptions.areaList,
                jobTypes: companyResOptions.jobtypeList,
              };
            });
            break;
          case "area":
            setAreas(values);
            filtros.area = values;
            result = offerList.filter(
              (item) =>
                (filtros.country.length === 0 ||
                  filtros.country.includes(item.country)) &&
                (filtros.area.length === 0 ||
                  filtros.area.includes(item.area)) &&
                (filtros.jobtype.length === 0 ||
                  filtros.jobtype.includes(item.jobtype))
            );

            let areaResOptions = await getListOptions(result, filtros);
            setOptions((prev) => {
              return {
                areas:
                  action === "select-option"
                    ? prev.areas
                    : areaResOptions.areaList,
                countries: areaResOptions.countryList,
                companies: areaResOptions.companyList,
                jobTypes: areaResOptions.jobtypeList,
              };
            });
            break;
          case "jobtype":
            setJobTypes(values);
            filtros.jobtype = values;
            result = offerList.filter(
              (item) =>
                (filtros.area.length === 0 ||
                  filtros.area.includes(item.area)) &&
                (filtros.country.length === 0 ||
                  filtros.country.includes(item.country)) &&
                (filtros.jobtype.length === 0 ||
                  filtros.jobtype.includes(item.jobtype))
            );
            let jobtypeResOptions = await getListOptions(result);
            setOptions((prev) => {
              return {
                jobTypes:
                  action === "select-option"
                    ? prev.jobTypes
                    : jobtypeResOptions.jobtypeList,
                countries: jobtypeResOptions.countryList,
                areas: jobtypeResOptions.areaList,
                companies: jobtypeResOptions.companyList,
              };
            });
            break;
          case "tags":
            setTags(values);
            filtros.tags = values;
            
            result = offerList.filter(
              (item) =>
                (filtros.country.length === 0 ||
                  filtros.country.includes(item.country)) &&
                (filtros.area.length === 0 ||
                  filtros.area.includes(item.area)) &&
                (filtros.company.length === 0 ||
                  filtros.company.includes(item.company_code)) &&
                (filtros.jobtype.length === 0 ||
                  filtros.jobtype.includes(item.jobtype)) &&
                (filtros.tags.length === 0 || filtros.tags.includes(item.parsedTags))
            );
            let tagsResOptions = await getListOptions(result, filtros);
            setOptions((prev) => {
              return {
                tags:
                  action === "select-option"
                    ? prev.tags
                    : tagsResOptions.tagsList,
                countries: tagsResOptions.countryList,
                areas: tagsResOptions.areaList,
                companies: tagsResOptions.companyList,
                jobTypes: tagsResOptions.jobtypeList,
              };
            });
            break;

          default:
            break;
        }
        setLoading(false);
      }
    },
    [areas, companies, countries, getListOptions, jobTypes, offerList, tags]
  );

  useLayoutEffect(() => {
    getOfferList();
  }, [getOfferList]);

  const createAndSendPayload = () => {
    const payload = {
      country: countries,
      area: areas,
      company: companies,
      jobtype: jobTypes,
      tags: tags,
      search: searchTerm,
    };
    props.onSearch(payload);
  };

  const handleFilter = async (e) => {
    if (e) e.preventDefault();
    createAndSendPayload()
  };

  const handleReset = useCallback(async () => {
    setLoading(true);

    setAreas([]);
    setCompanies([]);
    setCountries([]);
    setJobTypes([]);
    setTags([]);
    setSearchTerm("");
    
    pageFilters.current = {
      country: [],
      area: [],
      company: [],
      jobtype: [],
      tags: [],
    };
    
    let opts = await getListOptions(offerList, {
      countries: [],
      areas: [],
      companies: [],
      jobTypes: [],
      tags: [],
    });

    setOptions({
      jobTypes: opts.jobtypeList,
      countries: opts.countryList,
      areas: opts.areaList,
      companies: opts.companyList,
      tags: opts.tagsList,
    });

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('search')) {
        searchParams.delete('search');
        history.push(`${window.location.pathname}?${searchParams}`);
    }

    setLoading(false);

}, [getListOptions, offerList, history]);



  return (  
    <Fragment>
      <Container {...props} name="filter" id="filter">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="fs-4 text-dark fw-bold pb-4">
                <FormattedMessage id="app.explorar.seccion.titulo" />
              </span>
            </Accordion.Header>
            <Accordion.Body>
              {!loading && (
                <>
                  <Row>
                    <Col xs="12">
                      <Form
                        className="mb-3 d-flex align-items-center"
                        onSubmit={(e) => handleFilter(e)}
                      >
                        <span className="position-absolute ps-3 search-icon text-primary">
                          <i className="fe fe-search"></i>
                        </span>
                        <FormattedMessage id="app.explorar.seccion.buscar">
                          {(text) => (
                            <Form.Control
                              type="text"
                              id="formSearch"
                              className="ps-6 primary"
                              placeholder={text}
                              value={searchTerm}
                              onChange={handleSearchChange}
                            />
                          )}
                        </FormattedMessage>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="3">
                      <Select
                        placeholder={
                          <FormattedMessage id="app.explorar.placeholder.pais" />
                        }
                        defaultValue={() =>
                          getOptionsFromArray(pageFilters.current.country)
                        }
                        className="w-80"
                        showTick
                        isSearchable
                        isMulti
                        noOptionsMessage={() =>
                          "No hay resultados, Limpiar para refrescar la lista"
                        }
                        options={options.countries}
                        onChange={(value, action) =>
                          handleChangeFilter("country", value, action.action)
                        }
                      />
                    </Col>
                    <Col xs="12" lg="3">
                      <Select
                        placeholder={
                          <FormattedMessage id="app.explorar.placeholder.empresa" />
                        }
                        defaultValue={() =>
                          getOptionsFromArray(pageFilters.current.company)
                        }
                        className="w-80"
                        showTick
                        isSearchable
                        isMulti
                        noOptionsMessage={() => "Seleccionar Empresa"}
                        options={options.companies}
                        onChange={(value, action) =>
                          handleChangeFilter("company", value, action.action)
                        }
                      />
                    </Col>
                    <Col xs="12" lg="3">
                      <Select
                        placeholder={
                          <FormattedMessage id="app.explorar.placeholder.area" />
                        }
                        defaultValue={() =>
                          getOptionsFromArray(pageFilters.current.area)
                        }
                        className="w-80"
                        showTick
                        isSearchable
                        isMulti
                        noOptionsMessage={() => "Seleccionar Area"}
                        options={options.areas}
                        onChange={(value, action) =>
                          handleChangeFilter("area", value, action.action)
                        }
                      />
                    </Col>
                    <Col xs="12" lg="3">
                      <Select
                        placeholder={
                          <FormattedMessage id="app.explorar.placeholder.tipojornada" />
                        }
                        defaultValue={() =>
                          getOptionsFromArray(pageFilters.current.jobtype)
                        }
                        className="w-80"
                        showTick
                        isSearchable
                        isMulti
                        noOptionsMessage={() => "Seleccionar Tipo de Jornada"}
                        options={options.jobTypes}
                        onChange={(value, action) =>
                          handleChangeFilter("jobtype", value, action.action)
                        }
                      />
                    </Col>
                    
                  </Row>
                </>
              )}
              <div className="text-end pt-4">
                <Button
                  className="ms-4 rounded-pill"
                  variant="secondary"
                  onClick={() => {
                    handleReset();
     
                  }}
                >
                  <FormattedMessage id="app.explorar.boton.limpiar" />
                </Button>
                <Button
                  className="ms-4 rounded-pill"
                  variant="primary"
                  onClick={(e) => handleFilter(e)}
                >
                  <FormattedMessage id="app.explorar.boton.filtrar" />
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Fragment>
  );
};
