/* eslint-disable no-unused-vars */
import { Carousel, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { FilterContext } from "hooks/FilterContext";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

const logos = [
  {
    id: "1",
    srcWeb: require("assets/images/falabella/f.png"),
    redirect: "/falabellaretail",
    to: "Falabella Retail",
  },
  {
    id: "2",
    srcWeb: require("assets/images/falabella/sodimac.png"),
    redirect: "/sodimac",
    to: "Sodimac",
  },
  {
    id: "3",
    srcWeb: require("assets/images/falabella/tottus.png"),
    redirect: "/tottus",
    to: "Tottus",
  },
  {
    id: "4",
    srcWeb: require("assets/images/falabella/banco-falabella.png"),
    redirect: "/bancofalabella",
    to: "Banco Falabella",
  },
  {
    id: "5",
    srcWeb: require("assets/images/falabella/mallplaza.png"),
    redirect: "/mallplaza",
    to: "Mallplaza",
  },
  {
    id: "6",
    srcWeb: require("assets/images/falabella/falabella-inmobiliario2.png"),
    redirect: "/falabellainmobiliario",
    to: "Falabella Inmobiliario",
  },
  {
    id: "8",
    srcWeb: require("assets/images/falabella/falabellacom.png"),
    redirect: "/falabellaretail",
    to: "Falabella Retail",
  },
  {
    id: "11",
    srcWeb: require("assets/images/img/logos/falabellafinanciero2.png"),
    redirect: "/falabellafinanciero",
    to: "Falabella Financiero",
  },
  {
    id: "12",
    srcWeb: require("assets/images/falabella/falabellasoriana2.png"),
    redirect: "/falabellasoriana",
    to: "Falabella Soriana",
  },
  {
    id: "13",
    srcWeb: require("assets/images/falabella/segurosfalabella2.png"),
    redirect: "/segurosfalabella",
    to: "Seguros Falabella",
  },
  {
    id: "14",
    srcWeb: require("assets/images/falabella/falabella.png"),
    redirect: "/falabellacorp",
    to: "Falabella Equipo Corporativo",
  }
];

const BrandCarousel = (props) => {
  const { setFilters } = useContext(FilterContext);
  const history = useHistory();

  const handleClick = async (to) => {
    const payload = {
      country: [],
      area: [],
      company: [to],
      jobtype: [],
    };
    setFilters(payload);
    history.push("/explorar");
  };
  return (
    <Carousel indicators={false} {...props}>
      {logos.map((item) => (
        <Carousel.Item className="text-center">
          <Link to={item.redirect}>
            <Image
              src={item.srcWeb}
              style={{ height: props.height, width: "auto" }}
            />
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

// Specifies the default values for props
BrandCarousel.defaultProps = {
  to: "#",
  controls: false,
};

// Typechecking With PropTypes
BrandCarousel.propTypes = {
  height: PropTypes.string,
  nextIcon: PropTypes.node,
  prevIcon: PropTypes.node,
  to: PropTypes.string,
  controls: PropTypes.bool,
};

export default BrandCarousel;
