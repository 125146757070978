// import node module libraries
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";

const NavDropdownMobile = (props) => {
  const { item } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const [isActiveGroup, setIsActiveGroup] = useState(false);
  const { variante } = props;

  useEffect(() => {
    const isActive = item.children.some((submenu) => location.pathname === submenu.link);
    setIsActiveGroup(isActive);
    setIsDropdownOpen(isActive);
  }, [item.children, location.pathname]);

  const handleMouseEnter = (event) => {
    event.target.style.transform = 'scale(1.2)';
    event.target.style.borderBottom = '3px solid #ffffff'; // Línea de 5px de ancho en color blanco
    event.target.style.color = variante === 'muevete' ? '#555555' : '#ffffff'; // Cambiar color de letra a blanco
  };

  const handleMouseLeave = (event) => {
    event.target.style.transform = 'scale(1)';
    event.target.style.borderBottom = 'none';
    event.target.style.color = ''; // Revertir color de letra
  };

  return (
    <Row onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="navCursorPointer">
      <Col xs="12" className={`navbar-title${isActiveGroup ? " active" : ""}`} style={variante==='muevete' ? {color:'#3E3E3D'} : {}}>
        {item.link ? (
          <Link 
            className="text-reset" 
            to={item.link}               
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {item.menuitem}
          </Link>
        ) : (
          item.menuitem
        )}
       
      </Col>
      {isActiveGroup && item.children.length > 0 && <span className="navbar-title-caret"></span>}
      {item.children.map((submenu, submenuindex) => {
        const isActive = location.pathname === submenu.link;
        return (
          <Col xs="12" className={`navbar-item${isDropdownOpen ? " open" : ""} ${item.id === 1 ? " open" : ""}`} key={submenuindex} >
            <Link 
              className={`navbar-item-link${isActive ? " active selecteditem" : ""}${isActive && variante==='muevete' ? " activeGris" : ""}`} 
              to={submenu.link} 
              onClick={props.toggleMenu}               
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {submenu.menuitem}
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};
export default NavDropdownMobile;
