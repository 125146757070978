const falabella1 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/1.jpg");
const falabella2 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/2.jpg");
const falabella3 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/3.jpg");
const falabella4 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/4.jpg");
const falabella5 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/5.jpg");
const falabella6 = require("assets/images/falabella/internos/carousel/FalabellaInmobiliario/6.jpg");




export const GrandesEspaciosDataFalabellaInmobiliario = 
[
  [
    {
      id: 1,
      image: falabella1,
      position: "h-20rem",
      image2: falabella2,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella4,
      position: "h-16rem",
      image2: falabella3,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella5,
      position: "h-20rem",
      image2: falabella6,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella4,
      position: "h-20rem",
      image2: falabella2,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella6,
      position: "h-16rem",
      image2: falabella1,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella3,
      position: "h-20rem",
      image2: falabella5,
      position2: "h-18rem",
    },
  ]
];



export default GrandesEspaciosDataFalabellaInmobiliario;
