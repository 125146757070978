import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import cardimage1 from 'assets/images/falabella/jovenes/shutterstock_2088756661.jpg';
import cardimage2 from 'assets/images/falabella/jovenes/shutterstock_1166473099.jpg';
import cardimage3 from 'assets/images/falabella/jovenes/shutterstock_1447982429.jpg';
import "./PrimerosPasos.style.css";

  
import { useMediaQuery } from "react-responsive";
import { FormattedMessage, useIntl } from 'react-intl';

const PrimerosPasos = (param) => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', descripcion: ''});
  const handleClose = () => setShow(false);

  const handleShow = (title, descripcion) => {
    setModalInfo({ title, descripcion});
    setShow(true);
  }

    const intl = useIntl();
    const message1 = intl.formatMessage({ id: 'app.jovenes.primeros.paso1.description' });
    const truncatedMessage1 = message1.length > 112 ? `${message1.slice(0, 110)}...` : message1;
    const titlemodal1=intl.formatMessage({ id: 'app.jovenes.modal.1.titulo' });
    const descmosal1=intl.formatMessage({ id: 'app.jovenes.modal.1.desc' });
    
    const message2 = intl.formatMessage({ id: 'app.jovenes.primeros.paso2.description' });
    const truncatedMessage2 = message2.length > 112 ? `${message2.slice(0, 110)}...` : message2;
    const titlemodal2=intl.formatMessage({ id: 'app.jovenes.modal.2.titulo' });
    const descmosal2=intl.formatMessage({ id: 'app.jovenes.modal.2.desc' });
    
    const message3 = intl.formatMessage({ id: 'app.jovenes.primeros.paso3.description' });
    const truncatedMessage3 = message3.length > 112 ? `${message3.slice(0, 110)}...` : message3;
    const titlemodal3=intl.formatMessage({ id: 'app.jovenes.modal.3.titulo' });
    const descmosal3=intl.formatMessage({ id: 'app.jovenes.modal.3.desc' });

  return (
    <Fragment>
        <div className="py-8 bg-primeros-pasos" id="prota" name="prota">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} className="pb-5 pt-5 px-8">
                        <div className={`${isLargeScreen ? "" : "pt-4"} d-flex align-items-center justify-content-center`}>
                        <h4 className={`${isLargeScreen ? "classH3Size" : ""}`}>
                            <span style={{ color: "#CD0B51" }}>
                            <center>
                                <FormattedMessage id="app.jovenes.primeros.titulo" />
                            </center>
                            </span>
                        </h4>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-10">
                    <Col md={4} xs={12} className={` ${isLargeScreen ? "mt-8 mb-4" : "mb-especial-paso1"}`}>
                        <div className="card-container">
                            <div className="overlay-card">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                        <div className="text-left">
                                            <h5 className="mb-0">
                                            <span className="btn btn-custom-paso1 btn-sm rounded-pill mr-2"><FormattedMessage id="app.jovenes.primeros.paso1.paso" /></span>
                                            </h5>
                                        </div>
                                        <div>
                                            <span className="btn btn-custom-paso1 btn-sm rounded-pill mr-2" onClick={() => handleShow(titlemodal1, descmosal1)}>
                                            <FormattedMessage id="app.jovenes.primeros.paso1.paso1_text" />
                                            </span>
                                        </div>
                                    </div>
                                    <Card.Title><span style={{ color: "#2D46B9" }}><h4><FormattedMessage id="app.jovenes.primeros.paso1.title" /></h4></span></Card.Title>
                                    <Card.Text>
                                        {truncatedMessage1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <div className="background-card">
                                <img
                                    src={cardimage1}
                                    alt="Imagen 1"
                                    className="vertical-image img-fluid"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className={` ${isLargeScreen ? "mt-8 mb-4" : "mb-especial-paso2"}`}>
                        <div className="card-container-center">
                            <div className="overlay-card-center">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                        <div className="text-left">
                                            <h5 className="mb-0">
                                            <span className="btn btn-custom-paso2 btn-sm rounded-pill mr-2"><FormattedMessage id="app.jovenes.primeros.paso2.paso" /></span>
                                            </h5>
                                        </div>
                                        <div>
                                        <span className="btn btn-custom-paso2 btn-sm rounded-pill mr-2" onClick={() => handleShow(titlemodal2, descmosal2)}>
                                            <FormattedMessage id="app.jovenes.primeros.paso1.paso1_text" />
                                            </span>
                                        </div>
                                    </div>
                                    <Card.Title><span style={{ color: "#2D46B9" }}><h4><FormattedMessage id="app.jovenes.primeros.paso2.title" /></h4></span></Card.Title>
                                    <Card.Text>
                                        {truncatedMessage2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <div className="background-card-center">
                                <img
                                    src={cardimage2}
                                    alt="Imagen 1"
                                    className="vertical-image-center img-fluid"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className={` ${isLargeScreen ? "mt-8 mb-4" : "mb-especial-paso2"}`}>
                        <div className="card-container">
                            <div className="overlay-card">
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between pb-4">
                                    <div className="text-left">
                                        <h5 className="mb-0">
                                        <span className="btn btn-custom-paso3 btn-sm rounded-pill mr-2"><FormattedMessage id="app.jovenes.primeros.paso3.paso" /></span>
                                        </h5>
                                    </div>
                                    <div>
                                        <span className="btn btn-custom-paso3 btn-sm rounded-pill mr-2" onClick={() => handleShow(titlemodal3, descmosal3)}>
                                        <FormattedMessage id="app.jovenes.primeros.paso1.paso1_text" />
                                        </span>
                                    </div>
                                </div>
                                <Card.Title><span style={{ color: "#2D46B9" }}><h4><FormattedMessage id="app.jovenes.primeros.paso3.title" /></h4></span></Card.Title>
                                <Card.Text>
                                        {truncatedMessage3}
                                    </Card.Text>
                            </Card.Body>
                            </div>
                            <div className="background-card">
                                <img
                                    src={cardimage3}
                                    alt="Imagen 1"
                                    className="vertical-image img-fluid"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div style={{ height: "150px", width: "auto", overflow: "hidden" }}>
        <svg
        className="svg-vertical"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        style={{
            height: "100%",
            width: "100%",
            verticalalign: "baseline",
        }}
        >
        <path
            fill="rgb(224, 224, 224)"
            d="M0,192L60,213.3C120,235,240,277,360,288C480,299,600,277,720,240C840,203,960,149,1080,133.3C1200,117,1320,139,1380,149.3L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            style={{ stroke: "none" }}
        ></path>
        </svg>

        </div>
        <Modal id="mi-modal-primeros-pasos" size="lg" show={show} onHide={handleClose}>
          <Modal.Body>
            <Card className={``}>
                <Card.Body className="">
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} className="">
                            <h4 className="h4 mb-1">
                                <b>
                                    <span style={{ color: modalInfo.color }}>{modalInfo.title}</span>
                                </b>
                            </h4>
                            <br></br>
                            <br></br>
                            <span className="h4 ">
                                <span>{modalInfo.descripcion}</span>
                            </span>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div style={{ textAlign: 'center' }}>
                      <span className="btn rounded-pill mx-2  btn-tech btn btn-primary" onClick={handleClose}>
                          <b>
                          Cerrar
                          </b>
                      </span>
                    </div>
                </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
    </Fragment>
  );
};

export default PrimerosPasos;
