import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Row, Col, Button } from "react-bootstrap";
import NavbarDefaultRoutes from "routes/falabella/NavbarDefault";
import NavbarDefaultFormatRoutes from "routes/falabella/NavbarFormatDefault";
import { useMediaQuery } from "react-responsive";
import BrandCarousel from "components/falabella/jobs/BrandCarousel/BrandCarousel";
import { logos_white } from "data/landing/logo-list";
import NavDropdownDesk from "components/falabella/common/NavDropdownDesk";
import NavDropdownMobile from "components/falabella/common/NavDropdownMobile";
import { FormattedMessage } from "react-intl";
import { NavDropdown } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./NavbarLandingJobs.style.css";
import { langContext } from "./../../content/langContext";
import { useHistory } from "react-router-dom";
const logoMenuLight = require("assets/images/falabella/falabella_light.png");
const logoMenuDark = require("assets/images/falabella/falabella_dark.png");
const NavbarLandingJobs = ({ transparent, variant }) => {
  const history = useHistory();
  const isLargeScreen = useMediaQuery({ minWidth: 992 });
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [colorVariant, setColorVariant] = useState(variant);
  const [menuItems, setMenuItems] = useState(NavbarDefaultRoutes);
  const [menuItemsFormat, setMenuItemsFormat] = useState(
    NavbarDefaultFormatRoutes
  );
  const [showTopMenu, setShowTopMenu] = useState(true);
  const [show, setShow] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  function toggleMenu() {
    setIsOpen(!isOpen);
    setShowCloseIcon(!showCloseIcon);
  }

  const buttonStyle = {
    color: colorVariant === "light" ? "white" : "black",
  };

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const checkScroll = useCallback(() => {
    if (isLargeScreen) {
      let offset = window.scrollY;
      if (offset > 60) {
        setShowTopMenu(false);
      }
      if (offset < 60) {
        setShowTopMenu(true);
      }
    }
  }, [isLargeScreen]);

  useEffect(() => {
    if (isLargeScreen) {
      window.addEventListener("scroll", checkScroll);
      setColorVariant(variant);
    } else {
      setColorVariant("light");
    }
  }, [checkScroll, isLargeScreen, variant]);

  useEffect(() => {
    if (isLargeScreen) {
      setMenuItems(NavbarDefaultRoutes.slice(0, 3));
    } else {
      setMenuItems(NavbarDefaultRoutes.slice(0, 3));
    }

    return () => {
      setMenuItems([]);
    };
  }, [isLargeScreen]);

  useEffect(() => {
    if (isLargeScreen) {
      setMenuItemsFormat(NavbarDefaultFormatRoutes.slice(0, 1));
    } else {
      setMenuItemsFormat(NavbarDefaultFormatRoutes.slice(0, 2));
    }

    return () => {
      setMenuItemsFormat([]);
    };
  }, [isLargeScreen]);
  const idioma = useContext(langContext);
  const handleRedirect = (path) => {
    history.push(path);
  };

  return (
    <Fragment>
      <div
        className={showTopMenu ? "d-block" : "d-none"}
        style={{ transition: "0.3" }}
      >
        <div
          className="d-none d-lg-block py-lg-3 py-3 w-100"
          style={{ backgroundColor: "#3E3E3D" }}
        >
          <Row className="align-items-center" style={{ marginRight: "0" }}>
            <Col sm={`${isLargeScreen ? "1" : "2"}`}></Col>
            {logos_white.map((item) => (
              <Col className="text-center">
                <Link to={item.url}>
                  <Image
                    src={item.srcWeb}
                    style={{ height: "1.7vw", width: "auto" }}
                  />
                </Link>
              </Col>
            ))}
            <Col xs="2"></Col>
          </Row>
        </div>

        <Row style={{ margin: "0" }}>
          <Col lg="2" className="d-none d-lg-block"></Col>
          <Col sm="12" lg="8" className="px-0">
            <Navbar
              variant={colorVariant}
              onToggle={(collapsed) => setExpandedMenu(collapsed)}
              expanded={expandedMenu}
              expand="lg"
              className={`${
                isLargeScreen ? "pt-4" : "w-100 sticky-top"
              } navbar shadow-none 
              ${isLargeScreen && transparent && "navbar-transparent"}`}
              style={
                isLargeScreen
                  ? { maxWidth: "66vw" }
                  : { backgroundColor: "#3E3E3D", position: "fixed" }
              }
            >
              <Navbar.Brand as={Link} to="/">
                <Row>
                  <Col lg="1" className="d-lg-none" style={{ width: "35vw" }}>
                    <BrandCarousel height="4vw" />
                  </Col>
                  <Col className="text-end">
                    <Image
                      style={
                        isLargeScreen
                          ? { height: "2.5vw", width: "auto" }
                          : { height: "4.5vw", width: "auto" }
                      }
                      src={
                        colorVariant === "light" ? logoMenuLight : logoMenuDark
                      }
                      className="mt-2"
                    />
                  </Col>
                </Row>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className="icon-bar top-bar mt-0"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {menuItems.map((item, index) => {
                    if (item.children === undefined) {
                      return (
                        <Nav.Link key={index} as={Link} to={item.link}>
                          {item.menuitem}
                        </Nav.Link>
                      );
                    } else {
                      return isLargeScreen ? (
                        <Fragment>
                          {/*
                          <NavDropdownDesk
                            item={item}
                            key={index}
                            onClick={(value) => setExpandedMenu(value)}
                            variant={colorVariant}
                          />
                          */}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <NavDropdownMobile
                            item={item}
                            key={index}
                            toggleMenu={toggleMenu}
                          />
                        </Fragment>
                      );
                    }
                  })}
                  {!isLargeScreen && (
                    <Fragment>
                      {menuItemsFormat.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return (
                            <Fragment>
                              <NavDropdownMobile
                                item={item}
                                key={index}
                                toggleMenu={toggleMenu}
                              />
                            </Fragment>
                          );
                        }
                      })}
                      <div className="p-4 text-center">
                        <Button
                          onClick={() => idioma.establecerLenguaje("es-ES")}
                          variant="primary"
                        >
                          Español
                        </Button>{" "}
                        <Button
                          onClick={() => idioma.establecerLenguaje("en-US")}
                          variant="secondary"
                        >
                          English
                        </Button>{" "}
                      </div>
                    </Fragment>
                  )}
                </Nav>
                {isLargeScreen && (
                  <Fragment>
                    <NavDropdown
                      title={<FormattedMessage id="app.menu.oportunidad" />}
                      variant={`outline-${colorVariant}`}
                      className="btn rounded-pill mx-2 btn-ofertas"
                    >
                      <Dropdown.Item
                        onClick={() => handleRedirect("/explorar")}
                        className="btn rounded-pill"
                      >
                        Únete al Equipo
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleRedirect("/muevete")}
                        className="btn rounded-pill"
                      >
                        Oportunidades para Colaboradores
                      </Dropdown.Item>
                    </NavDropdown>

                    <div className="ms-lg-3 d-none d-md-none d-lg-block">|</div>
                    <div
                      className={`menu-principal-lang-${colorVariant} ms-lg-3 d-none d-md-none d-lg-block`}
                    >
                      <button
                        onClick={toggleMenu}
                        className={`icon-${colorVariant}`}
                      >
                        {showCloseIcon ? (
                          <i className={`fas fa-times`} style={buttonStyle}></i>
                        ) : (
                          <i className={`fas fa-bars`} style={buttonStyle}></i>
                        )}
                      </button>
                    </div>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block">|</div>
                    <div className="p-2">
                      <NavDropdown
                        className={`menu-principal-lang-${colorVariant}`}
                        title={
                          <FormattedMessage id="app.menu.selector.idioma" />
                        }
                        style={
                          isLargeScreen
                            ? { fontSize: "1.2vw" }
                            : { fontSize: "2vw" }
                        }
                        show={show}
                        onMouseEnter={showDropdown}
                        onMouseLeave={hideDropdown}
                      >
                        <Dropdown.Item
                          onClick={() => idioma.establecerLenguaje("es-ES")}
                        >
                          ES | Español
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => idioma.establecerLenguaje("en-US")}
                        >
                          EN | English
                        </Dropdown.Item>
                      </NavDropdown>
                    </div>
                  </Fragment>
                )}
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col lg="2" className="d-none d-lg-block"></Col>
        </Row>
      </div>
      {isLargeScreen && (
        <Navbar
          sticky="top"
          onToggle={(collapsed) => setExpandedMenu(collapsed)}
          expanded={expandedMenu}
          style={{ backgroundColor: "#3E3E3D", transition: "0.3" }}
          className={!showTopMenu ? "d-flex p-0" : "d-none"}
        >
          <Navbar.Brand as={Link} to="/" style={{ paddingLeft: "17%" }}>
            <Row>
              <Col lg="1" className="d-lg-none" style={{ width: "35vw" }}>
                <BrandCarousel height="4vw" />
              </Col>
              <Col className="text-end">
                <Image
                  style={
                    isLargeScreen
                      ? { height: "2.5vw", width: "auto" }
                      : { height: "4.5vw", width: "auto" }
                  }
                  src={logoMenuLight}
                  className="mt-2"
                />
              </Col>
            </Row>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ paddingRight: "17%" }}
          >
            <Nav className="ms-auto p-4">
              {menuItems.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link key={index} as={Link} to={item.link}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return isLargeScreen ? (
                    <Fragment>
                      <div className="mt-4"></div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NavDropdownMobile
                        item={item}
                        key={index}
                        toggleMenu={toggleMenu}
                      />
                    </Fragment>
                  );
                }
              })}
            </Nav>
            {isLargeScreen && (
              <Fragment>
                <NavDropdown
                  title={<FormattedMessage id="app.menu.oportunidad" />}
                  variant={`outline-${colorVariant}`}
                  className="btn rounded-pill mx-2 btn-ofertas "
                >
                  <Dropdown.Item
                    onClick={() => handleRedirect("/explorar")}
                    className="btn rounded-pill "
                  >
                    <FormattedMessage id="app.unetealqeuipo" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleRedirect("/muevete")}
                    className="btn rounded-pill "
                  >
                    <FormattedMessage id="app.oportunidadescolab" />
                  </Dropdown.Item>
                </NavDropdown>

                <div className="ms-lg-3 d-none d-md-none d-lg-block">|</div>
                <div
                  className={`menu-principal-lang-${colorVariant} ms-lg-3 d-none d-md-none d-lg-block`}
                >
                  <button
                    onClick={toggleMenu}
                    className={`icon-${colorVariant}`}
                  >
                    {showCloseIcon ? (
                      <i
                        className={`fas fa-times`}
                        style={{ color: "#ffffff" }}
                      ></i>
                    ) : (
                      <i
                        className={`fas fa-bars`}
                        style={{ color: "#ffffff" }}
                      ></i>
                    )}
                  </button>
                  <div className={`p-4 menu ${isOpen ? "menu-open" : ""}`}>
                    <Fragment>
                      <div style={{ textAlign: "right" }}>
                        <button
                          onClick={toggleMenu}
                          className={`icon-${colorVariant}`}
                        >
                          {showCloseIcon ? (
                            <i
                              className={`fas fa-times`}
                              style={{ color: "#ffffff" }}
                            ></i>
                          ) : (
                            <i
                              className={`fas fa-bars`}
                              style={{ color: "#ffffff" }}
                            ></i>
                          )}
                        </button>
                      </div>
                      {menuItems.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return !isLargeScreen ? (
                            <NavDropdownDesk
                              item={item}
                              key={index}
                              onClick={(value) => setExpandedMenu(value)}
                              variant={colorVariant}
                            />
                          ) : (
                            <Fragment>
                              <NavDropdownMobile
                                item={item}
                                key={index}
                                toggleMenu={toggleMenu}
                              />
                            </Fragment>
                          );
                        }
                      })}
                      {menuItemsFormat.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return (
                            <Fragment>
                              <NavDropdownMobile
                                item={item}
                                key={index}
                                toggleMenu={toggleMenu}
                              />
                            </Fragment>
                          );
                        }
                      })}
                    </Fragment>
                  </div>
                </div>
                <div className="ms-lg-3 d-none d-md-none d-lg-block">|</div>
                <div className="p-2">
                  <NavDropdown
                    className={`menu-principal-lang-light`}
                    title={<FormattedMessage id="app.menu.selector.idioma" />}
                    style={
                      isLargeScreen
                        ? { fontSize: "1.2vw" }
                        : { fontSize: "2vw" }
                    }
                    show={show}
                    onMouseEnter={showDropdown}
                    onMouseLeave={hideDropdown}
                  >
                    <Dropdown.Item
                      onClick={() => idioma.establecerLenguaje("es-ES")}
                    >
                      ES | Español
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => idioma.establecerLenguaje("en-US")}
                    >
                      EN | English
                    </Dropdown.Item>
                  </NavDropdown>
                </div>
              </Fragment>
            )}
          </Navbar.Collapse>
        </Navbar>
      )}

      {isLargeScreen ? (
        <div className={`p-4 menu ${isOpen ? "menu-open" : ""}`}>
          <Fragment>
            <div style={{ textAlign: "right" }}>
              <button onClick={toggleMenu} className={`icon-${colorVariant}`}>
                {showCloseIcon ? (
                  <i
                    className={`fas fa-times`}
                    style={{ color: "#ffffff" }}
                  ></i>
                ) : (
                  <i className={`fas fa-bars`} style={{ color: "#ffffff" }}></i>
                )}
              </button>
            </div>
            {menuItems.map((item, index) => {
              if (item.children === undefined) {
                return (
                  <Nav.Link key={index} as={Link} to={item.link}>
                    {item.menuitem}
                  </Nav.Link>
                );
              } else {
                return !isLargeScreen ? (
                  <NavDropdownDesk
                    item={item}
                    key={index}
                    onClick={(value) => setExpandedMenu(value)}
                    variant={colorVariant}
                  />
                ) : (
                  <Fragment>
                    <NavDropdownMobile
                      item={item}
                      key={index}
                      toggleMenu={toggleMenu}
                    />
                  </Fragment>
                );
              }
            })}
            {menuItemsFormat.map((item, index) => {
              if (item.children === undefined) {
                return (
                  <Nav.Link key={index} as={Link} to={item.link}>
                    {item.menuitem}
                  </Nav.Link>
                );
              } else {
                return (
                  <Fragment>
                    <NavDropdownMobile
                      item={item}
                      key={index}
                      toggleMenu={toggleMenu}
                    />
                  </Fragment>
                );
              }
            })}
          </Fragment>
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

// Specifies the default values for props
NavbarLandingJobs.defaultProps = {
  transparent: false,
};

// Typechecking With PropTypes
NavbarLandingJobs.propTypes = {
  transparent: PropTypes.bool,
};

export default NavbarLandingJobs;
