import { Fragment, useEffect,useContext } from "react";
import LandingJobsInterno from "../../components/falabella/jobs/LandingJobsInterno";
import "../../assets/css/main.css";
import WOW from "wowjs";
import {langContext} from '../../content/langContext';
import {useIntl} from 'react-intl';

const FalabellaInternoIndex = (props) => {
  const intl = useIntl();
  const idioma = useContext(langContext);
  useEffect(() => {
    if (intl.locale === 'es-ES') {
      idioma.establecerLenguaje('es-ES')
 
    } else {
      idioma.establecerLenguaje('en-US')

    }
    new WOW.WOW({
      live: false,
    }).init();
  }, [idioma, intl.locale]);
  
    return (
        <Fragment>
          <LandingJobsInterno />
        </Fragment>
      );  
  
};

export default FalabellaInternoIndex;
