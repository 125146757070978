import React, { useState } from 'react';
import { Button, Modal,Image } from 'react-bootstrap';
import Slider from "react-slick";
import imageRounded1 from 'assets/images/falabella/falabellatech/protagonista/Manasa_Chandrasekharan.jpg';
import imageRounded2 from 'assets/images/falabella/falabellatech/protagonista/Alexis_ Barquinez.jpg';
import imageRounded3 from 'assets/images/falabella/falabellatech/protagonista/Natalia_Jauregui.jpg';
import imageRounded4 from 'assets/images/falabella/falabellatech/protagonista/Brani _Siliciano.jpg';
import imageRounded5 from 'assets/images/falabella/falabellatech/protagonista/Aman_Saurav.jpg';
import {Col,Row,Card } from "react-bootstrap";
import "./Protagonista.style.css";
import { Avatar } from "components/elements/bootstrap/Avatar";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
        data-testid="next-arrow"
      className={
        "arrowDerechaViveExperienciaCargo slick-next-ViveExperienciaCargo"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaCargo slick-prev-ViveExperienciaCargo"}
      onClick={onClick}
    ></div>
  );
}
const Protagonista = (param) => {
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', descripcion: '',image:'',clase:'' });
  const handleClose = () => setShow(false);

  const handleShow = (title, descripcion,image,clase) => {

    setModalInfo({ title, descripcion,image,clase });
    setShow(true);
  }

  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  const ProtaData = [
    {
      id: 1,
      image: imageRounded1,
      clase: "borderAzul",
      title: <FormattedMessage id="app.protagonista.titulo.1"/>,
      descripcion: <FormattedMessage id="app.protagonista.desc.1"/>,
      descripcioncorta: <FormattedMessage id="app.protagonista.desccorta.1"/>,
      descmodal:<FormattedMessage id="app.protagonista.desc.1"/>
    },
    {
      id: 2,
      image: imageRounded2,
      clase: "borderAzul",
      title: <FormattedMessage id="app.protagonista.titulo.2"/>,
      descripcion: <FormattedMessage id="app.protagonista.desc.2"/>,
      descripcioncorta: <FormattedMessage id="app.protagonista.desccorta.2"/>,
      descmodal:<FormattedMessage id="app.protagonista.desc.2"/>
      
    },
    {
      id: 3,
      image: imageRounded4,
      clase: "borderAzul",
      title: <FormattedMessage id="app.protagonista.titulo.4"/>,
      descripcion: <FormattedMessage id="app.protagonista.desc.4"/>,
      descripcioncorta: <FormattedMessage id="app.protagonista.desccorta.4"/>,
      descmodal:<FormattedMessage id="app.protagonista.desc.4"/>
    },
    {
      id: 4,
      image: imageRounded5,
      clase: "borderAzul",
      title: <FormattedMessage id="app.protagonista.titulo.5"/>,
      descripcion: <FormattedMessage id="app.protagonista.desc.5"/>,
      descripcioncorta: <FormattedMessage id="app.protagonista.desccorta.5"/>,
      descmodal:<FormattedMessage id="app.protagonista.desc.5"/>
    },
    {
      id: 5,
      image: imageRounded3,
      clase: "borderAzul",
      title: <FormattedMessage id="app.protagonista.titulo.3"/>,
      descripcion: <FormattedMessage id="app.protagonista.desc.3"/>,
      descripcioncorta: <FormattedMessage id="app.protagonista.desccorta.3"/>,
      descmodal:<FormattedMessage id="app.protagonista.desc.3"/>
    }
  ];
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-8 bg-experiencia-cargos-prota" id="prota" name="prota">
        <div className="container" data-testid="job-item">
        <Row className="mb-10 justify-content-center">
            <Col lg={12} md={12} sm={12} className="text-start">
                <h3
                className={`mb-2 display-4 fw-bold text-white  ${
                    isLargeScreen ? "classH3Size" : "classH4Size"
                }`}
                >
                <FormattedMessage id="app.tech.prota"/><span style={{ color: "yellow" }}>{"()"}</span>
                </h3>
            </Col>
        </Row>
          <div data-testid="slider">
              <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper" >
                  {ProtaData.length > 0 &&
                  ProtaData.map((item, index) => (
                      <div
                      className="px-md-1 wow zoomIn"
                      data-wow-duration="1s"
                      data-wow-delay={`${index * 0.2}s`}
                      data-wow-offset="5"
                      key={index}

                      >
                          <div>
                              <Card className={`mb-4 border border-secondary-eventos border-card border-card-experiencia-cargos-protagonista`}>
                              <Card.Body className="">
                                  <Row className="mb-10 justify-content-center">
                                      <Col lg={12} md={12} sm={12} className="text-start">
                                          <center>
                                              <Avatar
                                                  size="xxl"
                                                  type="image"
                                                  src={item.image}
                                                  className={`rounded-circle ubicacion ${item.clase}`}
                                                  alt="G K"
                                                  />
                                          </center>
                                      </Col>
                                      <Col lg={12} md={12} sm={12} className="mt-11">
                                          <span className="h4">
                                              <center>
                                                  <b>
                                                      <span style={{ color: '#00D1FF' }}>{item.title}</span>
                                                  </b>
                                              </center>
                                          </span>
                                      </Col>
                                      <Col lg={12} md={12} sm={12} className="mt-5">
                                          <span className="h4 ">
                                              <center>
                                                  <span style={{ color: '#ffffff' }}>
                                                    {'"'}{item.descripcioncorta}{'"'}
                                                  </span>
                                              </center>
                                          </span>
                                      </Col>
                                  </Row>
                              </Card.Body>
                              <Card.Footer>
                                    <div style={{ textAlign: 'center' }}>
                                      <span className="btn rounded-pill mx-2  btn-tech btn btn-primary" key={item.title} onClick={() => handleShow(item.title, item.descmodal,item.image,item.clase)}>
                                          <b>
                                          Ver más {'->'}
                                          </b>
                                      </span>
                                    </div>
                                </Card.Footer>
                              </Card>
                          </div>
                      </div>
                  ))}
              </Slider>
          </div>
        </div>
        <Modal id="mi-modal" size="lg" show={show} onHide={handleClose}>
          <Modal.Body>
            <Card className="profile-card">
              <Card.Body className="">
                <Row className="mb-5 justify-content-center">
                    <Col lg={12} md={12} sm={12} className="text-start pb-4">
                        <center>
                            <Avatar
                                size="xxl"
                                type="image"
                                src={modalInfo.image}
                                className={`rounded-circle ubicacion ${modalInfo.clase}`}
                                alt="G K"
                                />
                        </center>
                    </Col>
                    <Col lg={12} md={12} sm={12} className="mt-11">
                        <span className="h4 ">
                            <center>
                                <b>
                                    <span></span>
                                </b>
                            </center>
                        </span>
                    </Col>
                    <Col lg={12} md={12} sm={12} className="mt-5">
                    <Row className="">
                      <Col xs="2">
                        <Image
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/3-1.png")}
                        />
                      </Col>
                      <Col xs="8">
                        <div
                          className="text-start fs-4"
                          style={{ color: "#97989A" }}
                        >
                          <div>
                          <span className='text-white'>{modalInfo.descripcion}</span>
                          </div>
                          <div className="text-end fw-bolder fst-italic">
                            {modalInfo.title}
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" className="d-flex align-items-end">
                        <Image
                          className="align-bottom"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/cierre-comillas.png")}
                        />
                      </Col>
                    </Row>

                    </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                    <div style={{ textAlign: 'center' }} >
                    <Button className="btn rounded-pill  btn-tech btn btn-primary" onClick={handleClose}>
                      Cerrar
                    </Button>
                    </div>
              </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
    </div>
  );
};

export default Protagonista;
