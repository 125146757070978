import { Fragment, useEffect, useState } from "react";
import "../../assets/css/main.css";
import WOW from "wowjs";
import ExploreJobs from "components/falabella/explore/ExploreJobs";

const validCountries = ["Chile", "Argentina", "Perú", "Colombia", "México", "India"];
const validCommerces = ["Sodimac", "Tottus", "Falabella.com", "Falabella Corporativo", "IKEA", "Fpay", "Fazil", "Falabella Soriana", "Falabella Retail", "Banco Falabella", "Falabella Financiero", "Seguros Falabella", "Linio", "Mallplaza", "Falabella Inmobiliario"];

const validCountryMap = validCountries.reduce((acc, country) => ({...acc, [country.toLowerCase()]: country }), {});
const validCommerceMap = validCommerces.reduce((acc, commerce) => ({...acc, [commerce.replace(/\s/g, '').toLowerCase()]: commerce }), {});

const FalabellaExplore = (props) => {
  const { variante } = props.extraProps || {};
  let paisParam = props.extraProps?.pais ? decodeURIComponent(props.extraProps?.pais) : null;
  let commerceParam = props.extraProps?.commerce ? decodeURIComponent(props.extraProps?.commerce) : null;

  // Traduce el parámetro del país si es necesario
  if (paisParam && validCountryMap[removeAccents(paisParam).toLowerCase()]) {
    paisParam = validCountryMap[removeAccents(paisParam).toLowerCase()];
  }

  // Traduce el parámetro del comercio si es necesario
  if (commerceParam && validCommerceMap[commerceParam.replace(/\s/g, '').toLowerCase()]) {
    commerceParam = validCommerceMap[commerceParam.replace(/\s/g, '').toLowerCase()];
  }

  const [pais, setPais] = useState(paisParam);
  const [commerce, setCommerce] = useState(commerceParam);

  function removeAccents(str) {
    const accents = 'ÁÉÍÓÚáéíóú';
    const accentsOut = 'AEIOUaeiou';
    str = str.split('');
    str.forEach((letter, index) => {
        let i = accents.indexOf(letter);
        if (i !== -1) {
            str[index] = accentsOut[i];
        }
    });
    return str.join('');
  }
  
 

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    
  }, [pais, commerce]);

  useEffect(() => {
      // Si el país y el comercio son válidos, establece los valores originales
      if (paisParam && validCountryMap[removeAccents(paisParam).toLowerCase()]) {
          setPais(validCountryMap[removeAccents(paisParam).toLowerCase()]);
      }
      if (commerceParam && validCommerceMap[commerceParam.replace(/\s/g, '').toLowerCase()]) {
          setCommerce(validCommerceMap[commerceParam.replace(/\s/g, '').toLowerCase()]);
      }
  }, [paisParam, commerceParam]);


  
  return (
    <Fragment>
      <ExploreJobs variante={variante} pais={pais} commerce={commerce} />
    </Fragment>
  );
};

export default FalabellaExplore;
