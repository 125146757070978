import { Fragment, useEffect } from "react";
import "../../assets/css/main.css";
import WOW from "wowjs";
import NotFound from "components/falabella/jobs/NotFound";

const Falabella404 = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Fragment>
      <NotFound />
    </Fragment>
  );
};

export default Falabella404;
