/* eslint-disable no-unused-vars */
// import node module libraries
import { Fragment, useEffect } from "react";
import CarouselIndexJovenes from "./JovenesProfesionales/CarouselIndexJovenes";
import { useLocation } from "react-router-dom";
import PrimerosPasos from "./JovenesProfesionales/PrimerosPasos";
import Tesumas from "./JovenesProfesionales/Tesumas";
import Sabiasque from "./JovenesProfesionales/Sabiasque";
import Despegar from "./JovenesProfesionales/Despegar";
import Cargos from "./JovenesProfesionales/Cargos";
import { useKeycloak } from "@react-keycloak/web";
import { useIntl } from "react-intl";

const LandingJobsJovenes = (props) => {
  const { pathname, hash, key } = useLocation();
  const { keycloak } = useKeycloak();
  const intl = useIntl();
  const handleLogout = () => {
    keycloak.logout();
  };
  useEffect(() => {
    document.body.className = "bg-white";
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    const options = {
      damping: 0.07,
      continuousScrolling: true,
    };
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <div>
      {keycloak.authenticated && (
          <button onClick={handleLogout} className="logout-button">
            {intl.formatMessage({ id: "app.logout" })}
            {keycloak.tokenParsed ? `(${keycloak.tokenParsed.email ? keycloak.tokenParsed.email : keycloak.tokenParsed.preferred_username})` : ""}
          </button>
        )}
        <CarouselIndexJovenes />
        <Tesumas />
        <Cargos />
        <PrimerosPasos />
        <Despegar />
      </div>
    </Fragment>
  );
};

export default LandingJobsJovenes;
