import { Col, Row, Image } from "react-bootstrap";

import arrow from "assets/images/img/arrow-right-g.png";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

const Head = (param) => {
  let formato = param.format;
  let color;
  if(formato === "Sodimac"){
    color = {
      color: '#0073CE',
    };
  }
  if(formato === "Tottus"){
    color = {
      color: '#92be49',
    };
  }
  if(formato === "Falabella.com"){
    color = {
      color: '#FF7600',
    };
  }
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  return (
    <Row className="mb-10 justify-content-center">
      <Col lg={12} md={12} sm={12} className="text-center">
        <h3
          className={`mb-2 display-4 fw-bold  ${
            isLargeScreen ? "" : "classH3Size"
          }`}
        >
          <Image src={arrow} style={{ width: "7%" }} /> <FormattedMessage id="app.cargo.data.nuevasoportunidades"/>{" "}
          <br />
          <FormattedMessage id="app.cargo.data.descubre"/><span style={color}><FormattedMessage id="app.cargo.data.novedades"/></span> <FormattedMessage id="app.cargo.data.tenemosparati"/>
        </h3>
      </Col>
    </Row>
  );
};
export default Head;
