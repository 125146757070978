// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import NavbarLandingJobsTech from "./NavbarLandingJobsTech";
import Footer from "./FooterInterno";
import { useMediaQuery } from "react-responsive";
import { SideScroll } from "components/falabella/jobs/SideScroll/SideScroll";
import Loader from "./Loader"; // Importa el componente Loader

const FalabellaLayoutTech = (props) => {
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    // Simula un tiempo de carga
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  
  return (
    <Fragment>
      {loading ? (
        <Loader /> // Muestra el Loader mientras se carga
        ) : (
          <Fragment>
            <NavbarLandingJobsTech transparent />
            <div className={isLargeScreen ? "pt-13" : ""} style={{ backgroundColor: '#111111' }}>{props.children}</div>
            <Footer id="FOOTER_TECH" />
            <SideScroll />
          </Fragment>
      )}
    </Fragment>
  );
};

export default FalabellaLayoutTech;