/* eslint-disable no-unused-vars */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Avatar } from "components/elements/bootstrap/Avatar";

import Style from "../EventosYNoticias.style.css";

const CourseCard = ({ item, extraclass }) => {
  const GridView = () => {
    return (
      <div>
        <center>
          <Link to="#">
            <Avatar
              size="xxl"
              type="image"
              src={item.image}
              className="rounded-circle ubicacion"
              alt="G K"
            />
          </Link>
        </center>
        <Card
          className={`mb-4   bg-transparent border border-secondary-eventos border-card ${extraclass}`}
          style={{ height: "750px" }}
        >
          <Card.Body className="" style={{ marginTop: "150px" }}>
            <h4 className="mb-2 text-center">
              <Link
                to="#"
                className="text-inherit"
                style={{ color: `${item.color}` }}
              >
                {item.title}
              </Link>
            </h4>
            <p className="mt-4 text-card text-size">{item.text}</p>
            <p className="text-card text-size">{item.text2}</p>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <Fragment>
      <GridView />
    </Fragment>
  );
};

export default CourseCard;
