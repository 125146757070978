// import node module libraries
import { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const NotFound = (props) => {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    document.body.className = "bg-white";
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <div className="py-16 w-100" name="notfound" id="notfound">
        <div className="container">
          <h3 className="text-center">
            Lo que buscas no está aquí! <br />
            Puedes volver al <Link to="/">inicio</Link> o Explorar nuestras
            <Link to="/explorar"> oportunidades de trabajo.</Link>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
