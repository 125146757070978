/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState,  useContext } from "react";
import Slider from "react-slick";
import { OfertasLaboralesService } from "../../../../services/OfertasLaborales.service";
import { Spinner } from "react-bootstrap";
import "./Cargos.style.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ItemCargo from "./components/ItemCargo";
import Head from "./components/head";
import { FilterContext } from "hooks/FilterContext";
import { useHistory } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={
        "arrowDerechaViveExperienciaCargo slick-next-ViveExperienciaCargo"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaCargo slick-prev-ViveExperienciaCargo"}
      onClick={onClick}
    ></div>
  );
}

const Cargos = (param) => {
  const history = useHistory();
  const { setFilters } = useContext(FilterContext);
  const offerType =  "external";

  const botonVerTodasOfertas = (e) => {
    const payload = {
      country: [],
      area: [],
      company:[],
      jobtype: [],
      tag:"FalabellaTech"
    };
    setFilters(payload);
    history.push("/explorar-tech");
  };

  let filter = {
    country:[],
    area: [],
    company:[],
    jobtype: [],
    page:1,
    perPage:50,
    search:"",
    type: offerType,
    tag:"FalabellaTech"
  };

  const [offerList, setOfferList] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useLayoutEffect(() => {
    const getOfertasLaborales = async () => {

      setLoading(true);
      const service = new OfertasLaboralesService(offerType);
      const config = 'FalabellaTech'
      const response = await service.getOfertasLaboralesByFilter(filter,config);
      setLoading(false);
      setOfferList(response.data.data.list)
    };
    getOfertasLaborales();
  }, []);
  return (
    <div className="py-8 bg-experiencia-cargos-tech" id="ofertasinternos" name="ofertasinternos">
      {offerList.length <= 3  ? <div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" data-testid="loading-spinner" />loading
          </div>
        ) : (
          <div className="container" data-testid="job-item1">
            <Head />
            <Row>
              {offerList.length > 0 &&
                offerList.map((item, index) => (
                  <Col xs lg="4">
                    <div
                      className="px-md-1 wow zoomIn"
                      data-wow-duration="1s"
                      data-wow-delay={`${index * 0.2}s`}
                      data-wow-offset="5"
                      key={item.offer_id}
                    >
                      <ItemCargo
                        key={index}
                        item={item}
                        extraclass="mx-2"
                        
                      />
                    </div>
                  </Col>
              ))}
            </Row>
          </div>
        )}
        </div> 
      :(
        <div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" data-testid="loading-spinner1"/>
          </div>
        ) : (
          <div className="container" data-testid="job-item">
            <Head />
            <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
              {offerList.length > 0 &&
                offerList.map((item, index) => (
                  <div
                    className="px-md-1 wow zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay={`${index * 0.2}s`}
                    data-wow-offset="5"
                    key={item.offer_id}
                  >
                    <ItemCargo
                      key={index}
                      item={item}
                      extraclass="mx-2"
                      
                    />
                  </div>
                ))}
            </Slider>
          </div>
        )}
        </div>)
      }
        <div style={{ textAlign: 'center' }}>
          <span className="btn rounded-pill mx-2   btn btn-tech-cargo" onClick={() => {
          botonVerTodasOfertas();
        }}>
              <b>
              Ver todas las ofertas TECH {'->'}
              </b>
          </span>
        </div>
    </div>
  );
};

export default Cargos;
