import { Col, Image } from "react-bootstrap";
import arrow from "assets/images/img/arrow-right-g.png";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

const SectionHeadingCenter = (param) => {
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  let formato = param.format;
  let color;
  let text;
  if(formato === "Sodimac"){
    color = {
      color: '#0073CE',
      
    };
    text='app.mapa.masdecienmilinternosodimac';
  }
  if(formato === "Tottus"){
    color = {
      color: '#92be49',
      
    };
    text='app.mapa.masdecienmilinternotottus';
  }

  if(formato === "Falabella Retail"){
    color = {
      color: '#CFD400',
    };
    text='app.mapa.masdecienmilinternofalabellaretail';
  }
  if(formato === "Mallplaza"){
    color = {
      color: '#E9004B',
    };
    text='app.mapa.masdecienmilinternomallplaza';
  }
  if(formato === "Banco Falabella"){
    color = {
      color: '#3dae2b',
    };
    text='app.mapa.masdecienmilinternobancofalabella';
  }
  if(formato === "Falabella Inmobiliario"){
    color = {
      color: '#515254',
    };
    text='app.mapa.masdecienmilinternofalabellainmobiliario';
  }
  if(formato === "Falabella Financiero"){
    color = {
      color: '#007836',
    };
    text='app.mapa.masdecienmilinternofalabellafinanciero';
  }
  if(formato === "Falabella Soriana"){
    color = {
      color: '#007836',
    };
    text='app.mapa.masdecienmilinternofalabellasoriana';
  }
  if(formato === "Seguros Falabella"){
    color = {
      color: '#007836',
    };
    text='app.mapa.masdecienmilinternosegurosfalabella';
  }
  if(formato === "Falabella Equipo Corporativo"){
    color = {
      color: '#92be49',
    };
    text='app.mapa.masdecienmilinternofalabellacorp';
  }
  return (
    <div>
      {isLargeScreen ? (
        <div className="position-absolute" style={{ paddingLeft: "10%" }}>
          <Col lg={10} md={12} sm={12} className="text-center">
            <h3 className="mb-2 display-4 fw-bold ">
              <Image src={arrow} style={{ width: "4%" }} /> <FormattedMessage id="app.mapa.somosinterno"/>{" "}
              <span style={color}><FormattedMessage id={text}/></span>{" "}
            </h3>
            <h2 className="mb-2  display-4 ">
              <FormattedMessage id="app.mapa.encuentrainterno"/>
            </h2>
          </Col>
        </div>
      ) : (
        <Col lg={12} md={12} sm={12} className="text-center">
          <h3 className="mb-2 display-4 fw-bold classH3Size">
            <Image src={arrow} style={{ width: "6%" }} /> <FormattedMessage id="app.mapa.somosinterno"/>{" "}
            <span style={color}><FormattedMessage id={text}/></span>{" "}
          </h3>
          <h2 className="mb-2 display-4  ">
          <FormattedMessage id="app.mapa.encuentrainterno"/>
          </h2>
        </Col>
      )}
    </div>
  );
};

export default SectionHeadingCenter;
