const falabella1 = require("assets/images/falabella/internos/carousel/Banco/1.jpg");
const falabella2 = require("assets/images/falabella/internos/carousel/Banco/2.jpg");
const falabella3 = require("assets/images/falabella/internos/carousel/Banco/3.jpg");
const falabella4 = require("assets/images/falabella/internos/carousel/Banco/4.JPG");
const falabella5 = require("assets/images/falabella/internos/carousel/Banco/5.jpg");
const falabella6 = require("assets/images/falabella/internos/carousel/Banco/6.JPG");
const falabella7 = require("assets/images/falabella/internos/carousel/Banco/7.JPG");
const falabella8 = require("assets/images/falabella/internos/carousel/Banco/8.jpg");
const falabella9 = require("assets/images/falabella/internos/carousel/Banco/9.jpg");
const falabella10 = require("assets/images/falabella/internos/carousel/Banco/10.jpg");
const falabella11 = require("assets/images/falabella/internos/carousel/Banco/11.JPG");
const falabella12 = require("assets/images/falabella/internos/carousel/Banco/12.jpg");

export const GrandesEspaciosDataBancoFalabella = 
[
  [
    {
      id: 1,
      image: falabella1,
      position: "h-20rem",
      image2: falabella2,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella4,
      position: "h-16rem",
      image2: falabella3,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella5,
      position: "h-20rem",
      image2: falabella6,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella7,
      position: "h-20rem",
      image2: falabella8,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella9,
      position: "h-16rem",
      image2: falabella10,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella11,
      position: "h-20rem",
      image2: falabella12,
      position2: "h-18rem",
    },
  ]
];



export default GrandesEspaciosDataBancoFalabella;
