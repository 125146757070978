/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Fragment, useLayoutEffect, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Modal,
  Accordion,
} from "react-bootstrap";
import { Link, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { OfertasLaboralesService } from "services/OfertasLaborales.service";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import BeneficiosES from "./BeneficiosData.json";
import BeneficiosEN from "./BeneficiosDataIngles.json";
import dataEmpresas from "../explore/Filters/mueveteData.json";
import Moment from "react-moment";
import "moment/locale/es";
import UAParser from "ua-parser-js";
import { useKeycloak } from "@react-keycloak/web";
import {
  faChevronLeft,
  faCity,
  faEarthAmericas,
  faShareNodes,
  faCopy,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import "./OfferDetail.style.css";
import { FormattedMessage } from "react-intl";

import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const OfferDetail = (props) => {
  const lang = localStorage.getItem("lang");
  const [showShare, setShowShare] = useState(false);
  const ShareClose = () => setShowShare(false);
  const ShareShow = () => setShowShare(true);
  const history = useHistory();
  const match = useRouteMatch();
  const { id, type } = match.params;
  const { keycloak, initialized } = useKeycloak();
  const isAuth = keycloak.authenticated;
  const [offer, setOffer] = useState(null);
  const [hasLoggedView, setHasLoggedView] = useState(false);
  const [idiomaMoment, setIdiomaMoment] = useState("");
  const intl = useIntl();
  useLayoutEffect(() => {
    const { locale } = intl;
    if (locale === "es-ES") {
      setIdiomaMoment("es");
      moment.locale("es");
      setBeneficios(BeneficiosES);
    } else {
      setIdiomaMoment("en");
      moment.locale("en");
      setBeneficios(BeneficiosEN);
    }
  }, [intl.locale]);

  const [beneficios, setBeneficios] = useState("");

  useLayoutEffect(() => {
    let service = new OfertasLaboralesService(type);
    service.getOfertasLaborales(id).then((response) => {
      setOffer(response.data);
    });
  }, [id, type]);

  const location = useLocation();
  const url =
    window.location.protocol +
    "/" +
    window.location.host +
    "/" +
    location.pathname;
  const shareUrl = url.toString();

  // eslint-disable-next-line no-unused-vars
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  let obtenerEmpresasUnicas = [];
  let company;

  const handleLogout = () => {
    keycloak.logout();
  };
  if (offer !== null) {
    const empresas = dataEmpresas
      .filter((dataSource) => {
        return dataSource.Company === offer.company;
      })
      .map((index) => index.muevete);

    obtenerEmpresasUnicas = empresas.reduce((acc, item) => {
      if (!acc.includes(item)) {
        acc.push(item);
      }
      return acc;
    }, []);
    if (offer.company_code === "falabella_equipo_corporativo") {
      switch (offer.requisition_company) {
        case "Falabella.com Chile":
        case "Falabella.com Perú":
        case "Falabella.com Colombia":
        case "Falabella.com Regional":
        case "Home Delivery":
        case "Home Delivery Perú":
          company = "Falabella.com";
          break;
        case "Fpay Argentina":
        case "Fpay Chile":
          company = "Banco Falabella";
          break;
        case "Fazil Chile":
        case "Fazil":
          company = "Tottus";
          break;
        case "Linio Colombia":
        case "Linio México":
          company = "Linio";
          break;
        case "Falabella Servicios":
          company = "Falabella Corporativo";
          break;
        case "Falabella Retail Chile":
          company = "Falabella Corporativo";
          break;
        case "Falabella SA Chile":
          company = "Falabella Corporativo";
          break;
        case "Falabella Tecnología Corporativo":
          company = "Falabella Corporativo";
          break;
        case "Sodimac Chile":
          company = "Sodimac";
          break;
        case "Tottus Perú":
          company = "Tottus";
          break;
        case "Seguros Falabella":
          company = "Seguros Falabella";
          break;
        default:
          company = offer.company;
          break;
      }
    } else {
      company = obtenerEmpresasUnicas[0];
    }
  }

  useEffect(() => {
    if (keycloak.authenticated && offer && !hasLoggedView) {
      const parser = new UAParser();
      const deviceInfo = {
        deviceType: parser.getResult().device.type || "desktop",
        browserName: parser.getResult().browser.name,
        os: parser.getResult().os.name,
        time: moment()
          .tz("America/Santiago")
          .format("YYYY-MM-DDTHH:mm:ss-04:00"),
        offer: offer.offer_id,
      };
      const service = new OfertasLaboralesService();
      const userEmail =
        keycloak.tokenParsed.email || keycloak.tokenParsed.preferred_username;
      service
        .postLoggingMuevete(
          "Offer View",
          userEmail,
          deviceInfo
        )
        .then((response) => {
          setHasLoggedView(true);
        })
        .catch((error) =>
          console.log("Error al registrar el evento de visualización:", error)
        );
    }
  }, [keycloak.authenticated, offer]);

  const handlePostular = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    const currentTime = moment()
      .tz("America/Santiago")
      .format("YYYY-MM-DDTHH:mm:ss-04:00");

    const deviceInfo = {
      deviceType: result.device.type || "desktop",
      browserName: result.browser.name,
      os: result.os.name,
      time: currentTime,
      offer: offer.offer_id,
    };

    if (isAuth) {
      window.open(offer.url + "&check_login=1");
      const service = new OfertasLaboralesService();
      const userEmail =
        keycloak.tokenParsed.email || keycloak.tokenParsed.preferred_username;
      service
        .postLoggingMuevete("Postular Click", userEmail, deviceInfo)
        .then((response) => console.log("", response))
        .catch((error) => console.log("Error al registrar el evento:", error));
    } else {
      window.open(offer.url);
    }
  };

  const handleLoginAndReload = () => {
    keycloak
      .login({ redirectUri: offer.url + "&check_login=1" })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };

  return (
    <Fragment>
      {keycloak.authenticated && (
        <button onClick={handleLogout} className="logout-button">
          {intl.formatMessage({ id: "app.logout" })}
          {keycloak.tokenParsed
            ? `(${
                keycloak.tokenParsed.email
                  ? keycloak.tokenParsed.email
                  : keycloak.tokenParsed.preferred_username
              })`
            : ""}
        </button>
      )}
      {!offer ? (
        <Container className="text-center">
          <Spinner animation="border" variant="primary" />
        </Container>
      ) : (
        <Container className="pt-10">
          <Row className="pb-4">
            <Col>
              <p className="fs-2 fw-bold titleGreenOferta">{offer.title}</p>
            </Col>
            <Col sm="auto">
              <Link to="" onClick={() => history.goBack()}>
                <FontAwesomeIcon color="primary" icon={faChevronLeft} />{" "}
                <FormattedMessage id="app.oferta.volver" />
              </Link>{" "}
            </Col>
          </Row>
          <h4>
            <FontAwesomeIcon className="brandOffer" icon={faCity} /> {company} -{" "}
            {offer.area}
          </h4>
          <h4>
            <FontAwesomeIcon className="brandOffer" icon={faEarthAmericas} />{" "}
            {offer.country}, {offer.state}, {offer.city}.
          </h4>
          <h4>
            <FontAwesomeIcon className="brandOffer" icon={faCalendar} />{" "}
            <Moment
              locale={idiomaMoment}
              format="D MMMM YYYY"
              className="text-capitalize"
            >
              {offer.date}
            </Moment>
          </h4>
          <hr></hr>
          <Col className="">
            <Link onClick={ShareShow}>
              <h5 className="btn rounded-pill mx-2 btn btn-outline-dark">
                <FontAwesomeIcon className="" icon={faShareNodes} size="lg" />
                {lang === "en-US" ? " Share" : " Compartir"}
              </h5>
            </Link>
          </Col>
          <hr></hr>
          <Card>
            <Card.Header>
              <Card.Title>
                <h4>
                  <FormattedMessage id="app.oferta.funciones" />
                </h4>
              </Card.Title>
              <p>{parse(offer.description.replaceAll("\n", "<br />"))}</p>
            </Card.Header>
            <Card.Body>
              <h4>
                <FormattedMessage id="app.oferta.requerimientos" />
              </h4>
              <p>{parse(offer.requirements)}</p>
            </Card.Body>
            {obtenerEmpresasUnicas.length <= 1 ? (
              <div>
                {obtenerEmpresasUnicas.map((item) => (
                  <Fragment>
                    {beneficios
                      .filter(function (data) {
                        return data.Muevete === item;
                      })
                      .map((dato) => (
                        <div>
                          <hr></hr>
                          <Card.Body>
                            <Accordion defaultActiveKey="0" flush>
                              <Accordion.Item eventKey="0" alwaysOpen>
                                <Accordion.Header>
                                  <h4>
                                    <FormattedMessage id="app.menu.somosfalabella" />
                                  </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: dato.somosfalabella,
                                    }}
                                  ></div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1" alwaysOpen>
                                <Accordion.Header>
                                  <h4>
                                    <FormattedMessage id="app.menu.porquetrabajar" />
                                  </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: dato.porquetrabajar,
                                    }}
                                  ></div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2" alwaysOpen>
                                <Accordion.Header>
                                  <h4>
                                    <FormattedMessage id="app.menu.comohacemos" />
                                  </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: dato.comohacemos,
                                    }}
                                  ></div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Card.Body>
                        </div>
                      ))}
                  </Fragment>
                ))}
              </div>
            ) : (
              <Fragment>
                {obtenerEmpresasUnicas.map((item) => (
                  <Fragment>
                    {beneficios
                      .filter(function (data) {
                        return data.Muevete === item;
                      })
                      .map((dato) => (
                        <div>
                          {offer.company.toLowerCase() ===
                            dato.Muevete.toLowerCase() && (
                            <div>
                              <hr></hr>
                              <Card.Body>
                                <Accordion defaultActiveKey="0" flush>
                                  <Accordion.Item eventKey="0" alwaysOpen>
                                    <Accordion.Header>
                                      <h4>
                                        <FormattedMessage id="app.menu.somosfalabella" />
                                      </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: dato.somosfalabella,
                                        }}
                                      ></div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1" alwaysOpen>
                                    <Accordion.Header>
                                      <h4>
                                        <FormattedMessage id="app.menu.porquetrabajar" />
                                      </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: dato.porquetrabajar,
                                        }}
                                      ></div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2" alwaysOpen>
                                    <Accordion.Header>
                                      <h4>
                                        <FormattedMessage id="app.menu.comohacemos" />
                                      </h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: dato.comohacemos,
                                        }}
                                      ></div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Card.Body>
                            </div>
                          )}
                        </div>
                      ))}
                  </Fragment>
                ))}
              </Fragment>
            )}

            <Card.Footer className="text-center">
              {isAuth ? (
                <Button
                  variant="secondary"
                  className="btn-postularcol rounded-pill mx-2"
                  onClick={handlePostular}
                >
                  <FormattedMessage id="app.oferta.boton.postularcolaborador" />
                </Button>
              ) : (
                <Fragment>
                  <Button
                    variant="secondary"
                    className="btn-postularcol rounded-pill mx-2"
                    onClick={handleLoginAndReload}
                  >
                    <FormattedMessage id="app.oferta.boton.postularcolaborador" />
                  </Button>
                  <Button
                    variant="secondary"
                    className="btn-postular rounded-pill mx-2"
                    onClick={() => window.open(offer.url)}
                  >
                    <FormattedMessage id="app.oferta.boton.postular" />
                  </Button>
                </Fragment>
              )}
            </Card.Footer>
          </Card>
        </Container>
      )}
      <Modal show={showShare} size="md" onHide={ShareClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang === "en-US" ? " Share this add" : " Comparte este aviso"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="text-center p-4">
            <WhatsappShareButton url={shareUrl} className="offerWhatapp">
              <WhatsappIcon size={42} round={true} />
            </WhatsappShareButton>
            <LinkedinShareButton url={shareUrl} className="offerLinkedin">
              <LinkedinIcon size={42} round={true} />
            </LinkedinShareButton>
            <EmailShareButton url={shareUrl} className="offerMail">
              <EmailIcon size={42} round={true} />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl} className="offerMail">
              <FacebookIcon size={42} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} className="offerMail">
              <TwitterIcon size={42} round={true} />
            </TwitterShareButton>
          </Col>
          <Col className="p-4">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="URL"
                aria-label="URL"
                aria-describedby="basic-addon2"
                defaultValue={shareUrl}
              />
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipBoard(shareUrl)}
                id="button-addon2"
              >
                <FontAwesomeIcon icon={faCopy} size="lg" />
              </Button>
            </InputGroup>
          </Col>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default OfferDetail;
