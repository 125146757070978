/* eslint-disable no-unused-vars */
import { Col, Row, Container, Image, Carousel } from "react-bootstrap";
import { useState, useEffect } from "react";
import data from "./GrandesEspaciosData";
import arrow from "assets/images/img/arrow-right-g.png";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';
const carouselItemDataMobile = data.map((item, index) => {
  return (
    <Carousel.Item>
      <div className="pt-5 bg-white">
        <Container fluid className="px-md-5">
          <Row>
            <Col lg={12} xs={12} className="">
              <Row>
                {item.slice(0, 2).map((subItem, subIndex) => (
                  <Col xs={6} className="px-1 ">
                    <div
                      className={`bg-cover wow fadeIn rounded-3 mb-2 ${subItem.position}`}
                      data-wow-duration="1s"
                      data-wow-delay={`${subIndex * 0.2}s`}
                      data-wow-offset="5"
                    >
                      <Image src={subItem.image}  style={{ width: "100%" }} className={`bg-cover rounded-3 mb-2 ${subItem.position}`} />
                    </div>
                    <div
                      className={`bg-cover wow fadeIn rounded-3 mb-2 ${subItem.position2}`}
                      data-wow-duration="1s"
                      data-wow-delay={`${subIndex * 0.2}s`}
                      data-wow-offset="5"
                    >
                      <Image src={subItem.image2}  style={{ width: "100%" }} className={`bg-cover rounded-3 mb-2 ${subItem.position2}`} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Carousel.Item>
  );
});

const GrandesEspacios = () => {
  const [largeScreenSliderSelectedIndex, setLargeScreenSliderSelectedIndex] =
    useState(0);
  const [smallScreenSliderSelectedIndex, setSmallScreenSliderSelectedIndex] =
    useState(0);
  const isLargeScreen = useMediaQuery({ minWidth: 990 });

  return (
    <div className="py-8" id="espacios" name="espacios">
      <div className="mb-10 pt-5 justify-content-center">
        <center>
          <Col lg={12} md={12} sm={12} className="text-center">
            <h3
              className={`mb-2 display-4 fw-bold ${
                isLargeScreen ? "" : "classH3Size"
              }`}
            >
              <Image src={arrow} style={{ width: "4%" }} /> <FormattedMessage id="app.espacio.disfruta"/>
              <br />
              <span style={{ color: "#92BE49" }}><FormattedMessage id="app.espacio.talento"/></span>
            </h3>
          </Col>
        </center>
      </div>

      {isLargeScreen ? (
        <Carousel
          activeIndex={largeScreenSliderSelectedIndex}
          onSelect={(selectedIndex) =>
            setLargeScreenSliderSelectedIndex(selectedIndex)
          }
          indicators={false}
          controls={true}
          pause={false}
        >
          {data.map((item, index) => (
            <Carousel.Item>
              <div className="pt-5 bg-white">
                <Container fluid className="px-md-5">
                  <Row>
                    <Col lg={12} xs={12} className="d-none d-lg-block ">
                      <Row>
                        {item.map((subItem, subIndex) => (
                          <Col
                            md={4}
                            xs={4}
                            className="px-1 wow pulse"
                            data-wow-duration="1s"
                            data-wow-delay={`${index * 0.2}s`}
                            data-wow-offset="5"
                          >
                            <div
                              className={`bg-cover rounded-3 mb-2 ${subItem.position}`}
                            >
                              <Image src={subItem.image}  style={{ width: "100%" }} className={`bg-cover rounded-3 mb-2 ${subItem.position}`} />
                            </div>
                            <div
                              className={`bg-cover rounded-3 mb-2 ${subItem.position2}`}
                            >
                              <Image src={subItem.image2}  style={{ width: "100%" }} className={`bg-cover rounded-3 mb-2 ${subItem.position2}`} />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div>
          <Carousel
            activeIndex={smallScreenSliderSelectedIndex}
            onSelect={(selectedIndex) =>
              setSmallScreenSliderSelectedIndex(selectedIndex)
            }
            indicators={false}
            controls={true}
          >
            {carouselItemDataMobile}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default GrandesEspacios;
