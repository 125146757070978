/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import JobCard from "./JobCard/JobCard";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { OfertasLaboralesService } from "services/OfertasLaborales.service";
import WOW from "wowjs";
import { Filters } from "./Filters/Filters";
import Paginate from "./Pagination/Paginate";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { scroller } from "react-scroll";
import { FilterContext } from "../../../hooks/FilterContext";
import { FormattedMessage } from "react-intl";
import { useKeycloak } from "@react-keycloak/web";
const wow = new WOW.WOW({
  live: true,
});

const ExploreJobs = (props) => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search'); 
  const currentPath = location.pathname;
  const { variante } = props;
  const { pais } = props;
  const history = useHistory();
  const { commerce } = props;
  const params = useParams();
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [listadoOfertas, setListadoOfertas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const maxPages = isLargeScreen ? 8 : 4;
  const limit = 20;
  const type = variante === "muevete" ? "internal" : "external";
  const isAuth = keycloak.authenticated;
  const finalType = isAuth ? "internal" : type;
  let newFilter = {
    country: [],
    area: [],
    company: [],
    jobtype: [],
  };

  if (pais) {
    newFilter.country = pais;
  }
  if (commerce) {
    newFilter.commerce = commerce;
  }
  const { filters: contextFilters } = useContext(FilterContext);

  const getListadoOfertas = useCallback(
    async (page, filters) => {
      try {
        setLoading(true);
        let service = new OfertasLaboralesService(finalType);
        let data = null;
  
        // Preparar los filtros basados en props, contexto, y argumentos
        let effectiveFilters = { ...filters };
        if (pais) {
          effectiveFilters.country = pais;
        }
        if (commerce) {
          effectiveFilters.company = commerce;
        }
  
        let path = "";
        if (currentPath === "/explorar-tech") {
          path = "FalabellaTech";
          effectiveFilters.tag = "FalabellaTech";
        } else if (currentPath === "/explorar-jovenes") {
          path = "JP";
          effectiveFilters.tag = "JP";
        }
  
        // Añadir número de página y límite a los filtros
        effectiveFilters = {
          ...effectiveFilters,
          page, // Usa el número de página actual
          perPage: limit,
          type: finalType,
          ...(searchTerm && { search: searchTerm }),

        };
  
        // Llamar al servicio con los filtros efectivos
        data = await service.getOfertasLaboralesByFilter(effectiveFilters, path);

        // Actualizar el estado con los nuevos datos
        setListadoOfertas(data.data.data.list);
        setTotalPages(data.data.data.totalPages);
        setCurrentPage(data.data.data.currentPage); // Asegúrate de que este valor es correcto según la respuesta de tu API
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [finalType, pais, commerce, currentPath, searchTerm, limit]
  );
  
  

  useLayoutEffect(() => {
    wow.init();
    getListadoOfertas(1, contextFilters);
  }, [contextFilters, finalType]);

  useEffect(() => {
    if (currentPath === "/muevete" && !keycloak.authenticated) {
      //history.push("/");
    } else {
      wow.sync();
    }
  }, [currentPath, keycloak.authenticated, history]);

  const handlePageChange = (page) => {
    scroller.scrollTo("filter");
    setCurrentPage(page);
    getListadoOfertas(page, selectedFilters);
  };

  const handleMoveNext = () => {
    scroller.scrollTo("filter");
    getListadoOfertas(currentPage + 1, selectedFilters);
  };

  const handleMovePrev = () => {
    scroller.scrollTo("filter");
    getListadoOfertas(currentPage - 1, selectedFilters);
  };

  const handleSearch = useCallback(
    async (filters) => {
      setSelectedFilters(filters);
      setCurrentPage(1);
      getListadoOfertas(1, filters);
    },
    [getListadoOfertas]
  );
  

  return (
    <div className="bg-white">
      {variante === "muevete" ? (
        <Fragment>
          <Row>
            <div className="bg-muevete-pre">&nbsp;</div>
          </Row>
          <Row>
            <div className="bg-muevete">
              <Fragment>
                <div className="justify-content-center py-4">
                  <Col lg={12} md={12} sm={12} className="text-center">
                    <h3
                      className={`pb-3 mb-2 display-4 fw-bold text-color-footer  ${
                        isLargeScreen ? "" : "classH3Size"
                      }`}
                    >
                      <Image
                        src={require("assets/images/img/arrow-right.png")}
                        style={{ width: isLargeScreen ? "4vw" : "8vw" }}
                      />{" "}
                      <FormattedMessage id="app.muevete.titulo.1" />{" "}
                      <span style={{ color: "#92BE49" }}>
                        <FormattedMessage id="app.muevete.titulo.2" />
                      </span>
                      <br />
                    </h3>
                    <span className="mb-2 subTitle py-6">
                      <FormattedMessage id="app.muevete.titulo.3" />
                    </span>
                    <h3
                      className={`pt-3 mb-2 display-4 fw-bold text-color-footer  ${
                        isLargeScreen ? "" : "classH3Size"
                      }`}
                    >
                      <span style={{ color: "#92BE49" }}>
                        <FormattedMessage id="app.muevete.titulo.4" />
                      </span>
                    </h3>
                  </Col>
                </div>
              </Fragment>
            </div>
          </Row>
        </Fragment>
      ) : (
        <Fragment>
          <h3 className={`text-center  ${isLargeScreen ? "mt-4 pt-8" : "mt-8 pt-8"}`}>
            <FormattedMessage id="app.oportunidades.titulo.1" />{" "}
            <span className="text-primary">
              <FormattedMessage id="app.oportunidades.titulo.2" />
            </span>
            <FormattedMessage id="app.oportunidades.titulo.3" />
          </h3>
        </Fragment>
      )}

      <Container>
        <Filters
          className="pt-9"
          onSearch={handleSearch}
          type={finalType}
          {...params}
        />
        <Row>
          {loading ? (
            <Container className="text-center" style={{ padding: "8%" }}>
              <Spinner animation="border" variant="primary" />
            </Container>
          ) : listadoOfertas.length > 0 ? (
            listadoOfertas.map((item, index) => {
              return (
                <Col
                  className="my-4 wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay={`${(index % 8) * 0.05}s`}
                  lg="3"
                  xs="12"
                  md="6"
                >
                  <JobCard item={item} type={finalType} />
                </Col>
              );
            })
          ) : (
            <Container className="text-center">
              <h4>
                <FormattedMessage id="app.losentimos.titulo.1" />
                <br />
                <FormattedMessage id="app.losentimos.titulo.2" />
              </h4>
            </Container>
          )}
        </Row>
        {listadoOfertas.length > 0 && (
          <Row className="mt-4">
            <Col lg="3" className="d-none d-lg-block">
              {" "}
            </Col>
            <Col lg="6" className="align-items-center">
              <Paginate
                totalPages={totalPages}
                currentPage={currentPage}
                maxPages={maxPages}
                moveToPage={handlePageChange}
                moveToNext={handleMoveNext}
                moveToPrev={handleMovePrev}
              />
            </Col>
            <Col lg="auto" className="d-none d-lg-block">
              {" "}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ExploreJobs;
