import { useMediaQuery } from "react-responsive";
import "./NuestroProposito.style.css";
import PropositosDesk from "./PropositosDesk";
import PropositosMobile from "./PropositosMobile";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const NuestroProposito = (props) => {
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });

  return isLargeScreen ? <PropositosDesk /> : <PropositosMobile />;
};

export default NuestroProposito;
