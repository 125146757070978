/* eslint-disable no-unused-vars */
// import node module libraries
import { Fragment, useEffect } from "react";
import CarouselPresenciaInterno from "./CarouselPresencia/CarouselPresenciaInterno";
import TuNosImportasInterno from "./TuNosImportas/TuNosImportasInterno";
import ViveExperienciaInterno from "./ViveExperiencia/ViveExperienciaInterno";
import ViveExperienciaCargosInternos from "./ViveExperienciaCargos/ViveExperienciaCargosInternos";
import GrandesEspaciosInterno from "./GrandesEspacios/GrandesEspaciosInterno";
import { useLocation } from "react-router-dom";

const LandingJobsInterno = () => {
  let url = window.location.pathname;
  let formato;
  if(url === "/sodimac"){
    formato = "Sodimac";
  }
  if(url === "/tottus"){
    formato = "Tottus";
  }
  if(url === "/falabellaretail"){
    formato = "Falabella Retail";
  }
  if(url === "/mallplaza"){
    formato = "Mallplaza";
  }
  if(url === "/bancofalabella"){
    formato = "Banco Falabella";
  }
  if(url === "/falabellafinanciero"){
    formato = "Falabella Financiero";
  }
  if(url === "/falabellasoriana"){
    formato = "Falabella Soriana";
  }
  if(url === "/segurosfalabella"){
    formato = "Seguros Falabella";
    
  } if(url === "/falabellainmobiliario"){
    formato = "Falabella Inmobiliario";

  } if(url === "/falabellacorp"){
    formato = "Falabella Equipo Corporativo";
  }
  
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    document.body.className = "bg-white";
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    const options = {
      damping: 0.07,
      continuousScrolling: true,
    };
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <div>
        <CarouselPresenciaInterno className="p-8" format={formato}/>
        <TuNosImportasInterno format={formato}/>
        <GrandesEspaciosInterno format={formato}/>
        <ViveExperienciaInterno format={formato}/>
        <ViveExperienciaCargosInternos format={formato}/>
      </div>
    </Fragment>
  );
};

export default LandingJobsInterno;
