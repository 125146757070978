/* eslint-disable no-unused-vars */
import { Col, Image } from "react-bootstrap";

import arrow from "assets/images/img/arrow-right.png";
import { Fragment, useState,useEffect  } from "react";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';
import ReactPlayer from "react-player";
import Cinema from "../Cinema/Cinema";
import play from "assets/images/falabella/DESKTOP FALABELLA VB4-39.png";
import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'


export const VideoFiles = {
  CORTO: 'corto_tesumas.mp4',
  LARGO: 'largo_tesumas.mp4',
}

const TeSumas = () => {
  const [videoURLs, setVideoURLs] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const service = new OfertasLaboralesService();
  
  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };
  
  useEffect(() => {
    const fetchVideos = async () => {
      const videosUrl = {
        CORTO: await getVideoURL(VideoFiles.CORTO),
        LARGO: await getVideoURL(VideoFiles.LARGO),
      }
      setVideoURLs(videosUrl)
    }
    fetchVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLargeScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div className="py-4" id="cultura" name="cultura">
      <Fragment>
        
        <div className="video-cultura" style={{ position: 'relative' }}>

          <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundColor: 'black', opacity: 0.7,zIndex:3 }} />
            <ReactPlayer
            data-testid="player"
              url={videoURLs.CORTO}
              controls={false}
              loop
              muted
              playing
              width="100%"
              style={{ width: "100%",opacity: isLargeScreen ? '90%' : '',backgroundColor: isLargeScreen ? 'black' : '' }}
            />
        </div>

          <div>
            <Col lg={12} md={12} sm={12} className={`${
                    isLargeScreen ? "" : "text-center"
                  }`} >
            <div className="mensajetitle p-8">
                  <h3
                  className={`mb-2 display-4 fw-bold ${
                    isLargeScreen ? "classH4Size" : "classH3Size"
                  }`}
                  >
                  { isLargeScreen ? 
                  <div>
                    <div className="text-white">
                      <span style={{ color: "yellow" }}>{ '<h1>' }</span>
                    </div>
                    <div className="text-white" style={{paddingLeft:"30px"}}>
                      <FormattedMessage id="app.tech.video"/>
                    </div>
                    <div className="text-white">
                      <span style={{ color: "yellow" }}>{ '</h1>' }</span>
                    </div>
                  </div>: 
                  <div>
                    <div className="text-white">
                      <span style={{ color: "yellow" }}>{ '<h1>' }</span> <FormattedMessage id="app.tech.video"/> <span style={{ color: "yellow" }}>{ '</h1>' }</span>
                    </div>
                  </div>
                  }                 
                  </h3>
                <h4
                  data-testid="video-player"
                  className="mb-2 fw-bold text-white zoom-video-text text-center"
                  style={{ cursor: "pointer",marginTop:"15px" }}
                  onClick={handleShow}
                >
                  <Image src={play} alt="play" style={{ width: (isLargeScreen ? "15%" : "5%") }} />

                </h4>
            </div>
            </Col>
          </div>
      </Fragment>
      <Cinema
        show={show}
        setShow={handleClose}
        url={videoURLs.LARGO}
        data-testid="video-player-modal"
      />
    </div>
  );
};

export default TeSumas;
