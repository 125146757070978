/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useState, useLayoutEffect, useCallback, useRef, memo } from "react";
import { Image, Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "./NuestroProposito.style.css";
import arrow from "assets/images/img/arrow-right-g.png";
import ReactPlayer from "react-player";
import Cinema from "../Cinema/Cinema";
import {useIntl,FormattedMessage} from 'react-intl';
import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

export const VideoTypes = {
  CULTURA: 'cultura',
  FLEXIBILIDAD: 'flexibilidad',
  TALENTO: 'talento',
  BENEFICIOS: 'beneficios',
};

export const VideoFiles = {
  [VideoTypes.CULTURA]: 'aaron.mp4',
  [VideoTypes.FLEXIBILIDAD]: 'antonella.mp4',
  [VideoTypes.TALENTO]: 'tomas.mp4',
  [VideoTypes.BENEFICIOS]: 'felipe.mp4',
};

export const TestimonialVideos = {
  TESTIMONY1: 'antonella_mask.mp4',
  TESTIMONY2: 'tomas_mask.mp4',
  TESTIMONY3: 'aaron_mask.mp4',
  TESTIMONY4: 'felipe_mask.mp4',
}

const PropositosDesk = () => {
  const lang = localStorage.getItem('lang');
  const intl = useIntl();
  let imagenrueda;
  let imgflex;
  let imgtalento;
  let imgcultura;
  let imgbeneficios;

  if(intl.locale)
  {
      if(intl.locale === 'es-ES')
      {   
          imagenrueda = require("assets/images/falabella/rueda_propositos.png");
          imgflex = require("assets/images/falabella/testimonios/flex.png");
          imgtalento = require("assets/images/falabella/testimonios/talento.png");
          imgcultura = require("assets/images/falabella/testimonios/cultura.png");
          imgbeneficios = require("assets/images/falabella/testimonios/beneficios.png");

      } else if(intl.locale === 'en-US'){

          imagenrueda = require("assets/images/falabella/ingles/pilares/Rueda_ingles_1.png");
          imgflex = require("assets/images/falabella/ingles/pilares/Flexibilidad_texto.png");
          imgtalento = require("assets/images/falabella/ingles/pilares/Talento_texto.png");
          imgcultura = require("assets/images/falabella/ingles/pilares/Cultura_texto.png");
          imgbeneficios = require("assets/images/falabella/ingles/pilares/Beneficios_texto.png");
         

      } else {
          imagenrueda = require("assets/images/falabella/rueda_propositos.png");
          imgflex = require("assets/images/falabella/testimonios/flex.png");
          imgtalento = require("assets/images/falabella/testimonios/talento.png");
          imgcultura = require("assets/images/falabella/testimonios/cultura.png");
          imgbeneficios = require("assets/images/falabella/testimonios/beneficios.png");
          
      }

  }else{
      imagenrueda = require("assets/images/falabella/rueda_propositos.png");
      imgflex = require("assets/images/falabella/testimonios/flex.png");
      imgtalento = require("assets/images/falabella/testimonios/talento.png");
      imgcultura = require("assets/images/falabella/testimonios/cultura.png");
      imgbeneficios = require("assets/images/falabella/testimonios/beneficios.png");
      
  }

  const [video, setVideo] = useState("");
  const [videoURLs, setVideoURLs] = useState({});
  const [showCinema, setShowCinema] = useState(false);
  const mainSectionRef = useRef(null);
  const service = new OfertasLaboralesService();

  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };


  useEffect(() => { 
    const fetchVideos = async () => {
      const videoURLs = {};
      for (const videoType in TestimonialVideos) {
        const videoName = TestimonialVideos[videoType];
        const url = await getVideoURL(videoName);
        videoURLs[videoType] = url;
      }

      setVideoURLs(videoURLs);
    }
    fetchVideos();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoPreviewClick = useCallback(async(videoType) => {
    const videoName = VideoFiles[videoType];
    if (!videoName) {
      console.error(`Invalid video type: ${videoType}`);
      setVideo('');
      setShowCinema(false);
      return;
    }

    const url = await getVideoURL(videoName);
    setVideo(url);
    setShowCinema(true);

    // switch (video) {
    //   case "cultura":
    //     setVideo(require("assets/images/falabella/testimonios/aaron.mp4"));
    //     setShowCinema(true);
    //     break;
    //   case "flexibilidad":
    //     setVideo(require("assets/images/falabella/testimonios/antonella.mp4"));
    //     setShowCinema(true);
    //     break;
    //   case "talento":
    //     setVideo(require("assets/images/falabella/testimonios/tomas.mp4"));
    //     setShowCinema(true);
    //     break;
    //   case "beneficios":
    //     setVideo(require("assets/images/falabella/testimonios/felipe.mp4"));
    //     setShowCinema(true);
    //     break;
    //   default:
    //     setVideo("");
    //     setShowCinema(false);
    //     break;
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panels = useRef([]);
  const panelsContainer = useRef();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
  });

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    const totalPanels = panels.current.length;

    const animation = gsap.fromTo(
      panels.current,
      {
        yPercent: 15,
      },
      {
        xPercent: -83 * (totalPanels - 1),
        //yPercent: 10,
        ease: "none",
        scrollTrigger: {
          trigger: mainSectionRef.current,
          start: "top top",
          pin: mainSectionRef.current,
          scrub: true,
          endTrigger: panels.current[totalPanels],
          // base vertical scrolling on how wide the container is so it feels more natural.
          end: () => {
            return "+=" + (panelsContainer.current.offsetWidth + 5000);
          },
          invalidateOnRefresh: true,
        },
      }
    );

    return () => {
      animation.scrollTrigger.kill();
      animation.kill();
    };
  }, []);

  return (
    <div
      className="container-flex"
      style={{ height: "100%" }}
      id="proposito"
      name="proposito"
    >
      <section className="section-scroll horizontal" ref={panelsContainer}>
        <div className="pin-wrap" id="pinThis-0" ref={mainSectionRef}>
          <div className="animation-wrap to-right" id="animateThis-0">
            <div
              className="item-scroll-padding pt-9"
              ref={(e) => createPanelsRefs(e, 0)}
            ></div>
            <div className="item-scroll">
              <div
                className="align-items-center text-center h-100 "
                ref={(e) => createPanelsRefs(e, 1)}
              >
                <Row>
                  <Col md={12}>
                    <h4 className="mb-2 display-4 fw-bold classH4Size">
                      <Image src={arrow} style={{ width: "6%" }} /> <FormattedMessage id="app.sliderpilares.titulovive"/> <span style={{ color: "#92BE49" }}><FormattedMessage id="app.sliderpilares.tituloexperiencia"/></span>{" "}
                      <FormattedMessage id="app.sliderpilares.titulodescubre"/>
                    </h4>
                  </Col>
                  <Col md={12} className="pt-4 w-100">
                    <Image
                      style={{
                        height: "70vh",
                        width: "auto",
                      }}
                      src={imagenrueda}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="item-scroll">
              <div
                className="align-items-center text-center h-100 w-100"
                ref={(e) => createPanelsRefs(e, 2)}
              >
                <Row
                  style={{
                    // border: "1px solid #BC2D55",
                    // borderRadius: "2.5rem",
                    padding: "2rem",
                    minHeight: "97vh",
                  }}
                >
                  <Col md={6}>
                    <Row
                      className="text-center pt-6 ps-2 pointer"
                      onClick={() => handleVideoPreviewClick(VideoTypes.FLEXIBILIDAD)}
                    >
                      <ReactPlayer
                        url={videoURLs.TESTIMONY1}
                        width="100%"
                        height="auto"
                        controls={false}
                        playing
                        loop
                        muted
                      />
                    </Row>
                    <Row className="pt-6">
                      <Col xs="2">
                        <Image
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/1-1.png")}
                        />
                      </Col>
                      <Col xs="8">
                        <div
                          className="text-start fs-4"
                          style={{ color: "#97989A" }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.flexibilidad.antonella"/>
                          </div>
                          <div className="text-end fw-bolder fst-italic">
                            Antonella Camila Tilli
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" className="d-flex align-items-end">
                        <Image
                          className="align-bottom"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/cierre-comillas.png")}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="pb-3">
                      <Col md="12" className="pt-3">
                        <Image
                          style={{ height: "auto", width: "40%" }}
                          src={imgflex}
                        />
                      </Col>
                      <Col
                        md="12"
                        className="pt-3"
                        style={{ marginLeft: "5%" }}
                      >
                        <div
                          className="text-start fs-4"
                          style={{
                            color: "rgb(188, 45, 85)",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.flexibilidad.texto"/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2" style={{ verticalAlign: "middle" }}>
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/1-5.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4 align-middle">
                          <FormattedMessage id="app.sliderpilares.flexibilidad.1"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/1-4.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span className="fw-bold"><FormattedMessage id="app.sliderpilares.flexibilidad.2"/></span> <FormattedMessage id="app.sliderpilares.flexibilidad.2.1"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/1-2.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span className="fw-bold"><FormattedMessage id="app.sliderpilares.flexibilidad.3"/></span> <FormattedMessage id="app.sliderpilares.flexibilidad.3.1"/>
                        </div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col xs="3" className="text-center"></Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span
                            style={{
                              backgroundColor: "rgb(188, 45, 85)",
                              color: "white",
                              marginTop: "-12px",
                            }}
                            className="btn rounded-pill disabled"
                          >
                            <FormattedMessage id="app.sliderpilares.flexibilidad.ymuchomas"/>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="item-scroll">
              <div
                className="align-items-center text-center h-100 w-100"
                ref={(e) => createPanelsRefs(e, 3)}
              >
                <Row
                  style={{
                    // border: "1px solid #97989A",
                    // borderRadius: "2.5rem",
                    padding: "2rem",
                    minHeight: "97vh",
                  }}
                >
                  <Col md={6}>
                    <Row className="pb-3">
                      <Col md="12" className="pt-3">
                        <Image
                          style={{ height: "auto", width: "40%" }}
                          src={imgtalento}
                        />
                      </Col>
                      <Col md="12" className="pt-3">
                        <div
                          className="text-start fs-4"
                          style={{
                            color: "rgb(175, 171, 171)",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.talento.texto"/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2" style={{ verticalAlign: "middle" }}>
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center"
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/2-5.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4 align-middle">
                          <FormattedMessage id="app.sliderpilares.talento.1"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center"
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/2-1.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-sm-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.talento.2"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center "
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/2-2.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.talento.3"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="text-center"></Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span
                            style={{
                              backgroundColor: "rgb(175, 171, 171)",
                              color: "white",
                            }}
                            className="btn rounded-pill disabled"
                          >
                            <FormattedMessage id="app.sliderpilares.talento.ymuchomas"/>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row
                      className="text-center pt-6 ps-2 pointer"
                      onClick={() => handleVideoPreviewClick(VideoTypes.TALENTO)}
                    >
                      <ReactPlayer
                        url={videoURLs.TESTIMONY2}
                        width="100%"
                        height="auto"
                        controls={false}
                        loop
                        muted
                        playing
                      />
                    </Row>
                    <Row className="pt-6">
                      <Col xs="2">
                        <Image
                          className="text start fs-4"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/2-4.png")}
                        />
                      </Col>
                      <Col xs="8">
                        <div
                          className="text-start fs-4"
                          style={{ color: "#97989A" }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.talento.tomas"/>
                          </div>
                          <div className="text-end fw-bolder fst-italic">
                            Tomás Valdés
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" className="d-flex align-items-end">
                        <Image
                          className="align-bottom"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/cierre-comillas.png")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="item-scroll">
              <div
                className="align-items-center text-center h-100 w-100"
                ref={(e) => createPanelsRefs(e, 4)}
              >
                <Row
                  style={{
                    // border: "1px solid #3E54A1",
                    // borderRadius: "2.5rem",
                    padding: "2rem",
                    minHeight: "96vh",
                  }}
                >
                  <Col md={6}>
                    <Row
                      className="text-center pt-6 ps-2 pointer"
                      onClick={() => handleVideoPreviewClick(VideoTypes.CULTURA)}
                    >
                      <ReactPlayer
                        url={videoURLs.TESTIMONY3}
                        width="100%"
                        height="auto"
                        controls={false}
                        loop
                        muted
                        playing
                      />
                    </Row>
                    <Row className="pt-6">
                      <Col xs="2">
                        <Image
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/3-1.png")}
                        />
                      </Col>
                      <Col xs="8">
                        <div
                          className="text-start fs-4"
                          style={{ color: "#97989A" }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.cultura.aaron"/>
                          </div>
                          <div className="text-end fw-bolder fst-italic">
                            Aarón Rojas
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" className="d-flex align-items-end">
                        <Image
                          className="align-bottom"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/cierre-comillas.png")}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="pb-3">
                      <Col md="12" className="pt-3">
                        <Image
                          style={{ height: "auto", width: "40%" }}
                          src={imgcultura}
                        />
                      </Col>
                      <Col
                        md="12"
                        className="pt-3"
                        style={{ marginLeft: "5%" }}
                      >
                        <div
                          className="text-start fs-4"
                          style={{
                            color: "rgb(62, 84, 161)",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.cultura.texto"/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2" style={{ verticalAlign: "middle" }}>
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/3-4.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4 align-middle">
                          <FormattedMessage id="app.sliderpilares.cultura.1"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/3-2.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.cultura.2"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col xs="3" className="text-center">
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/3-5.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.cultura.3"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="text-center"></Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span
                            style={{
                              backgroundColor: "rgb(62, 84, 161)",
                              color: "white",
                            }}
                            className="btn rounded-pill disabled"
                          >
                            <FormattedMessage id="app.sliderpilares.cultura.ymuchomas"/>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="item-scroll-last">
              <div
                className="align-items-center text-center h-100"
                ref={(e) => createPanelsRefs(e, 5)}
              >
                <Row
                  style={{
                    // border: "1px solid #60B132",
                    // borderRadius: "2.5rem",
                    padding: "2rem",
                    minHeight: "97vh",
                  }}
                >
                  <Col md={6}>
                    <Row className="pb-3">
                      <Col md="12" className="pt-3">
                        <Image
                          style={{ height: "auto", width: "40%" }}
                          src={imgbeneficios}
                        />
                      </Col>
                      <Col md="12" className="pt-3">
                        <div
                          className="text-start fs-4"
                          style={{
                            color: "rgb(146, 190, 73)",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.beneficio.texto"/>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2" style={{ verticalAlign: "middle" }}>
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center"
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/4-6.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4 align-middle">
                          <FormattedMessage id="app.sliderpilares.beneficio.1"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center"
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/4-5.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-sm-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.beneficio.2"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center "
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/4-2.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.beneficio.3"/>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col
                        xs="3"
                        className="text-center d-flex align-items-center "
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "60%",
                          }}
                          src={require("assets/images/falabella/testimonios/4-7.png")}
                        />
                      </Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <FormattedMessage id="app.sliderpilares.beneficio.4"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" className="text-center"></Col>
                      <Col xs="9" className="d-flex align-items-center">
                        <div className="text-start fs-4">
                          <span
                            style={{
                              backgroundColor: "rgb(146, 190, 73)",
                              color: "white",
                            }}
                            className="btn rounded-pill disabled"
                          >
                            <FormattedMessage id="app.sliderpilares.beneficio.ymuchomas"/>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row
                      className="text-center pt-6 ps-2 pointer"
                      onClick={() => handleVideoPreviewClick(VideoTypes.BENEFICIOS)}
                    >
                      <ReactPlayer
                        url={ videoURLs.TESTIMONY4 }
                        width="100%"
                        height="auto"
                        controls={false}
                        loop
                        muted
                        playing
                      />
                    </Row>
                    <Row className="pt-6">
                      <Col xs="2">
                        <Image
                          className="text start fs-4"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/4-1.png")}
                        />
                      </Col>
                      <Col xs="8">
                        <div
                          className="text-start fs-4"
                          style={{ color: "#97989A" }}
                        >
                          <div>
                            <FormattedMessage id="app.sliderpilares.beneficio.felipe"/>
                          </div>
                          <div className="text-end fw-bolder fst-italic">
                            Felipe Avendaño
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" className="d-flex align-items-end">
                        <Image
                          className="align-bottom"
                          style={{ height: "auto", width: "100%" }}
                          src={require("assets/images/falabella/testimonios/cierre-comillas.png")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cinema url={video} show={showCinema} setShow={setShowCinema} />
    </div>
  );
};

export default PropositosDesk;
