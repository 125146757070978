const falabella1 = require("assets/images/falabella/internos/carousel/FalabellaRetail/1.jpg");
const falabella2 = require("assets/images/falabella/internos/carousel/FalabellaRetail/2.JPG");
const falabella3 = require("assets/images/falabella/internos/carousel/FalabellaRetail/3.JPG");
const falabella4 = require("assets/images/falabella/internos/carousel/FalabellaRetail/4.JPG");
const falabella5 = require("assets/images/falabella/internos/carousel/FalabellaRetail/5.JPG");
const falabella6 = require("assets/images/falabella/internos/carousel/FalabellaRetail/6.png");
const falabella7 = require("assets/images/falabella/internos/carousel/FalabellaRetail/7.png");
const falabella8 = require("assets/images/falabella/internos/carousel/FalabellaRetail/8.jpg");
const falabella9 = require("assets/images/falabella/internos/carousel/FalabellaRetail/9.jpg");
const falabella10 = require("assets/images/falabella/internos/carousel/FalabellaRetail/10.png");
const falabella11 = require("assets/images/falabella/internos/carousel/FalabellaRetail/11.png");
const falabella12 = require("assets/images/falabella/internos/carousel/FalabellaRetail/12.JPG");

export const GrandesEspaciosDataFalabellaRetail = 
[
  [
    {
      id: 1,
      image: falabella1,
      position: "h-20rem",
      image2: falabella2,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella4,
      position: "h-16rem",
      image2: falabella3,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella5,
      position: "h-20rem",
      image2: falabella6,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella7,
      position: "h-20rem",
      image2: falabella8,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella9,
      position: "h-16rem",
      image2: falabella10,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella11,
      position: "h-20rem",
      image2: falabella12,
      position2: "h-18rem",
    },
  ]
];



export default GrandesEspaciosDataFalabellaRetail;
