import { Fragment, useEffect } from "react";
import LandingJobsJovenes from "../../components/falabella/jobs/LandingJobsJovenes";
import "../../assets/css/main.css";
import WOW from "wowjs";

const FalabellaJovenesIndex = (props) => {
  
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Fragment>
      <LandingJobsJovenes />
    </Fragment>
  );
};

export default FalabellaJovenesIndex;
