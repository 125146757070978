/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Row, Carousel } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "./CarouselPresencia.style.css";
import {useIntl} from 'react-intl';

const itemsinglesSodimac = [
  {
    srcWeb: require("assets/images/falabella/internos/sodimac/Banner_Sodimac.png"),
    srcMobile: require("assets/images/falabella/internos/sodimac/Banner_sodimac_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSodimac"
  }
];

const itemsSodimac = [
  {
    srcWeb: require("assets/images/falabella/internos/sodimac/Sodimac_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/sodimac/Sodimac_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSodimac"
  }
];

const itemsinglesTottus = [
  {
    srcWeb: require("assets/images/falabella/internos/tottus/Banner_Tottus.png"),
    srcMobile: require("assets/images/falabella/internos/tottus/Tottus_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorTottus"
  }
];

const itemsTottus = [
  {
    srcWeb: require("assets/images/falabella/internos/tottus/Tottus_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/tottus/Tottus_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorTottus"
  }
];



const itemsinglesFalabellaretail = [
  {
    srcWeb: require("assets/images/falabella/internos/retail/Retail.png"),
    srcMobile: require("assets/images/falabella/internos/retail/Retail_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellacom"
  }
];

const itemsFalabellaretail = [
  {
    srcWeb: require("assets/images/falabella/internos/retail/Retail_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/retail/Retail_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellacom"
  }
];

const itemsinglesMallplaza = [
  {
    srcWeb: require("assets/images/falabella/internos/mallplaza/Banner_mallplaza.png"),
    srcMobile: require("assets/images/falabella/internos/mallplaza/Mallplaza_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorMallplaza"
  }
];

const itemsMallplaza = [
  {
    srcWeb: require("assets/images/falabella/internos/mallplaza/Mallplaza_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/mallplaza/Mallplaza_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorMallplaza"
  }
];

const itemsinglesBancoFalabella = [
  {
    srcWeb: require("assets/images/falabella/internos/bancofalabella/Banner_Banco.png"),
    srcMobile: require("assets/images/falabella/internos/bancofalabella/Banco_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorBancoFalabella"
  }
];

const itemsBancoFalabella = [
  {
    srcWeb: require("assets/images/falabella/internos/bancofalabella/Banco_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/bancofalabella/Banco_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorBancoFalabella"
  }
];

const itemsFalabellaInmobiliario = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellainmobiliario/Inmobiliario_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/falabellainmobiliario/Inmobiliario_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellaInmobiliario"
  }
];

const itemsinglesFalabellaInmobiliario = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellainmobiliario/banner_Inmobiliario.png"),
    srcMobile: require("assets/images/falabella/internos/falabellainmobiliario/Inmobiliario_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellaInmobiliario"
  }
];
const itemsinglesFalabellaFinanciero = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellafinanciero/Financiero.png"),
    srcMobile: require("assets/images/falabella/internos/falabellafinanciero/Financiero_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellaFinanciero"
  }
];
const itemsFalabellaFinanciero = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellafinanciero/Financiero_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/falabellafinanciero/Financiero_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellaFinanciero"
  }
];
const itemsFalabellaSoriana = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellasoriana/Soriana_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/falabellasoriana/Soriana_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorFalabellaSoriana"
  }
];
const itemsinglesFalabellaSoriana = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellasoriana/Soriana.png"),
    srcMobile: require("assets/images/falabella/internos/falabellasoriana/Soriana_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:""
  }
];
const itemsSegurosFalabella = [
  {
    srcWeb: require("assets/images/falabella/internos/segurosfalabella/Seguros_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/segurosfalabella/Seguros_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSegurosFalabella"
  }
];
const itemsinglesSegurosFalabella = [
  {
    srcWeb: require("assets/images/falabella/internos/segurosfalabella/Seguros.png"),
    srcMobile: require("assets/images/falabella/internos/segurosfalabella/Seguros_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSegurosFalabella"
  }
];
const itemsFalabellaCorp = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellacorp/Falabella_ingles.png"),
    srcMobile: require("assets/images/falabella/internos/falabellacorp/Falabella_movil_ingles.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSegurosFalabella"
  }
];
const itemsinglesFalabellaCorp = [
  {
    srcWeb: require("assets/images/falabella/internos/falabellacorp/Falabella.png"),
    srcMobile: require("assets/images/falabella/internos/falabellacorp/Falabella_movil.png"),
    text1:"",
    text2:"",
    text3:"",
    text4:"",
    classtext:"textInternoColorSegurosFalabella"
  }
];
const CarouselPresencia = (param) => {
  const intl = useIntl()
  let sliderPorDefecto;
  let formato = param.format;
  if(intl.locale)
  {
      if(intl.locale === 'es-ES')
      {   
          if(formato === "Sodimac"){
            sliderPorDefecto = itemsinglesSodimac;
          }
          if(formato === "Tottus"){
            sliderPorDefecto = itemsinglesTottus;
          }
          if(formato === "Falabella Retail"){
            sliderPorDefecto = itemsinglesFalabellaretail;
          }
          if(formato === "Mallplaza"){
            sliderPorDefecto = itemsinglesMallplaza;
          }
          if(formato === "Banco Falabella"){
            sliderPorDefecto = itemsinglesBancoFalabella;
          }
          if(formato === "Falabella Inmobiliario"){
            sliderPorDefecto = itemsinglesFalabellaInmobiliario;
          }
          if(formato === "Falabella Financiero"){
            sliderPorDefecto = itemsinglesFalabellaFinanciero;
          }
          if(formato === "Falabella Soriana"){
            sliderPorDefecto = itemsinglesFalabellaSoriana;
          }
          if(formato === "Seguros Falabella"){
            sliderPorDefecto = itemsinglesSegurosFalabella;
          }
          if(formato === "Falabella Equipo Corporativo"){
            sliderPorDefecto = itemsinglesFalabellaCorp;
          }
      } else if(intl.locale === 'en-US'){

          if(formato === "Sodimac"){
            sliderPorDefecto = itemsSodimac;
          }
          if(formato === "Tottus"){
            sliderPorDefecto = itemsTottus;
          }
          if(formato === "Falabella Retail"){
            sliderPorDefecto = itemsFalabellaretail;
          }
          if(formato === "Banco Falabella"){
            sliderPorDefecto = itemsBancoFalabella;
          }
          if(formato === "Mallplaza"){
            sliderPorDefecto = itemsMallplaza;
          }
          if(formato === "Falabella Inmobiliario"){
            sliderPorDefecto = itemsFalabellaInmobiliario;
          }
          if(formato === "Falabella Financiero"){
            sliderPorDefecto = itemsFalabellaFinanciero;
          }
          if(formato === "Falabella Soriana"){
            sliderPorDefecto = itemsFalabellaSoriana;
          }
          if(formato === "Seguros Falabella"){
            sliderPorDefecto = itemsSegurosFalabella;
          }
          if(formato === "Falabella Equipo Corporativo"){
            sliderPorDefecto = itemsFalabellaCorp;
          }
      } else {
        if(formato === "Sodimac"){
          sliderPorDefecto = itemsSodimac;
        }
        if(formato === "Tottus"){
          sliderPorDefecto = itemsTottus;
        }
        if(formato === "Falabella Retail"){
          sliderPorDefecto = itemsFalabellaretail;
        }
        if(formato === "Banco Falabella"){
          sliderPorDefecto = itemsBancoFalabella;
        }
        if(formato === "Mallplaza"){
          sliderPorDefecto = itemsMallplaza;
        }
        if(formato === "Falabella Inmobiliario"){
          sliderPorDefecto = itemsFalabellaInmobiliario;
        }
        if(formato === "Falabella Financiero"){
          sliderPorDefecto = itemsFalabellaFinanciero;
        }
        if(formato === "Falabella Soriana"){
          sliderPorDefecto = itemsFalabellaSoriana;
        }
        if(formato === "Seguros Falabella"){
          sliderPorDefecto = itemsSegurosFalabella;
        }
        if(formato === "Falabella Equipo Corporativo"){
          sliderPorDefecto = itemsFalabellaCorp;
        }
      }

  }else{
    if(formato === "Sodimac"){
      sliderPorDefecto = itemsSodimac;
    }
    if(formato === "Tottus"){
      sliderPorDefecto = itemsTottus;
    }
    if(formato === "Falabella Retail"){
      sliderPorDefecto = itemsFalabellaretail;
    }
    if(formato === "Mallplaza"){
      sliderPorDefecto = itemsMallplaza;
    }
    if(formato === "Banco Falabella"){
      sliderPorDefecto = itemsBancoFalabella;
    }
    if(formato === "Falabella Inmobiliario"){
      sliderPorDefecto = itemsFalabellaInmobiliario;
    }
    if(formato === "Falabella Financiero"){
      sliderPorDefecto = itemsFalabellaFinanciero;
    }
    if(formato === "Falabella Soriana"){
      sliderPorDefecto = itemsFalabellaSoriana;
    }
    if(formato === "Seguros Falabella"){
      sliderPorDefecto = itemsSegurosFalabella;
    }
    if(formato === "Falabella Equipo Corporativo"){
      sliderPorDefecto = itemsFalabellaCorp;
    }
  }
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const isMobileDevice = useMediaQuery({ minWidth: 780 });
  let bgsize = "center center";
    if (isLargeScreen) {
      bgsize = "top";
    }
  
  const [index, setIndex] = useState(0);
  const [carouselItemData, setCarouselItemData] = useState();
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    let nodos = sliderPorDefecto.map((item) => {
      let classNameImage = "w-100";
      if (isLargeScreen) {
        classNameImage = "w-100";
      }
      
     
      return (
        
        <Carousel.Item>
          <Row className="">
          <div style={{ backgroundImage: `url(${ isLargeScreen ? item.srcWeb : item.srcMobile})`,backgroundSize:'cover',backgroundPosition:{bgsize},backgroundRepeat:'no-repeat',height: isLargeScreen ? '110vh' : '90vh'}}>
              {isLargeScreen ? (
                <div className="carouselInternoTexto">
                  {
                     formato ===  "Falabella Inmobiliario" || formato === "Falabella Financiero" || formato === "Falabella.com" || formato === "Seguros Falabella"|| formato === "Falabella Soriana"? 
                    (<div><h1 className={item.classtext}>{item.text1}</h1>
                    <h1 className="text-white textInternoSmall">{item.text2}</h1>
                    <h1 className="text-white textInternoSmall">{item.text3}</h1>
                    <h1 className="text-white textInternoSmall">{item.text4}</h1></div>) : 
                    (<div><h1 className={item.classtext}>{item.text1}</h1>
                    <h1 className="text-white textInterno">{item.text2}</h1>
                    <h1 className="text-white textInterno">{item.text3}</h1>
                    <h1 className="text-white textInterno">{item.text4}</h1></div>)
                  }
                  
                </div>
              ) : (
                <div>
                  {isMobileDevice ? (
                    <div className="carouselInternoTextomobile">
                      <h4 className={item.classtext}>{item.text1}</h4>
                      <h4 className="text-white textInterno">{item.text2}</h4>
                      <h4 className="text-white textInterno">{item.text3}</h4>
                      <h4 className="text-white textInterno">{item.text4}</h4>
                    </div>
                    ):(
                      <div className="carouselInternoTextomobileMobile">
                        <h4 className={item.classtext}>{item.text1}</h4>
                        <h4 className="text-white textInterno">{item.text2}</h4>
                        <h4 className="text-white textInterno">{item.text3}</h4>
                        <h4 className="text-white textInterno">{item.text4}</h4>
                      </div>
                  )}
                </div>
              )}
          </div>
          </Row>
        </Carousel.Item>
      );
    });
    setCarouselItemData(nodos);
  }, [isLargeScreen,sliderPorDefecto,bgsize,formato,isMobileDevice]);

  return (
    <div id="presencia" name="presencia">
      <div style={{ display: "block", width: "auto" }}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          controls={false}
          pause="false"
        >
          {carouselItemData}
        </Carousel>
      </div>
    </div>
  );
};
export default CarouselPresencia;
