/* eslint-disable no-unused-vars */
import { FilterContext } from "hooks/FilterContext";
import { useContext } from "react";
import { Image, Col, Row } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";

const settings = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: true,
  dots: false,
  autoplay: true,
  variableWidth: true,
};

const items = [
  {
    id: "1",
    srcWeb: require("assets/images/img/logos/corporativo.png"),
    redirect: "/falabellacorp",
    to: "Falabella Equipo Corporativo",
  },
  {
    id: "2",
    srcWeb: require("assets/images/img/logos/falabella-retail.png"),
    redirect: "/falabellaretail",
    to: "Falabella Retail",
  },
  {
    id: "3",
    srcWeb: require("assets/images/img/logos/sodimac.png"),
    redirect: "/sodimac",
    to: "Sodimac",
  },
  {
    id: "4",
    srcWeb: require("assets/images/img/logos/tottus.png"),
    redirect: "/tottus",
    to: "Tottus",
  },
  {
    id: "5",
    srcWeb: require("assets/images/img/logos/banco.png"),
    redirect: "/bancofalabella",
    to: "Banco Falabella",
  },
  {
    id: "6",
    srcWeb: require("assets/images/img/logos/mallplaza.png"),
    redirect: "/mallplaza",
    to: "Mallplaza",
  },
  {
    id: "7",
    srcWeb: require("assets/images/img/logos/falainmobiliario.png"),
    redirect: "/falabellainmobiliario",
    to: "Falabella Inmobiliario",
  },
  {
    id: "10",
    srcWeb: require("assets/images/img/logos/fcom.png"),
    redirect: "/falabellaretail",
    to: "Falabella Retail",
  },
  {
    id: "11",
    srcWeb: require("assets/images/img/logos/falabellafinanciero.png"),
    redirect: "/falabellafinanciero",
    to: "Falabella Financiero",
  },
  {
    id: "12",
    srcWeb: require("assets/images/img/logos/falabellasorianas.png"),
    redirect: "/falabellasoriana",
    to: "Falabella Soriana",
  },
  {
    id: "13",
    srcWeb: require("assets/images/img/logos/segurosfalabella.png"),
    redirect: "/segurosfalabella",
    to: "Seguros Falabella",
  },
];

const FooterBrands = () => {

  let slides = [];

  items.forEach((item, index) => {
    slides.push(
      <div
        className="px-md-1 wow zoomIn"
        data-wow-duration="0.1s"
        data-wow-delay={`${index * 0.1}s`}
        data-wow-offset="5"
        key={item.offer_id}
      >
         <Link to={item.redirect}>
            <Image
              src={item.srcWeb}
            />
          </Link>
      </div>
    );
    slides.push(
      <div className="pt-7">
        <div className="dot"></div>
      </div>
    );
  });

  return (
    <Row>
      <Col lg="1"></Col>
      <Col lg="10" className="text-center">
        <Slider {...settings} className="pb-sm-6 mb-6 p-6">
          {slides.length > 0 && slides}
        </Slider>
      </Col>
      <Col lg="1"></Col>
    </Row>
  );
};
export default FooterBrands;
