import { Col, Row, Image, Button, Modal, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { FormattedMessage } from 'react-intl';
import "./Aventura.style.css";
import ReactPlayer from "react-player";
import play from "assets/images/falabella/DESKTOP FALABELLA VB4-39.png";
import Cinema from "../Cinema/Cinema";
import { useIntl } from 'react-intl';

import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'

const Aventura = () => {
  const [show, setShow] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const service = new OfertasLaboralesService(); 

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const intl = useIntl();
  const textoModal = intl.formatMessage({ id: "app.tech.aventura.modal" });

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = () => setShowModal(true);
  const isLargeScreen = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    const fileName = isLargeScreen ? 'phone_aventura.mp4' : 'large_aventura.mp4';
    service.getVideo(fileName).then(response => {
      const url = URL.createObjectURL(response.data);
      setVideoURL(url);
    }).catch(error => console.error('Error fetching video:', error));
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isLargeScreen]);


  return (
    <div className={`py-8 ${isLargeScreen ? "bg-aventura-mobile" : "bg-aventura"} w-100`} name="aventura" id="aventura">
      <div className="container">
        <Row>
          <Col lg={12} md={12} sm={12} className="pb-5 pt-5 px-8">
            <div className={`${isLargeScreen ? "" : "pt-4"}`}>
              <h3 className="text-white classH3Size">
                <FormattedMessage id="app.tech.aventura.title"/> <span style={{ color: "yellow" }}>{"( )"}</span>
              </h3>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} style={{position: 'relative'}}>
            <ReactPlayer
              url={videoURL}
              style={{
                position: !isLargeScreen ? 'absolute' : '',
                top: '9%',
                left: '0%',
                zIndex: 1
              }}
              width={!isLargeScreen ? '90%' : '100%'}
              height="auto"
              controls={false}
              loop
              muted
              playing
              data-testid="cinema-component"
            />
            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 2,cursor: "pointer" }} onClick={handleShow}>
              <Image
                src={play}
                alt="play"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                  width: '50px',
                  height: '50px',
                  zIndex: 4,
                  marginTop:!isLargeScreen ? '22%' : '14%',
                  marginLeft:!isLargeScreen ? '40%' : '43%'
                }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="p-4 ">
            <span className="text-white" style={{ fontSize: "17px" }}>
              <span style={{ color: "#00D1FF" }}><b>{"<p>"}</b></span> <FormattedMessage id="app.tech.aventura"/>
              <span style={{ color: "#00D1FF" }}><b>{"</p>"}</b></span>
            </span>
            <br></br><br></br>
            <span className="text-white" style={{ fontSize: "17px" }}>
              <span style={{ color: "#00D1FF" }}><b>{"<p>"}</b></span> <FormattedMessage id="app.tech.aventura1"/>
              <span style={{ color: "#00D1FF" }}><b>{"</p>"}</b></span>
            </span>
            <div style={{ textAlign: 'center' }}>
              <span className="btn rounded-pill mx-2 btn btn-tech-cargo" onClick={() => handleShowModal()}>
                <b>
                  Ver más
                </b>
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <Cinema
        show={show}
        setShow={handleClose}
        url={videoURL}
        data-testid="video-player-modal"
      />

      <Modal id="mi-modal" size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <Card className="profile-card">
            <Card.Body className="">
              <Row className="mb-5 justify-content-center">
                <Col lg={12} md={12} sm={12} className="text-white p-3">
                  <div dangerouslySetInnerHTML={{ __html: textoModal }}></div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div style={{ textAlign: 'center' }}>
                <Button className="btn rounded-pill btn-tech btn btn-primary" onClick={handleCloseModal}>
                  Cerrar
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Aventura;
