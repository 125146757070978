import React, { useState } from 'react';
import Slider from "react-slick";
import imageRounded1 from 'assets/images/falabella/jovenes/shutterstock_610931219.jpg';
import imageRounded2 from 'assets/images/falabella/jovenes/shutterstock_1674180016.jpg';
import imageRounded3 from 'assets/images/falabella/jovenes/shutterstock_2129787884.jpg';
import {Col,Row,Card,Image } from "react-bootstrap";
import "./Sabiasque.style.css";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';
import { Modal, Button} from 'react-bootstrap';
import arrow from "assets/images/falabella/jovenes/arrowfala.png";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
        data-testid="next-arrow"
      className={
        "arrowDerechaViveExperienciaCargo slick-next-ViveExperienciaCargo"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaCargo slick-prev-ViveExperienciaCargo"}
      onClick={onClick}
    ></div>
  );
}

const Sabiasque = (param) => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  const arrowStyle = !isLargeScreen ? { width: "17%" } : { width: "25%" };
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', descripcion: '',image:'',desc1:'',desc2:'',desc3:'',desc4:'', url:'',color:'' });
  const handleClose = () => setShow(false);

  const handleShow = (title, descripcion,image,desc1,desc2,desc3,desc4,url,color) => {
    setModalInfo({ title, descripcion,image,desc1,desc2,desc3,desc4,url,color});
    setShow(true);
  }
  const ProtaData = [
	{
		id: 1,
		image: imageRounded1,
        color:"#2843F4",
        clase: "",
        title: <FormattedMessage id="app.sabiasque.jovenes.title.1"/>,
        descripcion: <FormattedMessage id="app.sabiasque.jovenes.desc.1"/>,
        descripcionlarga1: <FormattedMessage id="app.sabiasque.jovenes.desclargo.1.1"/>,
        descripcionlarga2: <FormattedMessage id="app.sabiasque.jovenes.desclargo.1.2"/>,
        descripcionlarga3: <FormattedMessage id="app.sabiasque.jovenes.desclargo.1.3"/>,
        descripcionlarga4: <FormattedMessage id="app.sabiasque.jovenes.desclargo.1.4"/>,
        url:"https://falabella.airavirtual.com/offer_info/B673kVNCinevRIVb5luq?fbrefresh=STPm1B6TRvQvIzI8&id=1678396020"
		
	},
	{
		id: 2,
		image: imageRounded2,
        color:"#9BBE0E",
        clase: "",
        title: <FormattedMessage id="app.sabiasque.jovenes.title.2"/>,
        descripcion: <FormattedMessage id="app.sabiasque.jovenes.desc.2"/>,
        descripcionlarga1: <FormattedMessage id="app.sabiasque.jovenes.desclargo.2.1"/>,
        descripcionlarga2: <FormattedMessage id="app.sabiasque.jovenes.desclargo.2.2"/>,
        descripcionlarga3: <FormattedMessage id="app.sabiasque.jovenes.desclargo.2.3"/>,
        descripcionlarga4: <FormattedMessage id="app.sabiasque.jovenes.desclargo.2.4"/>,
        url:"https://www.linkedin.com/posts/saci-falabella_tech-falabellatransforma-somosfalabella-activity-7046582796370259968-hcpA?utm_source=share&utm_medium=member_desktop "
        },
	{
		id: 3,
		image: imageRounded3,
        color:"#CD0B51",
        clase: "",
        title: <FormattedMessage id="app.sabiasque.jovenes.title.3"/>,
        descripcion: <FormattedMessage id="app.sabiasque.jovenes.desc.3"/>,
        descripcionlarga1: <FormattedMessage id="app.sabiasque.jovenes.desclargo.3.1"/>,
        descripcionlarga2: <FormattedMessage id="app.sabiasque.jovenes.desclargo.3.2"/>,
        descripcionlarga3: <FormattedMessage id="app.sabiasque.jovenes.desclargo.3.3"/>,
        descripcionlarga4: <FormattedMessage id="app.sabiasque.jovenes.desclargo.3.4"/>,
        url:"#"
  }
    ];
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-8 bg-sabiasquejovenes" id="sabiasjovenes" name="sabiasjovenes">
        <div className="container">
        <div className="mb-10 pt-5 justify-content-center">
            <center>
            <Col lg={12} md={12} sm={12} className="text-center">
                <h4
                className={`mb-2 display-4 fw-bold ${
                isLargeScreen ? "classH3Size" : ""
                }`}
                >
                
                    <span style={{ color: "#CD0B51" }}>
                        {' '}<FormattedMessage id="app.jovenes.sabiasque.titulo"/><span style={{ color: "#CD0B51" }}></span>{' '}
                    </span>
                
                </h4>
            </Col>
            </center>
        </div>
        <Row className="justify-content-center">
            <Col lg={4} md={4} sm={12} className="text-start p-2">
            <div className="sizeSabiasque">
                <span style={{ color: "#CD0B51" }}><Image src={arrow} style={arrowStyle} /> </span>
            </div>
            <div >
                <br></br>
                <span className="sizeSabiasque"><FormattedMessage id="app.jovenes.sabiasque.descripcion"/></span>
            </div>
            </Col>
            <Col lg={8} md={8} sm={12} className="text-start p-2">
                <div data-testid="slider">
                    <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper" >
                        {ProtaData.length > 0 &&
                        ProtaData.map((item, index) => (
                            <div
                            className="px-md-1 wow zoomIn"
                            data-wow-duration="1s"
                            data-wow-delay={`${index * 0.2}s`}
                            data-wow-offset="5"
                            key={index}
                            >
                                <div>
                                    <Card className={`border border-card-sabiasque-jovenes`}>
                                        <center>
                                        <Card.Img className="p-2" variant="top" src={item.image} style={{ width:"300px", height:"auto",borderRadius: "2.5rem" }} />
                                        </center>
                                        <Card.Body className="">
                                            <Row className="justify-content-center">
                                                <Col lg={12} md={12} sm={12} className="">
                                                    <span className="h4 mb-1">
                                                        <b>
                                                            <span style={{ color: item.color }}>{item.title}</span>
                                                        </b>
                                                    </span>
                                                    <br></br>
                                                    <br></br>
                                                    <span className="h4 ">
                                                        <span>{item.descripcion}</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div style={{ textAlign: 'center' }}>
                                              <span className="btn rounded-pill mx-2 text-white" style={{ background: item.color }} key={item.title} onClick={() => handleShow(item.title, item.descripcion,item.image,item.descripcionlarga1,item.descripcionlarga2,item.descripcionlarga3,item.descripcionlarga4,item.url)}>
                                                  <b>
                                                  Ver más {'->'}
                                                  </b>
                                              </span>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Col>
        </Row>
        <Modal id="mi-modal-sabias-jovenes" size="lg" show={show} onHide={handleClose}>
          <Modal.Body>
            <Card className={`border border-card-sabiasque-modal-jovenes`}>
                <center>
                <Card.Img className="p-2" variant="top" src={modalInfo.image} style={{ width:"300px", height:"auto",borderRadius: "2.5rem" }} />
                </center>
                <Card.Body className="">
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} className="">
                            <span className="h4 mb-1">
                                <b>
                                    <span style={{ color: modalInfo.color }}>{modalInfo.title}</span>
                                </b>
                            </span>
                            <br></br>
                            <br></br>
                            <span className="h4 ">
                                <span>{modalInfo.desc1}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span>{modalInfo.desc2}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span>{modalInfo.desc3}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span>{modalInfo.desc4}</span>
                            </span>
                            {modalInfo.url !== "#" && 
                              <div className='mt-3'>
                                  <a
                                  rel='noopener noreferrer'
                                  href={`${modalInfo.url}`}
                                  target="_blank"
                                >
                                  <Button variant="secondary" className="rounded-pill">
                                    <FormattedMessage id="app.sabiasque.ir"/>
                                  </Button>
                                </a>
                              </div>
                            }
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div style={{ textAlign: 'center' }}>
                      <span className="btn rounded-pill mx-2  btn-tech btn btn-primary" onClick={handleClose}>
                          <b>
                          Cerrar
                          </b>
                      </span>
                    </div>
                </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
        </div>
    </div>
  );
};

export default Sabiasque;
