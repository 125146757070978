import { memo } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

const Cinema = ({ url, show, setShow }) => {
  return (
    <div>
      <Modal
        show={show}
        fullscreen={true}
        size="xl"
        onHide={() => setShow(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body>
          <Modal.Header
            style={{ height: "2%", borderBottom: "none" }}
            closeButton
            closeVariant="white"
          >
            {" "}
          </Modal.Header>
          <ReactPlayer
            url={url}
            width="100%"
            height="97%"
            controls={true}
  
            data-testid="video-player-modal"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(Cinema);
