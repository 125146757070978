import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

const Head = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  return (
    <Row className="mb-10 justify-content-center">
      <Col lg={12} md={12} sm={12} className="text-start">
        <h3
          className={`mb-2 display-4 fw-bold text-white  ${
            isLargeScreen ? "" : "classH3Size"
          }`}
        >
          <FormattedMessage id="app.tech.cargo.parrafo0"/>{" "}
          <br />
          <FormattedMessage id="app.tech.cargo.parrafo1"/><span style={{ color: "#00D1FF" }}> <FormattedMessage id="app.tech.cargo.parrafo2"/></span>
        </h3>
      </Col>
    </Row>
  );
};
export default Head;
