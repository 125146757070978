/* eslint-disable no-unused-vars */
import { Carousel, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { logos_color } from "data/landing/logo-color-list";

const BrandCarouselColor = (props) => {

  return (
    <Carousel indicators={false} {...props}>
      {logos_color.map((item) => (
        <Carousel.Item className="text-center">
          <Link to={item.url}>
            <Image
              src={item.srcWeb}
              style={{ height: props.height, width: "auto" }}
            />
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

// Specifies the default values for props
BrandCarouselColor.defaultProps = {
  to: "#",
  controls: false,
};

// Typechecking With PropTypes
BrandCarouselColor.propTypes = {
  height: PropTypes.string,
  nextIcon: PropTypes.node,
  prevIcon: PropTypes.node,
  to: PropTypes.string,
  controls: PropTypes.bool,
};

export default BrandCarouselColor;
