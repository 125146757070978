export const logos_white = [
  {
    id: "1",
    srcWeb: require("assets/images/falabella/f.png"),
    url:"/falabellaretail"
  },
  {
    id: "2",
    srcWeb: require("assets/images/falabella/sodimac.png"),
    url:"/sodimac"
  },
  {
    id: "3",
    srcWeb: require("assets/images/falabella/tottus.png"),
    url:"/tottus"
  },
  {
    id: "4",
    srcWeb: require("assets/images/falabella/mallplaza.png"),
    url:"/mallplaza"
  },
  {
    id: "5",
    srcWeb: require("assets/images/falabella/banco-falabella.png"),
    url:"/bancofalabella"
  }
  
];
/* 
export const logos_translate = [
  {
    key: "Tottus Chile",
    logo: require("assets/images/falabella/tottus.png"),
  },
  {
    key: "Seguros Falabella",
    logo: require("assets/images/falabella/seguros-falabella.png"),
  },
  {
    key: "Falabella Financiero",
    logo: require("assets/images/falabella/falabella-financiero.png"),
  },
  {
    key: "Banco Falabella",
    logo: require("assets/images/falabella/banco-falabella.png"),
  },
  {
    key: "Falabella Retail Chile",
    logo: require("assets/images/falabella/falabella-retail.png"),
  },
  {
    key: "IKEA Chile, Colombia y Perú",
    logo: require("assets/images/falabella/ikea.png"),
  },
  {
    key: "Sodimac México",
    logo: require("assets/images/falabella/sodimac.png"),
  },
  {
    key: "Sodimac Argentina",
    logo: require("assets/images/falabella/sodimac.png"),
  },
  {
    key: "Falabella Equipo Corporativo",
    logo: require("assets/images/falabella/falabella.png"),
  },
  {
    key: "Tottus Perú",
    logo: require("assets/images/falabella/tottus.png"),
  },
  {
    key: "Banco Falabella Colombia",
    logo: require("assets/images/falabella/banco-falabella.png"),
  },
  {
    key: "Falabella Retail Colombia",
    logo: require("assets/images/falabella/falabella-retail.png"),
  },
  {
    key: "Falabella Tecnología Corporativo",
    logo: require("assets/images/falabella/falabella.png"),
  },
  {
    key: "Linio Colombia",
    logo: require("assets/images/falabella/linio.png"),
  },
  {
    key: "Falabella Inmobiliario Perú",
    logo: require("assets/images/falabella/falabella-inmobiliario.png"),
  },
  {
    key: "Mall Plaza Chile",
    logo: require("assets/images/falabella/mallplaza.png"),
  },
  {
    key: "Seguros Falabella Perú",
    logo: require("assets/images/falabella/seguros-falabella.png"),
  },
  {
    key: "Linio Perú",
    logo: require("assets/images/falabella/linio.png"),
  },
  {
    key: "Falabella Inmobiliario",
    logo: require("assets/images/falabella/falabella-inmobiliario.png"),
  },
  {
    key: "CMR México",
    logo: require("assets/images/falabella/cmr-mexico.png"),
  },
  {
    key: "Falabella Equipo Corporativo Perú",
    logo: require("assets/images/falabella/falabella.png"),
  },
  {
    key: "Banco Falabella Perú",
    logo: require("assets/images/falabella/banco-falabella.png"),
  },
  {
    key: "Seguros Falabella Colombia",
    logo: require("assets/images/falabella/seguros-falabella.png"),
  },
  {
    key: "Sodimac Perú",
    logo: require("assets/images/falabella/sodimac.png"),
  },
  {
    key: "Sodimac Chile",
    logo: require("assets/images/falabella/sodimac.png"),
  },
  {
    key: "Logística Chile",
    logo: require("assets/images/falabella/falabella-retail.png"),
  },
  {
    key: "Falabella Retail Perú",
    logo: require("assets/images/falabella/falabella-retail.png"),
  },
  {
    key: "Mall Plaza Colombia",
    logo: require("assets/images/falabella/mallplaza.png"),
  },
  {
    key: "Linio México",
    logo: require("assets/images/falabella/linio.png"),
  },
  {
    key: "Lexicom",
    logo: require("assets/images/falabella/cmr.png"),
  },
  {
    key: "Mall Plaza Perú",
    logo: require("assets/images/falabella/mallplaza.png"),
  },
]; */
