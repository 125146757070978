/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Col, Row, Carousel, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "./CarouselIndexJovenes.style.css";
import {useIntl} from 'react-intl';
import {FormattedMessage} from 'react-intl';

const items = [
  {
    id: 1,
    srcWeb: {
      es: require("assets/images/falabella/jovenes/ImgOnda-01.png"),
      en: require("assets/images/falabella/jovenes/ImgOnda-01_ingles.png"),
    },
    srcMobile: {
      es: require("assets/images/falabella/jovenes/Banner-Mobile_2.png"),
      en: require("assets/images/falabella/jovenes/Banner-Mobile_2_ingles.png"),
    },
  }
];

const getCarouselItems = (items, isLargeScreen, lang) => items.map((item,i) => (
  <Carousel.Item key={i}>
    <Row className="">
      <Col lg={12} md={12} sm={12} xs={12} className="image-wrapper-carousel-jovenes">
        <Image
          className="w-100"
          src={isLargeScreen ? item.srcWeb[lang] : item.srcMobile[lang]}
        />
      </Col>
    </Row>
  </Carousel.Item>
));

const CarouselIndex = () => {
  const intl = useIntl();
  const lang = intl.locale === 'es-ES' ? 'es' : 'en'; 
  let sliderPorDefecto;
  sliderPorDefecto = items;
    
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const [index, setIndex] = useState(0);
  const [carouselItemData, setCarouselItemData] = useState(getCarouselItems(items, isLargeScreen, lang));
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    let nodos = sliderPorDefecto.map((item,i) => {
      let classNameImage = "w-100";
      if (isLargeScreen) {
        classNameImage = "w-100 ";
      }
      return (
        <Carousel.Item key={i}>
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12} className="image-wrapper-carousel-jovenes">
              <Image
                className={classNameImage}
                src={isLargeScreen ? item.srcWeb[lang] : item.srcMobile[lang]}
              />
            </Col>
          </Row>
        </Carousel.Item>
      );
    });
    setCarouselItemData(nodos);
  }, [isLargeScreen, sliderPorDefecto, lang]);

  return (
    <div id="carouseljovenes" name="carouseljovenes">
      <div style={{ display: "block", width: "auto" }}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          prevIcon={null}
          nextIcon={null}
          pause="false"
        >
          {carouselItemData}
        </Carousel>
        {isLargeScreen ? 
        <div>
            
            <div className={`${intl.locale === "es-ES" ? "carousel-text-jovenes" : "carousel-text-english-jovenes"}`}>

            </div>

        </div> : 
        <div>
            <div className={`${intl.locale === "es-ES" ? "carousel-text-mobile-jovenes" : "carousel-text-mobile-jovenes"}`}>

            </div>
        </div>
        }
      </div>
    </div>
  );
};
export default CarouselIndex;
