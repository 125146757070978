// import node module libraries
import { Fragment } from "react";
import FooterInterno from "./FooterInterno";
import NavbarLandingJobsInterno from "./NavbarLandingJobsInterno";
import { SideScroll } from "components/falabella/jobs/SideScroll/SideScroll";

const FalabellaLayout = (props) => {
  return (
    <Fragment>
      <NavbarLandingJobsInterno transparent variant="light"/>
      {props.children}
      <FooterInterno id="FOOTER" />
      <SideScroll />
    </Fragment>
  );
};

export default FalabellaLayout;
