// import node module libraries
import ComponentHeadInterno from "./components/componentHeadInterno";
import MappingPersonsInterno from "./components/mappingPersonsInterno";
import ComponentFootInterno from "./components/componentFootInterno";

import "./TuNosImportas.style.css";

const TuNosImportasInterno = (param) => {
  let formato;
  formato=param.format;
  return (
    <div
      className="py-8 w-100 d-block bg-carousel-mapas-mobile"
      name="paisesinternos"
      id="paisesinternos"
      style={{ overflowX: "hidden" }}
    >
      <div>
        <ComponentHeadInterno format={formato}/>
        <MappingPersonsInterno format={formato}/>
        <ComponentFootInterno format={formato}/>
      </div>
    </div>
  );
};

export default TuNosImportasInterno;
