import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { faLocationDot, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKeycloak } from "@react-keycloak/web";

const ItemCargo = ({ item, index, color }) => {
  const { keycloak } = useKeycloak();
  const offerType = keycloak.authenticated ? "internal" : "external";
  return (
    <div>
      <Link to={`/detalle-oferta/${item.offer_id}/${offerType}`}>
        <Card
          className={`mb-4 card-hover border-card-experiencia-cargos-jovenes`}
          style={{ backgroundColor: color }}
        >
          <Card.Body className="text-start text-white">
            <span className="h4 text-white">
              <span
                className="btn rounded-pill mx-2 btn"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
              >
                <b>Falabella Equipo Corporativo</b>
              </span>

              <br />
              <br />

              <b>
                <span style={{ color: "#00D1FF" }}></span>
                {item.title.substring(0, 70)}
                {item.title.length > 70 ? "..." : ""}
                <span style={{ color: "#00D1FF" }}></span>
              </b>

              <br />
              <span style={{ color: "#00D1FF" }}></span>
              {item.description.substring(0, 100)}
              {item.description.length > 100 ? "..." : ""}
              <span style={{ color: "#00D1FF" }}></span>
              <br />
              <br />
              <div>
                <Row>
                  <Col
                    xs="12"
                    className="text-start d-flex align-items-center mb-3"
                  >
                    <span className="btn btn-gray btn-sm rounded-icon d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        color="#111111"
                        className="icon-cargos"
                      />
                    </span>
                    <span className="mx-2">
                      {item.country + ", " + item.city}.
                    </span>
                  </Col>
                  <Col xs="12" className="text-start d-flex align-items-center">
                    <span className="btn btn-gray btn-sm rounded-icon d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        color="#111111"
                        className="icon-cargos"
                      />
                    </span>
                    <span className="mx-2">{item.jobtype}.</span>
                  </Col>
                </Row>
              </div>
            </span>
          </Card.Body>
          <Card.Footer>
            <div style={{ textAlign: "right" }}>
              <span
                className="btn rounded-pill mx-2  btn text-white"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
              >
                <b>Ver más {"->"}</b>
              </span>
            </div>
          </Card.Footer>
        </Card>
      </Link>
    </div>
  );
};

export default ItemCargo;
