/* eslint-disable no-unused-vars */
// import node module libraries
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const NavDropdownDesk = (props) => {
  const { item, onClick } = props;
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }
  const isLargeScreen = useMediaQuery({ minWidth: 992 });
  return (
    <div className="mt-4">
      <NavDropdown
        className="menu-principal"
        title={item.menuitem}
        style={isLargeScreen ? { fontSize: "1vw" } : { fontSize: "2vw" }}
        as={Link}
        to={item.link}
        show={show}
        onMouseEnter={showDropdown} 
        onMouseLeave={hideDropdown}
      >
        {item.children.map((submenu, submenuindex) => {
          if (submenu.divider || submenu.header) {
            return submenu.divider ? (
              <NavDropdown.Divider bsPrefix="mx-3" key={submenuindex} />
            ) : (
              <h4 className="dropdown-header" key={submenuindex}>
                {submenu.header_text}
              </h4>
            );
          } else {
            if (submenu.children === undefined) {
              return (
                <NavDropdown.Item
                  key={submenuindex}
                  as={Link}
                  to={submenu.link}
                  
                >
                  {/* Second level menu item without having sub menu items */}
                  {submenu.menuitem}
                </NavDropdown.Item>
              );
            } else {
              return (
                <NavDropdown
                  title={submenu.menuitem}
                  key={submenuindex}
                  bsPrefix="dropdown-item d-block"
                  className={`dropdown-submenu dropend py-0 `}
                >
                  {submenu.children.map((submenuitem, submenuitemindex) => {
                    if (submenuitem.divider || submenuitem.header) {
                      return submenuitem.divider ? (
                        <NavDropdown.Divider
                          bsPrefix="mx-3"
                          key={submenuitemindex}
                        />
                      ) : (
                        <Fragment key={submenuitemindex}>
                          {/* Third level menu heading with description  */}
                          <h5 className="dropdown-header text-dark">
                            {submenuitem.header_text}
                          </h5>
                          <p className="dropdown-text mb-0 text-wrap">
                            {submenuitem.description}
                          </p>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={submenuitemindex}>
                          {submenuitem.type === "button" ? (
                            <div className="px-3 d-grid">
                              {/* Third Level with button format menu item */}
                              <Link
                                to={submenuitem.link}
                                className="btn-sm btn-primary text-center"
                              >
                                {submenuitem.menuitem}
                              </Link>
                            </div>
                          ) : (
                            <NavDropdown.Item
                              as={Link}
                              to={submenuitem.link}
                              onClick={(expandedMenu) => onClick(!expandedMenu)}
                            >
                              {/* Third Level menu item */}
                              {submenuitem.menuitem}
                            </NavDropdown.Item>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </NavDropdown>
              );
            }
          }
        })}
      </NavDropdown>
    </div>
  );
};
export default NavDropdownDesk;
