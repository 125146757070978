import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import {
  faMapPin,
  faBriefcaseClock
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from "@react-keycloak/web"; 
const ItemCargo = ({ item }) => {
  const { keycloak } = useKeycloak();

  const offerType = keycloak.authenticated ? "internal" : "external";
  
  return (
    <div>
      <Link to={`/detalle-oferta/${item.offer_id}/${offerType}`}>
        <Card className={`mb-4 card-hover border-card-experiencia-cargos-tech bg-black`}>
          <Card.Body className="text-start ">
            <span className="h4 text-white">
              <center>
              <span className="btn rounded-pill mx-2 btn-tech btn btn-primary">
                <b>
                  Falabella Equipo Corporativo
                </b>
              </span>
              </center>
              <br/>
              <br/>
              <center>
              <b>
                <span style={{ color: '#00D1FF' }}>{'<h3>'}</span>{item.title.substring(0, 70)}
                {item.title.length > 70 ? "..." : ""}<span style={{ color: '#00D1FF' }}>{'</h3>'}</span>
              </b>
              </center>         
              <br />
              <span style={{ color: '#00D1FF' }}>{'<p>'}</span>
              {item.description.substring(0, 100)}
              {item.description.length > 100 ? "..." : ""}
              <span style={{ color: '#00D1FF' }}>{'</p>'}</span>
              <br />
              <br />
              <div>
                <Row>
                  <Col xs="12" style={{ color: "yellow" }} className="text-start d-flex align-items-center">
                  <FontAwesomeIcon icon={faMapPin} color="#00D1FF" /><span className="mx-2">{item.country + ", " + item.city}.</span>
                  </Col>
                  <Col xs="12" style={{ color: "yellow" }} className="text-start d-flex align-items-center">
                  <FontAwesomeIcon icon={faBriefcaseClock} color="#00D1FF" /><span className="mx-2">{item.jobtype}.</span>
                  </Col>
                </Row>
              </div>
            </span>
          </Card.Body>
          <Card.Footer>
            <div style={{ textAlign: 'right' }}>
              <span className="btn rounded-pill mx-2  btn btn-secondary border">
                <b>
                Ver más {'->'}
                </b>
              </span>
            </div>
          </Card.Footer>
        </Card>
      </Link>
    </div>
  );
};

export default ItemCargo;
