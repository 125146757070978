/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Col, Row, Carousel, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "./CarouselIndex.style.css";
import {useIntl} from 'react-intl';
import {FormattedMessage} from 'react-intl';

const items = [
  {
    id:1,
    srcWeb: require("assets/images/falabella/falabellatech/carousel/Falabella-Tech-atraccion-de-talento-banner-1.jpg"),
    srcMobile: require("assets/images/falabella/falabellatech/carousel/bannermobile1.png"),
  },
  {
    id:2,
    srcWeb: require("assets/images/falabella/falabellatech/carousel/Falabella-Tech-atraccion-de-talento-banner-2.png"),
    srcMobile: require("assets/images/falabella/falabellatech/carousel/bannermobile2.png"),
  },
  {
    id:3,
    srcWeb: require("assets/images/falabella/falabellatech/carousel/Falabella-Tech-atraccion-de-talento-banner-3.jpg"),
    srcMobile: require("assets/images/falabella/falabellatech/carousel/bannermobile3.png"),
  },
  {
    id:4,
    srcWeb: require("assets/images/falabella/falabellatech/carousel/Falabella-Tech-atraccion-de-talento-banner-2.png"),
    srcMobile: require("assets/images/falabella/falabellatech/carousel/bannermobile2.png"),
  }
];

const CarouselIndex = () => {
  const intl = useIntl();
  let sliderPorDefecto;
  sliderPorDefecto = items;
    
  const [index, setIndex] = useState(0);
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const [carouselItemData, setCarouselItemData] = useState();
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    let nodos = sliderPorDefecto.map((item,i) => {
      let classNameImage = "w-100";
      if (isLargeScreen) {
        classNameImage = "w-100 ";
      }
      return (
        <Carousel.Item key={i}>
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12} className="image-wrapper-carousel">
              <Image
                className={classNameImage}
                src={isLargeScreen ? item.srcWeb : item.srcMobile}
              />
            </Col>
          </Row>
        </Carousel.Item>
      );
    });
    setCarouselItemData(nodos);
  }, [isLargeScreen,sliderPorDefecto]);

  return (
    <div id="presencia" name="presencia">
      <div style={{ display: "block", width: "auto" }}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={true}
          pause="false"
        >
          {carouselItemData}
        </Carousel>
        {isLargeScreen ? 
        <div>
            <div className="carousel-tag-button">
                <h1 style={{ color: "white" }}>#FalabellaTech</h1>
            </div>
            <div className="carousel-tag-top">
                <h1>{"<h1>"}</h1>
            </div>
            <div className={`${intl.locale === "es-ES" ? "carousel-text" : "carousel-text-english"}`}>
                <h3 className="classH3SizeTech text-white"><FormattedMessage id="app.tech.carousel0"/> <span style={{ color: "#F650DC" }}><FormattedMessage id="app.tech.carousel1"/></span></h3>
                <h3 className="classH3SizeTech text-white"><FormattedMessage id="app.tech.carousel2"/> <span style={{ color: "#F650DC" }}><FormattedMessage id="app.tech.carousel3"/></span></h3>
            </div>
            <div className="carousel-tag-bottom">
                <h1>{"</h1>"}</h1>
            </div>
        </div> : 
        <div>
            <div className="carousel-tag-button-mobile">
                <h2 className="p-1" style={{ color: "white" }}>#FalabellaTech</h2>
            </div>
            <div className={`${intl.locale === "es-ES" ? "carousel-text-mobile" : "carousel-text-mobile"}`}>
                <h2 style={{ color: "yellow" }}>{"<h1>"}</h2>
                <h4 className="text-white"><FormattedMessage id="app.tech.carousel0"/> <span style={{ color: "#F650DC" }}><FormattedMessage id="app.tech.carousel1"/></span></h4>
                <h4 className="text-white"><FormattedMessage id="app.tech.carousel2"/> <span style={{ color: "#F650DC" }}><FormattedMessage id="app.tech.carousel3"/></span></h4>
                <h2 style={{ color: "yellow" }}>{"</h1>"}</h2>
            </div>
        </div>
        }
      </div>
    </div>
  );
};
export default CarouselIndex;
