const falabella1 = require("assets/images/falabella/grandes_espacios/1.jpg");
const falabella2 = require("assets/images/falabella/grandes_espacios/2.jpg");
const falabella3 = require("assets/images/falabella/grandes_espacios/3.jpg");
const falabella4 = require("assets/images/falabella/grandes_espacios/4.jpg");
const falabella5 = require("assets/images/falabella/grandes_espacios/5.jpg");
const falabella6 = require("assets/images/falabella/grandes_espacios/6.jpg");
const falabella7 = require("assets/images/falabella/grandes_espacios/7.jpg");
const falabella8 = require("assets/images/falabella/grandes_espacios/8.jpg");
const falabella9 = require("assets/images/falabella/grandes_espacios/9.jpg");
const falabella10 = require("assets/images/falabella/grandes_espacios/10.jpg");
const falabella11 = require("assets/images/falabella/grandes_espacios/11.jpg");
const falabella12 = require("assets/images/falabella/grandes_espacios/12.jpg");
const falabella13 = require("assets/images/falabella/grandes_espacios/13.jpg");
const falabella14 = require("assets/images/falabella/grandes_espacios/14.jpg");
const falabella15 = require("assets/images/falabella/grandes_espacios/15.jpg");
const falabella16 = require("assets/images/falabella/grandes_espacios/16.jpg");
const falabella17 = require("assets/images/falabella/grandes_espacios/17.jpg");
const falabella18 = require("assets/images/falabella/grandes_espacios/18.jpg");
const falabella19 = require("assets/images/falabella/grandes_espacios/19.jpg");
const falabella20 = require("assets/images/falabella/grandes_espacios/20.jpg");
const falabella21 = require("assets/images/falabella/grandes_espacios/21.png");
const falabella22 = require("assets/images/falabella/grandes_espacios/22.jpg");
const falabella23 = require("assets/images/falabella/grandes_espacios/23.jpg");
const falabella24 = require("assets/images/falabella/grandes_espacios/24.png");

export const GrandesEspaciosData = [
  [
    {
      id: 1,
      image: falabella1,
      position: "h-20rem",
      image2: falabella2,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella4,
      position: "h-16rem",
      image2: falabella3,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella5,
      position: "h-20rem",
      image2: falabella6,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella7,
      position: "h-20rem",
      image2: falabella8,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella9,
      position: "h-16rem",
      image2: falabella10,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella11,
      position: "h-20rem",
      image2: falabella12,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella13,
      position: "h-20rem",
      image2: falabella14,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella15,
      position: "h-16rem",
      image2: falabella16,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella17,
      position: "h-20rem",
      image2: falabella18,
      position2: "h-18rem",
    },
  ],
  [
    {
      id: 1,
      image: falabella19,
      position: "h-20rem",
      image2: falabella20,
      position2: "h-18rem",
    },
    {
      id: 2,
      image: falabella21,
      position: "h-16rem",
      image2: falabella22,
      position2: "h-24rem",
    },
    {
      id: 3,
      image: falabella23,
      position: "h-20rem",
      image2: falabella24,
      position2: "h-18rem",
    },
  ],
];

export default GrandesEspaciosData;
