import { useEffect, useState } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import arrow from "assets/images/falabella/jovenes/arrowfala.png";
import { useMediaQuery } from "react-responsive";
import { useIntl, FormattedMessage } from "react-intl";
import "./Tesumas.style.css";
import ReactPlayer from "react-player";

import Cinema from "../Cinema/Cinema";
import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'

const Tesumas = () => {
  const [videoURLs, setVideoURLs] = useState({});
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const arrowStyleizq = !isLargeScreen ? { width: "9%" } : { width: "8%" };
  const arrowStyleder = !isLargeScreen
    ? { width: "9%" }
    : { width: "8%", transform: "scaleX(-1)" };
  const [show, setShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const intl = useIntl();
  const currentLanguage = intl.locale;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const service = new OfertasLaboralesService();
  const videoNamesConfig = {
    'es-ES': {
      'Video1': "tiktok_oficinas.mp4",
      'Video2': "tiktok_1.mp4",
      'Video3': "tiktok_negocios_espanol.mp4",
      'PreviewVideo1': "tiktok_oficinas_corto_espanol.mp4",
      'PreviewVideo2': "tiktok_2_corto.mp4",
      'PreviewVideo3': "tiktok_negocios_espanol.mp4"
    },
    'en-US': {
      'Video1': "tiktok_oficinas_ingles.mp4",
      'Video2': "tiktok_1.mp4",
      'Video3': "tiktok_negocios_ingles.mp4",
      'PreviewVideo1': "tiktok_oficinas_ingles_corto.mp4",
      'PreviewVideo2': "tiktok_2_corto.mp4",
      'PreviewVideo3': "tiktok_negocios_ingles_corto.mp4"
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      const videoURLs = {};
      const videoNamesLanguage = videoNamesConfig[currentLanguage] || videoNamesConfig['en-US'];
      for (const videoName in videoNamesLanguage) {
        videoURLs[videoName] = await getVideoURL(videoNamesLanguage[videoName]);
      }
      setVideoURLs(videoURLs);
    }
    
    fetchVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



//   const Video1_es =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Viktok_Oficinas.mp4";
//   const Video2_es =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Tiktok%20Catha.mp4";
//   const Video3_es =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Tiktok_Negocios-espan%CC%83ol.mp4";
//   const Video1_en =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/VTikTok_OficinasIngle%CC%81s.mp4";
//   const Video2_en =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Tiktok%20Catha.mp4";
//   const Video3_en =
//     "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Tiktok_Negocios_Ingle%CC%81s.mp4";
//   const Video1 = currentLanguage === "es-ES" ? Video1_es : Video1_en;
//   const Video2 = currentLanguage === "es-ES" ? Video2_es : Video2_en;
//   const Video3 = currentLanguage === "es-ES" ? Video3_es : Video3_en;

//   // Suponiendo que tienes disponibles estas URL para español
// const PreviewVideo1_es = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Video%20Oficinas%20Espan%CC%83ol%20Corto.mp4";;
// const PreviewVideo2_es = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/tiktok-catha_SYDen3Gm.mp4";
// const PreviewVideo3_es = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Video%20Negocios%20Espan%CC%83ol%20Corto.mp4";

// // Suponiendo que tienes disponibles estas URL para inglés
// const PreviewVideo1_en = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Video%20Oficinas%20Ingles%20Corto.mp4";
// const PreviewVideo2_en = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/tiktok-catha_SYDen3Gm.mp4";
// const PreviewVideo3_en = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/Video%20Negocios%20Ingles%20Corto.mp4";

// const PreviewVideo1 = currentLanguage === "es-ES" ? PreviewVideo1_es : PreviewVideo1_en;
// const PreviewVideo2 = currentLanguage === "es-ES" ? PreviewVideo2_es : PreviewVideo2_en;
// const PreviewVideo3 = currentLanguage === "es-ES" ? PreviewVideo3_es : PreviewVideo3_en;


const handleVideoClick = (previewVideo, fullVideo) => {
  setSelectedVideo(fullVideo);
  handleShow();
};

  return (
    <div className="py-8 bg-tesumas" id="sumasjovenes" name="sumasjovenes">
      <Container>
        <div className={`mb-10 pt-5 justify-content-center ${isLargeScreen ? "pb-8" : ""}`}>
          <center>
            <Col lg={12} md={12} sm={12} className="text-center">
              <h4
                className={`mb-2 display-4 fw-bold ${
                  isLargeScreen ? "classH3Size" : ""
                }`}
              >
                <Image src={arrow} style={arrowStyleizq} />
                <span style={{ color: "#92BE49" }}>
                  {' '}<FormattedMessage id="app.tech.video" />{' '}
                </span>
                <Image src={arrow} style={arrowStyleder} />
              </h4>
            </Col>
          </center>
        </div>
        <Row>
        <Col xs={12} sm={4} style={{ marginTop: isLargeScreen ? "25px" : "0" }} onClick={() => handleVideoClick(videoURLs.PreviewVideo1, videoURLs.Video1)} className="pointer-cursor">
            <div className={` ${isLargeScreen ? "" : "pt-5 pb-4"}`}>
              <div className="vertical-react-player-wrapper green-border-sumas">
              <ReactPlayer
  className="custom-react-player-sumas"
  url={videoURLs.PreviewVideo1} // Utiliza el video cortado para la vista previa
  controls={false}
  loop
  muted
  playing
  style={{ objectFit: "cover", opacity: isLargeScreen ? '90%' : '', backgroundColor: isLargeScreen ? 'black' : '' }}
/>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={4} style={{ marginTop: isLargeScreen ? "0px" : "0" }} onClick={() => handleVideoClick(videoURLs.PreviewVideo2, videoURLs.Video2)} className="pointer-cursor">
            <div className={` ${isLargeScreen ? "image-container" : "image-container pt-5 pb-4"}`}>
            <div className="vertical-react-player-wrapper azul-border-sumas-video">
            <ReactPlayer
  className="custom-react-player-sumas"
  url={videoURLs.PreviewVideo2} // Utiliza el video cortado para la vista previa
  controls={false}
  loop
  muted
  playing
  style={{ objectFit: "cover", opacity: isLargeScreen ? '90%' : '', backgroundColor: isLargeScreen ? 'black' : '' }}
/>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={4} style={{ marginTop: isLargeScreen ? "-25px" : "0" }} onClick={() => handleVideoClick(videoURLs.PreviewVideo3, videoURLs.Video3)} className="pointer-cursor">
            <div className={` ${isLargeScreen ? "image-container" : "image-container pt-5 pb-4"}`}>
            <div className="vertical-react-player-wrapper lila-border-sumas">
            <ReactPlayer
  className="custom-react-player-sumas"
  url={videoURLs.PreviewVideo3} // Utiliza el video cortado para la vista previa
  controls={false}
  loop
  muted
  playing
  style={{ objectFit: "cover", opacity: isLargeScreen ? '90%' : '', backgroundColor: isLargeScreen ? 'black' : '' }}
/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Cinema
        show={show}
        setShow={handleClose}
        url={selectedVideo}
      />
    </div>
  );
};

export default Tesumas;
