/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Image, Navbar, Nav, Row, Col, Button } from "react-bootstrap";
import NavbarDefaultRoutes from "routes/falabella/NavbarDefault";
import NavbarDefaultFormatRoutes from "routes/falabella/NavbarFormatDefault";
import { useMediaQuery } from "react-responsive";
import BrandCarouselColor from "components/falabella/jobs/BrandCarousel/BrandCarouselColor";
import { logos_color } from "data/landing/logo-color-list";
import NavDropdownDesk from "components/falabella/common/NavDropdownDesk";
import NavDropdownMobile from "components/falabella/common/NavDropdownMobile";
import {FormattedMessage} from 'react-intl'
import { NavDropdown } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import "./NavbarLandingJobs.style.css";
import {langContext} from './../../content/langContext';
const logoMenuLight = require("assets/images/falabella/muevete_light.png");
const logoMenuDark = require("assets/images/falabella/muevete_dark.png");
const NavbarLandingJobs = ({ transparent, variant }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isLargeScreen = useMediaQuery({ minWidth: 992 });
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [colorVariant, setColorVariant] = useState(variant);
  const [menuItems, setMenuItems] = useState(NavbarDefaultRoutes);
  const [menuItemsFormat, setMenuItemsFormat] = useState(NavbarDefaultFormatRoutes);
  const [showTopMenu, setShowTopMenu] = useState(true);
  const [show, setShow] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  
  function toggleMenu() {
    setIsOpen(!isOpen);
    setShowCloseIcon(!showCloseIcon);
  }

  const buttonStyle = {
    color: colorVariant === 'light' ? 'black' : 'white',
  };

  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }
  
  const checkScroll = useCallback(() => {
    if (isLargeScreen) {
      let offset = window.scrollY;
      if (offset > 60) {
        setShowTopMenu(false);
      }
      if (offset < 60) {
        setShowTopMenu(true);
      }
    }
  }, [isLargeScreen]);

  useEffect(() => {
    if (isLargeScreen) {
      window.addEventListener("scroll", checkScroll);
      setColorVariant(variant);
    } else {
      setColorVariant("light");
      if (currentPath === "/") {
        setExpandedMenu(true);
      }
    }
  }, [checkScroll, isLargeScreen, variant]);

  useEffect(() => {
    if (isLargeScreen) {
      setMenuItems(NavbarDefaultRoutes.slice(0, 3));
    } else {
      setMenuItems(NavbarDefaultRoutes.slice(0, 3));
    }

    return () => {
      setMenuItems([]);
    };
  }, [isLargeScreen]);

  useEffect(() => {
    if (isLargeScreen) {
      setMenuItemsFormat(NavbarDefaultFormatRoutes.slice(0, 1));
    } else {
      setMenuItemsFormat(NavbarDefaultFormatRoutes.slice(0, 2));
    }

    return () => {
      setMenuItemsFormat([]);
    };
  }, [isLargeScreen]);
  const idioma = useContext(langContext);

  return (
    <Fragment>
      <div
        className={showTopMenu ? "d-block" : "d-none"}
        style={{ transition: "0.3" }}
      >
        <div
          className="d-none d-lg-block py-lg-3 py-3 w-100"
          style={{ backgroundColor: "white" }}
        >
          <Row className="align-items-center" style={{ marginRight: "0" }}>
            <Col sm={`${isLargeScreen ? "1" : "2"}`}></Col>
            {logos_color.map((item) => (
              <Col className="text-center">
                <Link to={item.url}>
                  <Image
                    src={item.srcWeb}
                    style={{ height: "1.4vw", width: "auto" }}
                  />
                </Link>
              </Col>
            ))}
            <Col xs="2"></Col>
          </Row>
        </div>

        <Row style={{ margin: "0" }}>
          <Col lg="1" className="d-none d-lg-block"></Col>
          <Col sm="12" lg="10" className="px-0">
            <Navbar
              variant={colorVariant}
              onToggle={(collapsed) => setExpandedMenu(collapsed)}
              expanded={expandedMenu}
              expand="lg"
              className={`${
                isLargeScreen ? "pt-6" : "w-100 sticky-top"
              } navbar shadow-none 
              ${isLargeScreen && transparent && "navbar-transparent"}`}
              style={
                isLargeScreen
                  ? { maxWidth: "75vw"}
                  : { backgroundColor: "white", position: "fixed" }
                  
              }
            >
              <Navbar.Brand as={Link} to="/">
                <Row>
                  <Col lg="1" className="d-lg-none pt-2" style={{ width: "37vw" }}>
                    <BrandCarouselColor height="4vw" />
                  </Col>
                  <Col className="text-end">
                    <Image
                      style={
                        isLargeScreen
                          ? { height: "5.5vw", width: "auto" }
                          : { height: "6.5vw", width: "auto" }
                      }
                      src={
                        colorVariant === "light" ? logoMenuLight : logoMenuDark
                      }
                      className="mt-0"
                    />
                  </Col>
                </Row>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }}>
                <span className="icon-bar top-bar mt-0" style={{ backgroundColor: 'black' }}></span>
                <span className="icon-bar middle-bar" style={{ backgroundColor: 'black' }}></span>
                <span className="icon-bar bottom-bar" style={{ backgroundColor: 'black' }}></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {menuItems.map((item, index) => {
                    if (item.children === undefined) {
                      return (
                        <Nav.Link key={index} as={Link} to={item.link}>
                          {item.menuitem}
                        </Nav.Link>
                      );
                    } else {
                      return isLargeScreen ? (
                        <Fragment>
                          {/*
                          <NavDropdownDesk
                            item={item}
                            key={index}
                            onClick={(value) => setExpandedMenu(value)}
                            variant={colorVariant}
                          />
                          */}
                        </Fragment>
                      ) : (
                        <Fragment>
                          
                        </Fragment>
                      );
                    }
                  })}
                  {!isLargeScreen && (
                    <Fragment>
                       {menuItems.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return  (
                            <Fragment>
                              <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu} variante={'muevete'}/>
                            </Fragment>
                          );
                        }
                      })}
                      {menuItemsFormat.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return  (
                            <Fragment>
                              <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu} variante={'muevete'}/>
                            </Fragment>
                          );
                        }
                      })}
                      <div className="p-4 text-center">
                        <Button onClick={() => idioma.establecerLenguaje('es-ES')} variant="primary">Español</Button>{' '}
                        <Button onClick={() => idioma.establecerLenguaje('en-US')} variant="secondary">English</Button>{' '}
                      </div>
                    </Fragment>
                  )}
                </Nav>
                {isLargeScreen && (
                  <Fragment>
                    <Link className="p-2 text-center nav-link fs-4 p-4" as={Link} to="/explorar"><FormattedMessage id="app.menu.oportunidad.ofertas.2" /></Link>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block fs-3">
                    | 
                    </div>
                    <Link className="p-2 text-center nav-link fs-4 p-4" as={Link} to="/muevete"><FormattedMessage id="app.oportunidadescolab.2" /></Link>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block fs-3">
                    | 
                    </div>
                    <Link className="p-2 text-center nav-link fs-4 p-4" as={Link} to="/jovenes-profesionales"><FormattedMessage id="app.menu.oportunidad.jovenes" /></Link>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block fs-3">
                    | 
                    </div>
                    <Link className="p-2 text-center nav-link fs-4 p-4" as={Link} to="/falabellatech"><FormattedMessage id="app.menu.oportunidad.tech" /></Link>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block fs-3">
                    | 
                    </div>
                    <div className="p-3">
                      <NavDropdown
                        className={`menu-principal-lang-${colorVariant}`}
                        title={<FormattedMessage id="app.menu.selector.idioma"/>}
                        style={isLargeScreen ? { fontSize: "1.2vw" } : { fontSize: "2vw" }}
                        show={show}
                        onMouseEnter={showDropdown} 
                        onMouseLeave={hideDropdown}
                      >
                        <Dropdown.Item onClick={() => idioma.establecerLenguaje('es-ES')}>ES | Español</Dropdown.Item>
                        <Dropdown.Item onClick={() => idioma.establecerLenguaje('en-US')}>EN | English</Dropdown.Item>
                      </NavDropdown>
                    </div>
                    <div className="ms-lg-3 d-none d-md-none d-lg-block fs-3">
                    | 
                    </div>
                    <div className={`menu-principal-lang-${colorVariant} ms-lg-3 d-none d-md-none d-lg-block`}>
                      <button  onClick={toggleMenu} className={`icon-${colorVariant}`}>
                        {showCloseIcon ? (
                          <i className={`fas fa-times`} style={buttonStyle}></i>
                        ) : (
                          <i className={`fas fa-bars`} style={{ color: 'black' }}></i>
                        )}
                      </button>

                    </div>
                 
                    
                   
                  </Fragment>
                )}
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col lg="1" className="d-none d-lg-block"></Col>
        </Row>
      </div>
      {isLargeScreen && (
        <Navbar
          sticky="top"
          onToggle={(collapsed) => setExpandedMenu(collapsed)}
          expanded={expandedMenu}
          style={{ backgroundColor: "#FFFFFF", transition: "0.3" }}
          className={!showTopMenu ? "d-flex p-0" : "d-none"}
        >
          <Navbar.Brand as={Link} to="/" style={{ paddingLeft: "17%" }}>
            <Row>
              <Col lg="1" className="d-lg-none" style={{ width: "35vw" }}>
                <BrandCarouselColor height="4vw" />
              </Col>
              <Col className="text-end">
                <Image
                  style={
                    isLargeScreen
                      ? { height: "2.5vw", width: "auto" }
                      : { height: "4.5vw", width: "auto" }
                  }
                  src={logoMenuLight}
                  className="mt-2"
                />
              </Col>
            </Row>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" style={{ paddingRight: "17%" }}>
            <Nav className="ms-auto p-4">
              {menuItems.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link key={index} as={Link} to={item.link}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return isLargeScreen ? (
                    <Fragment>
                        <div className="mt-4">
                        </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu}/>
                    </Fragment>
                  );
                }
              })}
            </Nav>
            {isLargeScreen && (
              <Fragment>
                  <div className="p-2">
                    <NavDropdown
                      className={`menu-principal-lang-light`}
                      title={<FormattedMessage id="app.menu.selector.idioma"/>}
                      style={isLargeScreen ? { fontSize: "1.2vw" } : { fontSize: "2vw" }}
                      show={show}
                      onMouseEnter={showDropdown} 
                      onMouseLeave={hideDropdown}
                    >
                        <Dropdown.Item onClick={() => idioma.establecerLenguaje('es-ES')}>ES | Español</Dropdown.Item>
                        <Dropdown.Item onClick={() => idioma.establecerLenguaje('en-US')}>EN | English</Dropdown.Item>
                    </NavDropdown>
                  </div>
                  <div className="ms-lg-3 d-none d-md-none d-lg-block">
                    | 
                  </div>
                  <div className={`menu-principal-lang-${colorVariant} ms-lg-3 d-none d-md-none d-lg-block`}>
                    <button  onClick={toggleMenu} className={`icon-${colorVariant}`}>
                      {showCloseIcon ? (
                        <i className={`fas fa-times`} style={buttonStyle}></i>
                      ) : (
                        <i className={`fas fa-bars`} style={{ color: 'black' }}></i>
                      )}
                    </button>
                    <div className={`p-4 menu ${isOpen ? "menu-open" : ""}`}>
                    <Fragment>
                      <div style={{textAlign: 'right'}}>
                        <button  onClick={toggleMenu} className={`icon-${colorVariant}`}>
                            {showCloseIcon ? (
                              <i className={`fas fa-times`} style={buttonStyle}></i>
                            ) : (
                              <i className={`fas fa-bars`} style={buttonStyle}></i>
                            )}
                        </button>
                      </div>
                      {menuItems.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return !isLargeScreen ? (
                            <NavDropdownDesk
                              item={item}
                              key={index}
                              onClick={(value) => setExpandedMenu(value)}
                              variant={colorVariant}
                            />
                          ) : (
                            <Fragment>
                              <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu}/>
                            </Fragment>
                          );
                        }
                      })}
                      {menuItemsFormat.map((item, index) => {
                        if (item.children === undefined) {
                          return (
                            <Nav.Link key={index} as={Link} to={item.link}>
                              {item.menuitem}
                            </Nav.Link>
                          );
                        } else {
                          return  (
                            <Fragment>
                              <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu}/>
                            </Fragment>
                          );
                        }
                      })}
                    </Fragment>
                    </div>
                  </div>
              </Fragment>
            )}
          </Navbar.Collapse>
        </Navbar>
      )}

      {isLargeScreen ? 
        <div className={`p-4 menu ${isOpen ? "menu-open" : ""}`}>
          <Fragment>
            <div style={{textAlign: 'right'}}>
              <button  onClick={toggleMenu} className={`icon-${colorVariant}`}>
                  {showCloseIcon ? (
                    <i className={`fas fa-times`} style={{ color: '#ffffff' }}></i>
                  ) : (
                    <i className={`fas fa-bars`} style={buttonStyle}></i>
                  )}
              </button>
            </div>
            {menuItems.map((item, index) => {
              if (item.children === undefined) {
                return (
                  <Nav.Link key={index} as={Link} to={item.link}>
                    {item.menuitem}
                  </Nav.Link>
                );
              } else {
                return !isLargeScreen ? (
                  <NavDropdownDesk
                    item={item}
                    key={index}
                    onClick={(value) => setExpandedMenu(value)}
                    variant={colorVariant}
                  />
                ) : (
                  <Fragment>
                    <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu}/>
                  </Fragment>
                );
              }
            })}
            {menuItemsFormat.map((item, index) => {
              if (item.children === undefined) {
                return (
                  <Nav.Link key={index} as={Link} to={item.link}>
                    {item.menuitem}
                  </Nav.Link>
                );
              } else {
                return  (
                  <Fragment>
                    <NavDropdownMobile item={item} key={index} toggleMenu={toggleMenu}/> 
                  </Fragment>
                );
              }
            })}
          </Fragment>
        </div>
      : <div></div>}
    </Fragment>
  );
};

// Specifies the default values for props
NavbarLandingJobs.defaultProps = {
  transparent: false,
};

// Typechecking With PropTypes
NavbarLandingJobs.propTypes = {
  transparent: PropTypes.bool,
};

export default NavbarLandingJobs;
