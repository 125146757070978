/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import "./mapping.style.css";
import { FilterContext } from "hooks/FilterContext";
import {  Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import  dataEmpresas  from "../../../explore/Filters/mueveteData.json";

const Persons = (props) => {
  let formato = props.format;
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const history = useHistory();
  const [imagen, setImagen] = useState(null);
  useEffect(() => {
    setImagen(require("assets/images/falabella/mapa-falabella.png"));
  }, []);

  
  let dataCompany;
  const filterJsonCompany = dataEmpresas.filter(function(dataSource){
     if(props.format === "Falabella Equipo Corporativo"){
      dataCompany="Falabella Corporativo";
     }else{
      dataCompany=props.format;
     }
     return dataSource.muevete === dataCompany;
  });
  const valoresEspecificosCompany = filterJsonCompany.map(element => element.muevete);

  const uniqueArrayCompany = [...new Set(valoresEspecificosCompany)];

  const { setFilters } = useContext(FilterContext);

  const urlPaisPin = (selectedIndex, e) => {
    const payload = {
      country: selectedIndex,
      area: [],
      company: uniqueArrayCompany,
      jobtype: [],
    };
    setFilters(payload);
    history.push(`/explorar/${selectedIndex}/${uniqueArrayCompany}`);
  };

  const paises = [
    {
      id: "1",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Sodimac"
    },
    {
      id: "2",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Sodimac"
    },
    {
      id: "3",
      srcWeb: require("assets/images/falabella/internos/banderas/argentina.png"),
      pais: "Argentina",
      formato:"Sodimac"
    },
    {
      id: "4",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Sodimac"
    },
    {
      id: "5",
      srcWeb: require("assets/images/falabella/internos/banderas/uruguay.png"),
      pais: "Uruguay",
      formato:"Sodimac"
    },
    {
      id: "6",
      srcWeb: require("assets/images/falabella/internos/banderas/brasil.png"),
      pais: "Brasil",
      formato:"Sodimac"
    },
    {
      id: "7",
      srcWeb: require("assets/images/falabella/internos/banderas/mexico.png"),
      pais: "México",
      formato:"Sodimac"
    },
    {
      id: "8",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Tottus"
    },
    {
      id: "9",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Tottus"
    },
    {
      id: "8",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Falabella.com"
    },
    {
      id: "9",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Falabella.com"
    },
    {
      id: "10",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Falabella Retail"
    },
    {
      id: "11",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Falabella Retail"
    },
    {
      id: "12",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Falabella Retail"
    },
    {
      id: "13",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Mallplaza"
    },
    {
      id: "14",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Mallplaza"
    },
    {
      id: "16",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Mallplaza"
    },
    {
      id: "17",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Banco Falabella"
    },
    {
      id: "18",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Banco Falabella"
    },
    {
      id: "19",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Banco Falabella"
    },
    {
      id: "20",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Falabella Inmobiliario"
    },
    {
      id: "21",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Falabella Inmobiliario"
    },
    {
      id: "22",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Falabella Financiero"
    },
    {
      id: "23",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Falabella Financiero"
    },
    {
      id: "24",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Falabella Financiero"
    },
    {
      id: "25",
      srcWeb: require("assets/images/falabella/internos/banderas/mexico.png"),
      pais: "México",
      formato:"Falabella Financiero"
    },
    {
      id: "26",
      srcWeb: require("assets/images/falabella/internos/banderas/argentina.png"),
      pais: "Argentina",
      formato:"Falabella Financiero"
    },
    {
      id: "27",
      srcWeb: require("assets/images/falabella/internos/banderas/mexico.png"),
      pais: "México",
      formato:"Falabella Soriana"
    },
    {
      id: "28",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Seguros Falabella"
    },
    {
      id: "29",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Seguros Falabella"
    },
    {
      id: "30",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Seguros Falabella"
    },
    {
      id: "38",
      srcWeb: require("assets/images/falabella/internos/banderas/chile.png"),
      pais: "Chile",
      formato:"Falabella Equipo Corporativo"
    },
    {
      id: "39",
      srcWeb: require("assets/images/falabella/internos/banderas/peru.png"),
      pais: "Perú",
      formato:"Falabella Equipo Corporativo"
    },
    {
      id: "40",
      srcWeb: require("assets/images/falabella/internos/banderas/india.png"),
      pais: "India",
      formato:"Falabella Equipo Corporativo"
    },
    {
      id: "41",
      srcWeb: require("assets/images/falabella/internos/banderas/colombia.png"),
      pais: "Colombia",
      formato:"Falabella.com"
    }
  ];

  const contador =paises.filter(function (dataSource) {
    return dataSource.formato === formato;
  }).length;

  const listPais = paises.filter(function (dataSource) {
    return dataSource.formato === formato;
  }).map((item , index) => (
    <div className="pb-5">
      <Image
        className="align-middle marginMobileCaraouselLeftInterno"
        src={item.srcWeb}
        onClick={() => {
          urlPaisPin(item.pais);
        }}
      />
    </div>
  ));

  const minSlidesToShow = contador;

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const settingsMobile2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  

  return (
    <div>
      {isLargeScreen ? (
        <div className="p-10">
          <div className=" text-center">
            <div className="py-15 mt-5 p-8">
              {
                contador >= 4 ?(
                  <Slider className="carouselMargin" {...settingsMobile}>{listPais}</Slider>
                ):(
                  <Slider className="carouselMargin" {...settingsMobile2}>{listPais}</Slider>
                )
              }
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-center">
            <div className="carouselMarginInterno">
              <Slider className="" {...settingsMobile}>{listPais}</Slider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Persons;
