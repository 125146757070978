import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

const Head = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  return (
    <Row className="mb-10 justify-content-center">
      <Col lg={12} md={12} sm={12} className="text-center">
        <h4
          className={`mb-2 display-4 fw-bold text-white  ${
            isLargeScreen ? "classH3Size" : ""
          }`}
        >
          <span style={{ color: "#2D46B9" }}> <FormattedMessage id="app.jovenes.cargos.titulo"/></span>
        </h4>
        <p style={{ fontSize: "18px" }}><FormattedMessage id="app.jovenes.cargos.desc"/></p>
      </Col>
    </Row>
  );
};
export default Head;
