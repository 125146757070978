/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Slider from "react-slick";
import imageRounded2 from 'assets/images/falabella/CUADRADO-03.jpg';
import imageRounded1 from 'assets/images/falabella/noticia2.png';
import imageRounded3 from 'assets/images/falabella/falaindia.jpg';
import {Col,Row,Card } from "react-bootstrap";
import "./Sabiasque.style.css";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';
import { Modal, Button} from 'react-bootstrap';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
        data-testid="next-arrow"
      className={
        "arrowDerechaViveExperienciaCargo slick-next-ViveExperienciaCargo"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaCargo slick-prev-ViveExperienciaCargo"}
      onClick={onClick}
    ></div>
  );
}

const Sabiasque = (param) => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', descripcion: '',image:'',desc1:'',desc2:'',desc3:'',desc4:'', url:'' });
  const handleClose = () => setShow(false);

  const handleShow = (title, descripcion,image,desc1,desc2,desc3,desc4,url) => {
    setModalInfo({ title, descripcion,image,desc1,desc2,desc3,desc4,url});
    setShow(true);
  }
  const ProtaData = [
	{
		id: 1,
		image: imageRounded1,
    clase: "",
		title: <FormattedMessage id="app.sabiasque.title.1"/>,
    descripcion: <FormattedMessage id="app.sabiasque.desc.1"/>,
    descripcionlarga1: <FormattedMessage id="app.sabiasque.desclargo.1.1"/>,
    descripcionlarga2: <FormattedMessage id="app.sabiasque.desclargo.1.2"/>,
    descripcionlarga3: <FormattedMessage id="app.sabiasque.desclargo.1.3"/>,
    descripcionlarga4: <FormattedMessage id="app.sabiasque.desclargo.1.4"/>,
    url:"https://falabella.airavirtual.com/offer_info/B673kVNCinevRIVb5luq?fbrefresh=STPm1B6TRvQvIzI8&id=1678396020"
		
	},

	{
		id: 3,
		image: imageRounded3,
    clase: "",
		title: <FormattedMessage id="app.sabiasque.title.3"/>,
    descripcion: <FormattedMessage id="app.sabiasque.desc.3"/>,
    descripcionlarga1: <FormattedMessage id="app.sabiasque.desclargo.3.1"/>,
    descripcionlarga2: <FormattedMessage id="app.sabiasque.desclargo.3.2"/>,
    descripcionlarga3: <FormattedMessage id="app.sabiasque.desclargo.3.3"/>,
    descripcionlarga4: <FormattedMessage id="app.sabiasque.desclargo.3.4"/>,
    url:"#"
  }
    ];
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-8 bg-dark-sabiasque" id="sabias" name="sabias">
        <div className="container" data-testid="job-item">
        <Row className="justify-content-center">
            <Col lg={4} md={4} sm={12} className="text-start p-2">
                <h3
                className={`mb-5 display-4 fw-bold text-white  ${
                    isLargeScreen ? "classH3Size" : "classH4Size"
                }`}
                >
                <FormattedMessage id="app.sabiasque.title"/><span style={{ color: "yellow" }}>{"()"}</span>
                </h3>
            <div className="sizeSabiasque">
                <span style={{ color: "yellow" }}>{"<h1>"}</span>
            </div>
            <div >
                <span className="sizeSabiasque" style={{ color: "#10C7F9" }}>#FalabellaTransforma</span>
                <br></br>
                <span className="sizeSabiasque" style={{ color: "#F052D7" }}>Mundo Tech</span>
            </div>
            <div className="sizeSabiasque">
                <span style={{ color: "yellow" }}>{"<h1>"}</span>
            </div>
            </Col>
            <Col lg={8} md={8} sm={12} className="text-start p-2">
                <div data-testid="slider">
                    <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper" >
                        {ProtaData.length > 0 &&
                        ProtaData.map((item, index) => (
                            <div
                            className="px-md-1 wow zoomIn"
                            data-wow-duration="1s"
                            data-wow-delay={`${index * 0.2}s`}
                            data-wow-offset="5"
                            key={index}
                            >
                                <div>
                                    <Card className={`border border-card-sabiasque`}>
                                        <center>
                                        <Card.Img className="p-2" variant="top" src={item.image} style={{ width:"300px", height:"auto",borderRadius: "2.5rem" }} />
                                        </center>
                                        <Card.Body className="">
                                            <Row className="justify-content-center">
                                                <Col lg={12} md={12} sm={12} className="">
                                                    <span className="h4 mb-1">
                                                        <b>
                                                            <span style={{ color: '#00D1FF' }}>{item.title}</span>
                                                        </b>
                                                    </span>
                                                    <br></br>
                                                    <br></br>
                                                    <span className="h4 ">
                                                        <span style={{ color: '#ffffff' }}>{item.descripcion}</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div style={{ textAlign: 'center' }}>
                                              <span className="btn rounded-pill mx-2  btn-tech btn btn-primary" key={item.title} onClick={() => handleShow(item.title, item.descripcion,item.image,item.descripcionlarga1,item.descripcionlarga2,item.descripcionlarga3,item.descripcionlarga4,item.url)}>
                                                  <b>
                                                  Ver más {'->'}
                                                  </b>
                                              </span>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Col>
        </Row>
        <Modal id="mi-modal-sabias" size="lg" show={show} onHide={handleClose}>
          <Modal.Body>
            <Card className={`border border-card-sabiasque-modal`}>
                <center>
                <Card.Img className="p-2" variant="top" src={modalInfo.image} style={{ width:"300px", height:"auto",borderRadius: "2.5rem" }} />
                </center>
                <Card.Body className="">
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} className="">
                            <span className="h4 mb-1">
                                <b>
                                    <span style={{ color: '#00D1FF' }}>{modalInfo.title}</span>
                                </b>
                            </span>
                            <br></br>
                            <br></br>
                            <span className="h4 ">
                                <span style={{ color: '#ffffff' }}>{modalInfo.desc1}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span style={{ color: '#ffffff' }}>{modalInfo.desc2}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span style={{ color: '#ffffff' }}>{modalInfo.desc3}</span>
                            </span>
                            <br></br><br></br>
                            <span className="h4 ">
                                <span style={{ color: '#ffffff' }}>{modalInfo.desc4}</span>
                            </span>
                            {modalInfo.url !== "#" && 
                              <div className='mt-3'>
                                  <a
                                  rel='noopener noreferrer'
                                  href={`${modalInfo.url}`}
                                  target="_blank"
                                >
                                  <Button variant="secondary" className="rounded-pill">
                                    <FormattedMessage id="app.sabiasque.ir"/>
                                  </Button>
                                </a>
                              </div>
                            }
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div style={{ textAlign: 'center' }}>
                      <span className="btn rounded-pill mx-2  btn-tech btn btn-primary" onClick={handleClose}>
                          <b>
                          Cerrar
                          </b>
                      </span>
                    </div>
                </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
        </div>
    </div>
  );
};

export default Sabiasque;
