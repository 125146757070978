export const logos_color = [
  {
    id: "1",
    srcWeb: require("assets/images/falabella/color/falabellacorp-color.png"),
    url:"/falabellacorp"
  },
  {
    id: "2",
    srcWeb: require("assets/images/falabella/color/fcom-color-new.png"),
    url:"/falabellaretail"
  },
  {
    id: "3",
    srcWeb: require("assets/images/falabella/color/sodimac-color.png"),
    url:"/sodimac"
  },
  {
    id: "4",
    srcWeb: require("assets/images/falabella/color/tottus-color.png"),
    url:"/tottus"
  },
  {
    id: "5",
    srcWeb: require("assets/images/falabella/color/mallplaza-color.png"),
    url:"/mallplaza"
  },
  {
    id: "6",
    srcWeb: require("assets/images/falabella/color/banco-color.png"),
    url:"/bancofalabella"
  }
  
];
