/* eslint-disable no-unused-vars */
import { v4 as uuid } from "uuid";
import {FormattedMessage} from 'react-intl';

const NavbarDefault = [
  
  {
    id: 1,
    menuitem: <FormattedMessage id="app.menu.oportunidad.titulo"/>,
    children: [
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.oportunidad.ofertas"/>,
        menuAnchor: "paises",
        link: "/explorar",
        icon: "fe fe-heart",
        color: "#BC2D55",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.oportunidad.muevete"/>,
        menuAnchor: "espacios",
        link: "/muevete",
        icon: "fe fe-home",
        color: "#3E3E3D",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.oportunidad.jovenes"/>,
        menuAnchor: "jovenes",
        link: "/jovenes-profesionales",
        icon: "fe fe-hash",
        color: "#60B132",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.oportunidad.tech"/>,
        menuAnchor: "cultura",
        link: "/falabellatech",
        icon: "fe fe-hash",
        color: "#60B132",
      },
     
    ],
  },
  {
    id: uuid(),
    menuitem: <FormattedMessage id="app.menu.experiencia"/>,
    children: [
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.somosfalabellamundo"/>,
        menuAnchor: "paises",
        link: "/#paises",
        icon: "fe fe-heart",
        color: "#BC2D55",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.simplificaydisfruta"/>,
        menuAnchor: "proposito",
        link: "/#proposito",
        icon: "fe fe-gift",
        color: "#3E54A1",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.megustafalabella"/>,
        menuAnchor: "cultura",
        link: "/#cultura",
        icon: "fe fe-hash",
        color: "#60B132",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.nuestrasoficinas"/>,
        menuAnchor: "espacios",
        link: "/#espacios",
        icon: "fe fe-home",
        color: "#3E3E3D",
      },
      {
        id: uuid(),
        menuitem: <FormattedMessage id="app.menu.quienessomos"/>,
        menuAnchor: "conocemas",
        link: "/#footer",
        icon: "fe fe-user-check",
        color: "#3E3E3D",
      },
    ],
  }
];


export default NavbarDefault;
