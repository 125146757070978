import React from 'react';
import Slider from "react-slick";
import imageRounded1 from 'assets/images/falabella/jovenes/despegar/Alejandra_Heridia.jpg';
import imageRounded2 from 'assets/images/falabella/jovenes/despegar/Giancarlos_Lopez.jpg';
import imageRounded3 from 'assets/images/falabella/jovenes/despegar/Jaime_Carafi.jpg';
import imageRounded4 from 'assets/images/falabella/jovenes/despegar/Antonia_Ramello.jpg';
import imageRounded5 from 'assets/images/falabella/jovenes/despegar/Josefa_Munoz_Solis.jpg';

import {Col,Row,Card, Image } from "react-bootstrap";
import "./Despegar.style.css";
import { Avatar } from "components/elements/bootstrap/Avatar";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
        data-testid="next-arrow"
      className={
        "arrowDerechaViveExperienciaJovenes slick-next-ViveExperienciaJovenes"
      }
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"arrowIzqViveExperienciaJovenes slick-prev-ViveExperienciaJovenes"}
      onClick={onClick}
    ></div>
  );
}
const Despegar = (param) => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  const ProtaData = [
    {
      id: 1,
      image: imageRounded1,
      clase: "borderBlanco",
      title: <FormattedMessage id="app.jovenes.testimonio.nombre.1"/>,
      cargo: <FormattedMessage id="app.jovenes.testimonio.cargo.1"/>,
      descripcion: <FormattedMessage id="app.jovenes.testimonio.desc.1"/>,
    },
    {
        id: 2,
        image: imageRounded2,
        clase: "borderBlanco",
        title: <FormattedMessage id="app.jovenes.testimonio.nombre.2"/>,
        cargo: <FormattedMessage id="app.jovenes.testimonio.cargo.2"/>,
        descripcion: <FormattedMessage id="app.jovenes.testimonio.desc.2"/>,
    },
    {
      id: 3,
      image: imageRounded3,
      clase: "borderBlanco",
      title: <FormattedMessage id="app.jovenes.testimonio.nombre.3"/>,
      cargo: <FormattedMessage id="app.jovenes.testimonio.cargo.3"/>,
      descripcion: <FormattedMessage id="app.jovenes.testimonio.desc.3"/>,
    },
    {
      id: 4,
      image: imageRounded4,
      clase: "borderBlanco",
      title: <FormattedMessage id="app.jovenes.testimonio.nombre.4"/>,
      cargo: <FormattedMessage id="app.jovenes.testimonio.cargo.4"/>,
      descripcion: <FormattedMessage id="app.jovenes.testimonio.desc.4"/>,
    },
    {
      id: 5,
      image: imageRounded5,
      clase: "borderBlanco",
      title: <FormattedMessage id="app.jovenes.testimonio.nombre.5"/>,
      cargo: <FormattedMessage id="app.jovenes.testimonio.cargo.5"/>,
      descripcion: <FormattedMessage id="app.jovenes.testimonio.desc.5"/>,
    },
  ];
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          height: "15px",
          width: "15px",
          border: "2px solid black",
          backgroundColor: "#fff",
          borderRadius: "50%",
          display: "inline-block",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="py-8 bg-experiencia-cargos-despegar" id="prota" name="prota">
        <div className="container" data-testid="job-item">
        <Row className="mb-10 justify-content-center">
            <Col lg={12} md={12} sm={12} className="text-center p-4">
                <h3
                className={`mb-2 display-4 fw-bold text-white  ${
                    isLargeScreen ? "classH3Size" : "classH4Size"
                }`}
                >
                <FormattedMessage id="app.jovenes.despegar.1"/> <span style={{ color: "#CD0B51" }}><FormattedMessage id="app.jovenes.despegar.2"/></span>
                </h3>
            </Col>
        </Row>
          <div data-testid="slider">
            <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
                {ProtaData.length > 0 &&
                    ProtaData.map((item, index) => (
                    <div
                        className="px-md-1 wow zoomIn"
                        data-wow-duration="1s"
                        data-wow-delay={`${index * 0.2}s`}
                        data-wow-offset="5"
                        key={index}
                    >
                        <div>
                        <Card className={`mb-4  border-card border-card-experiencia-cargos-despegar`}>
                            <Card.Body className="">
                            <Row className="mb-10 justify-content-center">
                                <Col lg={6} md={6} sm={12} className="text-center align-self-center">
                                <center>
                                    <div className="avatar-container">
                                    <Avatar
                                        size={isLargeScreen ? "xxl2" : "l"}
                                        type="image"
                                        src={item.image}
                                        className={`rounded-circle ubicacion ${item.clase} mt-3`}
                                        alt="G K"
                                    />
                                    </div>
                                </center>
                                </Col>
                                <Col lg={6} md={6} sm={12} className={isLargeScreen ? "mt-11" : "mt-12"}>
                                <Image
                                    style={{ height: "auto", width: isLargeScreen ? "3vw" : "8vw", position:"absolute" }}
                                    src={require("assets/images/falabella/jovenes/comillas.png")}
                                />
                                <div className='p-5'>
                                    <span className="h4">
                                        <span>
                                           
                                            {item.descripcion}
                                          
                                        </span>
                                    </span>
                                </div>
                                <br></br>
                                <div className={isLargeScreen ? "p-5" : ""}>
                                    <span className="h3">
                                        <b>
                                            <span style={{ color: '#2B49C0' }}>{item.title}</span>
                                        </b>
                                    </span>
                                    <br></br>
                                    <span className="h4">
                                        <span>{item.cargo}</span>
                                    </span>
                                    <div className="color-bar mt-3">
                                      <div className="color-block blue-falabella"></div>
                                      <div className="color-block green-falabella"></div>
                                      <div className="color-block red-falabella"></div>
                                      <div className="color-block gray-falabella"></div>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                            </Card.Body>
                        </Card>
                        </div>
                    </div>
                ))}
            </Slider>
          </div>
        </div>
    </div>
  );
};

export default Despegar;
