import axios from "axios";

export class OfertasLaboralesService {
  url;
  constructor(type) {
    this.url = process.env.REACT_APP_API_URL + "ofertalaboral";
    this.type = type;
    this.urlKey = process.env.REACT_APP_API_URL;
    this.token = process.env.REACT_APP_JWT_SECRET;
    this.url_bff = process.env.REACT_APP_URL_BFF;
  }

  async getOfertasLaborales(resource, config) {
    config = {
      headers: {
        authorization: `${this.token}`
      },
    }; 
    return axios.get(`${this.url_bff}/bff-sgdt-job-offer/api/ofertalaboral/${this.type}/${resource}`, config);
  }

  async getAllOfertasLaborales(resource, config) {
    config = {
      headers: {
        authorization: `${this.token}`
      },
    };   
    return axios.get(`${this.url_bff}/bff-sgdt-job-offer/api/ofertalaboral/type/${this.type}`, config);
  }

  async getOfertasLaboralesByFilter(resource, config) {
    config = {
      headers: {
        authorization: `${this.token}`
      },
    };
    return axios.post(`${this.url_bff}/bff-sgdt-job-offer/api/ofertalaboral/filter`, resource, config);
  }

  async postLoggingMuevete(eventType, userDetails, deviceInfo) {
    const postData = {
      eventType: eventType,
      userDetails: userDetails,
      deviceInfo: deviceInfo
    };
    return axios.post(`${this.url_bff}/bff-sgdt-job-offer/api/ofertalaboral/logEvent`, postData);
  }

  async getVideo(fileName) {
    const config = {
      headers: {
        authorization: `${this.token}`,
        'Content-Type': 'video/mp4' 
      },
      responseType: 'blob' 
    };
    return axios.get(`${this.url_bff}/bff-sgdt-job-offer/api/ofertalaboral/media/${fileName}`, config);
  }
}
