// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import ImageMapper from "react-img-mapper";
import { useHistory } from "react-router-dom";
import arrow from "assets/images/img/arrow-right-green.png";
import "./mapping.style.css";
import { FilterContext } from "hooks/FilterContext";
import { Col, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Cinema from "../../Cinema/Cinema";
import Slider from "react-slick";
import {FormattedMessage} from 'react-intl';
import colombiaImg from "assets/images/falabella/banderas/colombia.png";
import peruImg from "assets/images/falabella/banderas/peru.png";
import argentinaImg from "assets/images/falabella/banderas/argentina.png";
import chileImg from "assets/images/falabella/banderas/chile.png";
import uruguayImg from "assets/images/falabella/banderas/uruguay.png";
import brasilImg from "assets/images/falabella/banderas/brasil.png";
import mexicoImg from "assets/images/falabella/banderas/mexico.png";
import chinaImg from "assets/images/falabella/banderas/china.png";
import indiaImg from "assets/images/falabella/banderas/india.png";
import { OfertasLaboralesService } from '../../../../../services/OfertasLaborales.service'

export const VideoFiles = {
  ECOSYSTEM: 'ecosistema.mp4',
}

const Persons = (props) => {
  const history = useHistory();
  const [imagen, setImagen] = useState(null);
  const [showCinema, setShowCinema] = useState(false);
  const [videoURL, setVideoURL] = useState('');

  const service = new OfertasLaboralesService();
  
  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };

  useEffect(() => {
    setImagen(require("assets/images/falabella/mapa-falabella.png"));
    getVideoURL(VideoFiles.ECOSYSTEM).then(response => setVideoURL(response))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isSmallscreen = useMediaQuery({ maxWidth: 700 });

  const { setFilters } = useContext(FilterContext);

  const urlPaisPin = (selectedIndex, e) => {
    const payload = {
      country: [selectedIndex],
      area: [],
      company: [],
      jobtype: [],
    };
    setFilters(payload);
    history.push(`/explorar/${selectedIndex}`);
  };

  const settingsMobile = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  

  const paises = [
    {
      id: "1",
      srcWeb: colombiaImg,
      pais: "Colombia",
    },
    {
      id: "2",
      srcWeb: peruImg,
      pais: "Perú",
    },
    {
      id: "3",
      srcWeb: argentinaImg,
      pais: "Argentina",
    },
    {
      id: "4",
      srcWeb: chileImg,
      pais: "Chile",
    },
    {
      id: "5",
      srcWeb: uruguayImg,
      pais: "Uruguay",
    },
    {
      id: "6",
      srcWeb: brasilImg,
      pais: "Brasil",
    },
    {
      id: "7",
      srcWeb: mexicoImg,
      pais: "México",
    },
    {
      id: "8",
      srcWeb: chinaImg,
      pais: "China",
    },
    {
      id: "9",
      srcWeb: indiaImg,
      pais: "India",
    },
  ];
  

  const listPais = paises.map((item) => (
    <div className="pb-5" key={item.id}>
      <Image
        className="align-middle marginMobileCaraouselLeft"
        src={item.srcWeb}
        onClick={() => {
          urlPaisPin(item.pais);
        }}
      />
      <p className="align-middle" style={{ marginLeft: "10px" }}>
        {item.pais}
      </p>
    </div>
  ));
  

  const mapConfig = useMemo(() => {
    return {
      map: {
        name: "my-map",
        areas: [
          {
            name: "title-pais-india",
            shape: "circle",
            coords: [1065, 268, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "India",
            pais: "India",
          },
          {
            name: "title-pais-china",
            shape: "circle",
            coords: [1145, 170, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "China",
            pais: "China",
          },
          {
            name: "title-pais-brasil",
            shape: "circle",
            coords: [435, 370, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "Brasil",
            pais: "Brasil",
          },
          {
            name: "title-pais-colombia",
            shape: "circle",
            coords: [370, 340, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "Colombia",
            pais: "Colombia",
          },
          {
            name: "title-pais-mexico",
            shape: "circle",
            coords: [245, 285, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "México",
            pais: "México",
          },
          {
            name: "title-pais-peru",
            shape: "circle",
            coords: [370, 420, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "Perú",
            pais: "Perú",
          },
          {
            name: "title-pais-chile",
            shape: "circle",
            coords: [355, 500, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            nombre: "TOMÁS VALDÉS",
            cargo: "Gerente Corporativo de Jardín y Temporada",
            formato: "Sodimac Corporativo",
            pais: "Chile",
            image: require("assets/images/falabella/DESKTOP FALABELLA VB4-mapa-60.png"),
            tipo: "chile",
          },
          {
            name: "title-pais-argentina",
            shape: "circle",
            coords: [400, 480, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            nombre: "ANTONELLA CAMILA TILLI",
            cargo: "Comunicaciones Internas",
            formato: "Corporativas",
            pais: "Argentina",
            image: require("assets/images/falabella/DESKTOP FALABELLA VB4-mapa-61.png"),
            tipo: "argentina",
          },
          {
            name: "title-pais-uruguay",
            shape: "circle",
            coords: [480, 410, 15],
            fillColor: "rgba(200, 54, 54, 0.0)",
            strokeColor: "rgba(200, 54, 54, 0.0)",
            paisTitle: "Uruguay",
            pais: "Uruguay",
          },
        ],
      },
    };
  }, []);

  return (
    <div>
      {isLargeScreen ? (
        <div
          className={"d-block py-4" + (isSmallscreen ? " float-start" : "")}
          style={{ overflowX: "hidden" }}
        >
          <center>
            <Row>
              <div className="img-mapper-container">
                {imagen && (
                  <ImageMapper
                    src={imagen}
                    map={mapConfig.map}
                    width={1480}
                    height={600}
                    onClick={(area) => {
                      urlPaisPin(area.pais);
                    }}
                  />
                )}
              </div>
              <div className="d-none d-lg-block floating-text">
                <h4 className="fs-2 mb-2 display-4 text-start">
                  <Image src={arrow} style={{ width: "4%" }} /> <FormattedMessage id="app.mapa.encuentralasoportunidades"/>
                </h4>
              </div>
            </Row>
            <Row className="pt-12">
              <Col md="4"></Col>
              <Col
                xs="4"
                md="12"
                className={"pt-3 " + !isLargeScreen ? "" : ""}
              >
                <div
                  className="ecosistema"
                  onClick={() => setShowCinema(true)}
                  style={{ marginRight: isLargeScreen ? "" : "16%" }}
                >
                  <span className="fa fa-play-circle"></span><FormattedMessage id="app.mapa.botonconoceecosistema"/>
                </div>
              </Col>
              <Col md="4"></Col>
            </Row>
          </center>
        </div>
      ) : (
        <div>
          <div className="text-center">
            <div className="bg-carousel-mapas-mobile mt-8">
              <Slider {...settingsMobile}>{listPais}</Slider>
            </div>
            <Row className="pt-12 text-center centerBotonMobile">
            <Col className={"centerBotonMobile"}>
              <div
                className="ecosistema centerBotonMobile"
                onClick={() => setShowCinema(true)}
              >
                <span className="fa fa-play-circle"></span><FormattedMessage id="app.mapa.encuentralasoportunidades"/>
              </div>
            </Col>
            <Col md="4"></Col>
          </Row>
          </div>

        </div>
      )}
      <Cinema
        url={videoURL}
        show={showCinema}
        setShow={setShowCinema}
      />
    </div>
  );
};

export default Persons;
