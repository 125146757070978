import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Moment from "react-moment";
import "moment/locale/es";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { mdiChevronRight } from "@mdi/js";
import dataEmpresas from "../Filters/mueveteData.json";
import { useKeycloak } from "@react-keycloak/web";


import {
  faBriefcaseClock,
  faCity,
  faEarthAmericas,
  faPersonDotsFromLine,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@mdi/react";
import { FormattedMessage } from "react-intl";
const JobCard = (props) => {
  const { item, type } = props;
  const [idiomaMoment, setIdiomaMoment] = useState("");
  const { keycloak } = useKeycloak();
  const isAuth = keycloak.authenticated;
  const finalType = isAuth ? "internal" : type;
  const intl = useIntl();
  useEffect(() => {
    if (intl.locale === "es-ES") {
      setIdiomaMoment("es");
    } else {
      setIdiomaMoment("en");
    }
    //eslint-disable-next-line
  }, []);

  const itemFinalCompanyNameArray = dataEmpresas.filter(function (dataSource) {
    return (
      dataSource.Company_Code === item.company_code &&
      dataSource.Company_Code !== "falabella_equipo_corporativo"
    );
  });

  let company;

  if (itemFinalCompanyNameArray.length === 0) {
    if (
      item.company_code === "falabella_equipo_corporativo" &&
      (!item.requisition_company || item.requisition_company === "undefined")
    ) {
      company = "Falabella Corporativo";
    } else {
      if (item.company_code === "falabella_equipo_corporativo") {
        if (
          item.requisition_company === "Falabella.com Chile" ||
          item.requisition_company === "Falabella.com Colombia" ||
          item.requisition_company === "Falabella.com Perú" ||
          item.requisition_company === "Falabella.com Regional" ||
          item.requisition_company === "Home Delivery" ||
          item.requisition_company === "Home Delivery Perú"
        ) {
          company = "Falabella.com";
        } else if (
          item.requisition_company === "Fpay Argentina" ||
          item.requisition_company === "Fpay Chile"
        ) {
          company = "Banco Falabella";
        } else if (
          item.requisition_company === "Fazil Chile" ||
          item.requisition_company === "Fazil"
        ) {
          company = "Tottus";
        } else if (
          item.requisition_company === "Linio Colombia" ||
          item.requisition_company === "Linio México"
        ) {
          company = "Linio";
        } else if (
          item.requisition_company === "Falabella Servicios" ||
          item.requisition_company === "Falabella Retail Chile" ||
          item.requisition_company === "Falabella SA Chile" ||
          item.requisition_company === "Falabella Tecnología Corporativo" ||
          item.requisition_company === "Seguros Falabella" ||
          item.requisition_company === undefined
        ) {
          company = "Falabella Corporativo";
        } else if (item.requisition_company === "Tottus Perú") {
          company = "Tottus";
        } else if (
          item.requisition_company === "Falabella Financiero" ||
          item.requisition_company === "Falabella Financiero."
        ) {
          company = "Falabella Financiero";
        } else if (item.requisition_company === "Sodimac Chile") {
          company = "Sodimac";
        }
      } else {
        const itemFiltro = dataEmpresas.filter(function (dataSourceFiltro) {
          return (
            dataSourceFiltro.Requisition_company !== "undefined" ||
            !dataSourceFiltro.Requisition_company
          );
        });
        if (itemFiltro.length === 0) {
          company = itemFiltro[0].muevete;
        }
      }
    }
  } else {
    company = itemFinalCompanyNameArray[0].muevete;
  }
  return (
    <Link
      to={`/detalle-oferta/${item.offer_id}/${finalType}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Card className="mb-4 card-jobs h-100">
        <Card.Body>
          <p className="fs-4 text-dark fw-bold pb-4">{item.title}</p>
          <span className="h5">
            <FontAwesomeIcon icon={faCity} color="#92BE49" /> {company} <br />
            <FontAwesomeIcon icon={faEarthAmericas} color="#92BE49" />{" "}
            {item.country}, {item.state === item.city ? item.city : `${item.state}, ${item.city}`}<br />
            <FontAwesomeIcon icon={faPersonDotsFromLine} color="#92BE49" />{" "}
            {item.area} <br />
            <FontAwesomeIcon icon={faBriefcaseClock} color="#92BE49" />{" "}
            {item.jobtype} <br />
            <FontAwesomeIcon icon={faCalendar} color="#92BE49" />{" "}
            <Moment locale={idiomaMoment} fromNow className="text-capitalize">
              {item.date}
            </Moment>
          </span>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button className="rounded-pill m-0">
            <FormattedMessage id="app.explorar.boton.vermas" />{" "}
            <Icon path={mdiChevronRight} size="20" />{" "}
          </Button>
        </Card.Footer>
      </Card>
    </Link>
  );
};

JobCard.propTypes = {
  item: PropTypes.object,
};

export default JobCard;
