import { Col, InputGroup, Image, FormControl, Button } from "react-bootstrap";
import arrow from "assets/images/img/arrow-right.png";
import { Fragment, useState,useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player";
import Cinema from "../Cinema/Cinema";
import searchIcon from "assets/images/falabella/search-icon.svg";
import play from "assets/images/falabella/DESKTOP FALABELLA VB4-39.png";
import { useHistory } from "react-router-dom";
import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'


export const VideoFiles = {
  LARGE_VIDEO: 'te_gusta_lo_que_ves.mp4',
};

const TeGustaLoQueVes = () => {
  const [videoURL, setVideoURL] = useState('');
  const service = new OfertasLaboralesService();
  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };

    
  useEffect(() => {
    getVideoURL(VideoFiles.LARGE_VIDEO)
      .then(response => setVideoURL(response))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const largeVideoURL = "https://storage.googleapis.com/qa-rrhh-tama-onbd-internal.appspot.com/te_gusta_lo_que_ves.mp4";
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory(); 
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams({ search: searchTerm }).toString();
    history.push(`/explorar?${queryParams}`);
  };
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isLargeScreen = useMediaQuery({ maxWidth: 767 });
  const searchPlaceholder = intl.formatMessage({ id: 'app.muevete.busqueda' });


  return (
    <div className="py-4" id="cultura" name="cultura">
      <Fragment>
        <div
          style={{
            position: "absolute",
            zIndex: 8,
            width: "100%",
            top:isLargeScreen ? "100%" : "5%",
            transform: "translateY(-30%)",
            padding: isLargeScreen ? "0 10px" : "0 100px",
          }}
        >
          <form
            onSubmit={handleSearchSubmit}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <InputGroup
              className="mb-3"
              style={{
                width: "80%",
                borderRadius: "50px",
                overflow: "hidden",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <FormControl
                type="search"
                placeholder={searchPlaceholder}
                aria-label="Buscar"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ border: "none", paddingLeft: "24px" }}
              />

              <Button
                variant="outline-success"
                type="submit"
                style={{
                  border: "none",
                  borderRadius: "50px",
                  padding: "0.375rem 0.75rem",
                  backgroundColor: "#60B132",
                  color: "#FFFFFF"
                }}
              >
                <Image
                  src={searchIcon}
                  alt="Buscar"
                  style={{ height: "20px", marginRight: "12px" }}
                />{" "}
                {}
                <FormattedMessage
                  id="app.video.searchjobs"
                  defaultMessage="Buscar Empleos"
                />
              </Button>
            </InputGroup>
          </form>
        </div>

        <div
          className="video-cultura"
          style={{
            position: "relative",
            borderRadius: "50px",
            overflow: "hidden",
            padding: isLargeScreen? "20px" : "120px",
            
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              opacity: 0.3,
              zIndex: 3,
            }}
          />
          <ReactPlayer
            url={videoURL}
            controls={false}
            loop
            muted
            playing
            width="100%"
            style={{
              width: "100%",
              opacity: isLargeScreen ? "90%" : "",
              backgroundColor: isLargeScreen ? "#3E3E3D" : "",
              borderRadius: '50px'
            }}
          />
        </div>
        {isLargeScreen ? (
          <div className="mensaje">
            <center>
              <Col
                lg={8}
                md={8}
                sm={8}
                className="text-center text-white zoom-video-text"
              >
                <h4
                  className={`mb-2 display-4 fw-bold ${
                    !isLargeScreen ? "" : "classH4Size"
                  }`}
                >
                  <div className="text-white">
                    <Image src={arrow} style={{ width: "6%" }} />
                    <FormattedMessage id="app.blog.data.titulo.tegusta" />{" "}
                    <br />
                    <FormattedMessage id="app.blog.data.titulo.asi" />{" "}
                    <span style={{ color: "#92BE49" }}>
                      <FormattedMessage id="app.blog.data.titulo.vivimos" />
                    </span>{" "}
                    <FormattedMessage id="app.blog.data.titulo.falabella" />
                  </div>
                </h4>
              </Col>
              <Col lg={4} md={4} sm={8} xs={8}>
                <h4
                  className="mb-2 fw-bold text-white text-center zoom-video-text"
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={handleShow}
                >
                  <Image src={play} style={{ width: "25%" }} />{" "}
                  <FormattedMessage id="app.video.vervideo" />
                </h4>
              </Col>
            </center>
          </div>
        ) : (
          <div>
            <Col lg={12} md={12} sm={12} className="text-center">
              <div className="mensajetitle p-8">
                <h3
                  className={`mb-2 display-4 fw-bold ${
                    !isLargeScreen ? "" : "classH3Size"
                  }`}
                >
                  {!isLargeScreen && (
                    <div className="text-white">
                      <Image src={arrow} style={{ width: "6%" }} />
                      <FormattedMessage id="app.blog.data.titulo.tegusta" />
                      <br></br>
                      <FormattedMessage id="app.blog.data.titulo.asi" />{" "}
                      <span style={{ color: "#60B132" }}>
                        <FormattedMessage id="app.blog.data.titulo.vivimos" />
                      </span>{" "}
                      <FormattedMessage id="app.blog.data.titulo.falabella" />
                    </div>
                  )}
                </h3>
                <h4
                  className="mb-2 fw-bold text-white zoom-video-text"
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={handleShow}
                >
                  <Image src={play} style={{ width: "7%" }} />{" "}
                  <FormattedMessage id="app.video.vervideo" />
                </h4>
              </div>
            </Col>
          </div>
        )}
      </Fragment>
      <Cinema
        show={show}
        setShow={handleClose}
        url={videoURL}
      />
    </div>
  );
};

export default TeGustaLoQueVes;
