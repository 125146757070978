import { v4 as uuid } from "uuid";
import {FormattedMessage} from 'react-intl';

export const AllExperienciaData = [
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/procesos-estrategia-transformacion-digital.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.procesosestrategiaytransformaciondigital"/>,
    param: ["Procesos, Estrategía y Tranformación Digital"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/producto-digital.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.productodigitalytecnologia"/>,
    param: ["Producto Digital", "Tecnología e Informática"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/recursos-humanos.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.personas"/>,
    param: ["Recursos Humanos"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/data-business-intelligence.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.dataybusinessintelligence"/>,
    param: ["Data y Business Intelligence"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/logistica-compras.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.logisticacomprasyabastecimiento"/>,
    param: ["Logística, Compras y Abastecimiento"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/venta-post-venta.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.ventaypostventa"/>,
    param: [
      "Venta y Post Venta -  Personas (B2C)",
      "Venta y Post Venta - Empresas (B2B)",
    ],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/finanzas-control-gestion-contabilidad.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.finanzascontroldeGestionycontabilidad"/>,
    param: ["Finanzas, Control de Gestión y Contabilidad"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/finanzas-control-gestion-contabilidad.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.inmobiliaria"/>,
    param: ["Inmobiliaria"],
    blog: true,
  },
  {
    id: uuid(),
    image: require("assets/images/falabella/areas/administracion-secretarias.jpg"),
    title: <FormattedMessage id="app.viveexperiencia.data.administracionysecretarias"/>,
    param: ["Administración y Secretarías"],
    blog: true,
  },
];

export default AllExperienciaData;
