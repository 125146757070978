/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const sectionList = [
  { section: "presencia" },
  { section: "paises" },
  { section: "testimonios" },
  { section: "cultura" },
  { section: "proposito" },
  { section: "espacios" },
  { section: "areas" },
  { section: "ofertas" },
  { section: "conocemas" },
  { section: "eventos" },
  { section: "footer" },
  { section: "paisesinternos" },
  { section: "propositointernos" },
  { section: "ofertasinternos" },
];
export const SideScroll = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 990 });
  const [sections, setSections] = useState(sectionList);
  const [nextSection, setNextSection] = useState(null);
  const [hideScroll, setHideScroll] = useState(false);

  const calculatePositions = useCallback(() => {
    let sectionsWithPos = sectionList.map((section) => {
      let element = document.getElementById(section.section);
      let offset = 0;
      if (element) {
        let bodyRect = document.body.getBoundingClientRect();
        let elemRect = element.getBoundingClientRect();
        offset = Math.floor(elemRect.top - bodyRect.top) - 1;
      }
      return {
        ...section,
        position: offset,
      };
    });
    setSections(sectionsWithPos);
    return sectionsWithPos[0];
  }, [document.body.getBoundingClientRect()]);

  const calculateNextSection = useCallback(() => {
    let currentPosition = Math.floor(window.scrollY);
    let nextSection = sections.find((section) => {
      return section.position > currentPosition;
    });
    setNextSection(nextSection);
    if (currentPosition >= sections[sections.length - 1].position) {
      setHideScroll(true);
    } else {
      setHideScroll(false);
    }
  }, [sections]);

  useEffect(() => {
    setTimeout(() => {
      let section = calculatePositions();
      setNextSection((prev) => section);
      setTimeout(() => {
        calculateNextSection();
      }, 2000);
    }, 2000);
  }, []);

  window.onscroll = calculateNextSection;
  const scrollDownDeskGif = require("../../../../assets/images/falabella/scroll-down-desktop.gif");
  const scrollDownMobileGif = require("../../../../assets/images/falabella/scroll-down-mobile.gif");

  const handleMoveNextSection = () => {
    calculatePositions();
    calculateNextSection();
    if (nextSection) {
      document.getElementById(nextSection.section).scrollIntoView();
    } else {
      let pos = isLargeScreen ? 0 : 1;
      document.getElementById(sections[pos].section).scrollIntoView();
    }
  };

  return (
    <div>
      {!hideScroll && (
        <Image
          className="scroll-down-floating"
          src={isLargeScreen ? scrollDownDeskGif : scrollDownMobileGif}
          onClick={() => handleMoveNextSection()}
        />
      )}
    </div>
  );
};
