import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { Image, Card, Row, Col } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/es";
import { useKeycloak } from "@react-keycloak/web";

const ItemViveExperienciaCargo = ({ item, logo, icon }) => {
  const [idiomaMoment, setIdiomaMoment] = useState("");
  const intl = useIntl();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (intl.locale === "es-ES") {
      setIdiomaMoment("es");
    } else {
      setIdiomaMoment("en");
    }
  }, [intl.locale]);

  const offerType = keycloak.authenticated ? "internal" : "external";
  return (
    <div>
      <Link
        to={`/detalle-oferta/${item.offer_id}/${offerType}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card className={`mb-4 card-hover border-card-experiencia-cargos`}>
          <center>
            <Image
              src={logo}
              alt=""
              className="card-img-top p-4 border-card-experiencia-item"
              style={{ width: "auto" }}
            />
          </center>
          <Card.Body className="text-center">
            <span className="h4">
              <b>
                <Moment
                  locale={idiomaMoment}
                  fromNow
                  className="text-capitalize"
                >
                  {item.date}
                </Moment>
              </b>
              <br />
              <b>
                {item.title.substring(0, 70)}
                {item.title.length > 70 ? "..." : ""}
              </b>

              <br />

              <br />
              {item.description.substring(0, 100)}
              {item.description.length > 100 ? "..." : ""}
              <br />
              <br />
              <div>
                <Row>
                  <Col xs="3">
                    <Image className="align-middle" src={icon} />
                  </Col>
                  <Col xs="9" className="text-start d-flex align-items-center">
                    {item.country + ", " + item.city}.
                  </Col>
                </Row>
              </div>
            </span>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};

export default ItemViveExperienciaCargo;
