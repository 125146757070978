/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import imageRounded1 from "assets/images/falabella/noticia1.png";
import imageRounded1_ingles from "assets/images/falabella/noticia1_ingles.jpg";
// import sub components
import ItemNoticia from "./components/ItemNoticia";

// import data files
import { AllBlogData } from "./components/AllData";
import { FormattedMessage, useIntl } from "react-intl";
const EventosYNoticias = () => {

  const intl = useIntl();
  const currentLanguage = intl.locale;
  
  const imageRounded1_selected = currentLanguage === "es-ES" ? imageRounded1 : imageRounded1_ingles;
  
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="py-8" id="eventos" name="eventos">
      <div className="container">
      <div className="title-section text-center">
          <h2>
            <span className="title-normal">
              <FormattedMessage id="app.eventosynoticias.titulo1"  />
            </span>{' '}
            <span className="title-bold title-red">
              <FormattedMessage id="app.eventosynoticias.titulo2" />
            </span>{' '}
            <span className="title-normal">
              <FormattedMessage id="app.eventosynoticias.titulo3" />
            </span>{' '}
            <span className="title-bold title-red">
              <FormattedMessage id="app.eventosynoticias.titulo4" />
            </span>
            <br />
            <br />
          </h2>
        
        </div>
        <Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
          {AllBlogData.filter((dataSource) => dataSource.blog).map(
            (item, index) => {
              let tempItem = { ...item }; 

              if (tempItem.id === 1) {
                tempItem.image = imageRounded1_selected; 
              }

              return (
                <div
                  className="px-md-1 wow pulse"
                  data-wow-duration="1s"
                  data-wow-delay={`${index * 0.2}s`}
                  data-wow-offset="5"
                  key={tempItem.id}
                >
                  <ItemNoticia key={index} item={tempItem} extraclass="mx-2" />
                </div>
              );
            }
          )}
        </Slider>
        <center>
          <div className="d-grid gap-2">
            <a
              rel="noreferrer"
              href={
                "https://www.linkedin.com/company/saci-falabella/posts/?feedView=all"
              }
              target="_blank"
            >
              <Button variant="secondary" className="rounded-pill">
                <FormattedMessage id="app.blog.data.vermas" />
              </Button>
            </a>
          </div>
        </center>
      </div>
    </div>
  );
};

export default EventosYNoticias;
