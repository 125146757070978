import { Col, Row, Image } from "react-bootstrap";
import { Fragment, useState, useEffect } from "react";
import arrow from "assets/images/img/arrow-right.png";
import { useMediaQuery } from "react-responsive";
import lupa from "assets/images/falabella/DESKTOP FALABELLA VB4-40.png";
import play from "assets/images/falabella/DESKTOP FALABELLA VB4-39.png";

import { Link } from "react-router-dom";
import Cinema from "../Cinema/Cinema";
import ReactPlayer from "react-player";
import {FormattedMessage} from 'react-intl';
import { OfertasLaboralesService } from '../../../../services/OfertasLaborales.service'

export const VideoFiles = {
  MANIFEST_SUB_1: 'FALABELLA_MANIFIESTO_SIN_SUB_1.mp4',
  MANIFEST_SUB_3: 'FALABELLA_MANIFIESTO_SIN_SUB_3.mp4',
}

const TenemosCultura = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [videoURLs, setVideoURLs] = useState({});

  const service = new OfertasLaboralesService();
  
  const getVideoURL = async (videoName) => {
    try {
      const response = await service.getVideo(videoName);
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      return '';
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      const videoURLs = {
        MANIFEST_SUB_1: await getVideoURL(VideoFiles.MANIFEST_SUB_1),
        MANIFEST_SUB_3: await getVideoURL(VideoFiles.MANIFEST_SUB_3),
      };
      setVideoURLs(videoURLs);
    }

    fetchVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  return (
    <div className="py-4" id="cultura" name="cultura">
      <Fragment>
        <div className="video-cultura">
          <ReactPlayer
            url={videoURLs.MANIFEST_SUB_1}
            controls={false}
            loop
            muted
            playing
            width="100%"
            style={{ width: "100%" }}
          ></ReactPlayer>
        </div>
        {isLargeScreen ? (
          <div className="mensaje" style={{ marginLeft: "10%" }}>
            <Row>
              <Col lg={6} md={8} sm={6} xs={6}>
                <h3 className="mb-2 fw-bold text-white">
                  <Image src={arrow} style={{ width: "6%" }} /> <FormattedMessage id="app.video.atraves"/>
                </h3>
              </Col>
              <Col lg={4} md={4} sm={6} xs={6}>
                <h4
                  className="mb-2 fw-bold text-white zoom-video-text"
                  style={{ cursor: "pointer" }}
                  onClick={handleShow}
                >
                  <Image src={play} style={{ width: "13%" }} /> <FormattedMessage id="app.video.vervideo"/>
                </h4>

                <Link to="/explorar" className="text-inherit">
                  <h4 className="mb-2 fw-bold text-white zoom-video-text">
                    <Image src={lupa} style={{ width: "13%" }} /> <FormattedMessage id="app.video.verofertas"/>
                  </h4>
                </Link>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="mensaje">
            <center>
              <h4 className="mb-2 fw-bold text-white">
                <Image src={arrow} style={{ width: "6%" }} /> <FormattedMessage id="app.video.atraves"/>
              </h4>

              <h4
                className="mb-2 fw-bold text-white zoom-video-text"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                <Image src={play} style={{ width: "10%" }} /> <FormattedMessage id="app.video.vervideo"/>
              </h4>

              <Link to="explorar" className="text-inherit">
                <h4 className="mb-2 fw-bold text-white zoom-video-text">
                  <Image src={lupa} style={{ width: "10%" }} /> <FormattedMessage id="app.video.verofertas"/>
                </h4>
              </Link>
            </center>
          </div>
        )}
      </Fragment>

      <Cinema
        show={show}
        setShow={handleClose}
        url={videoURLs.MANIFEST_SUB_3}
      />
    </div>
  );
};
export default TenemosCultura;
