import React from "react";
import "./Loader.style.css"; // Importa el archivo CSS para estilos del Loader
const logoMenuDark = require("assets/images/falabella/Corporativo.png");
const Loader = () => {
  return (
    <div className="loader">
      <img src={logoMenuDark} alt="Loading" />
    </div>
  );
};

export default Loader;
