/* eslint-disable no-unused-vars */
// import node module libraries
import { Fragment, useEffect } from "react";
import EventosYNoticias from "./EventosYNoticias/EventosYNoticias";
import TuNosImportas from "./TuNosImportas/TuNosImportas";
import ViveExperiencia from "./ViveExperiencia/ViveExperiencia";
import ViveExperienciaCargos from "./ViveExperienciaCargos/ViveExperienciaCargos";
import GrandesEspacios from "./GrandesEspacios/GrandesEspacios";
import TeGustaLoQueVes from "./TeGustaLoQueVes/TeGustaLoQueVes";
import NuestroProposito from "./NuestroProposito/NuestroProposito";
import TenemosCultura from "./TenemosCultura/TenemosCultura";
import { useLocation } from "react-router-dom";
import { OfertasLaboralesService } from "services/OfertasLaborales.service";
import { useKeycloak } from "@react-keycloak/web";
import { useIntl } from "react-intl";
import UAParser from 'ua-parser-js';


const LandingJobs = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname, hash, key } = useLocation();
  const intl = useIntl();

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    document.body.className = "bg-white";
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    const options = {
      damping: 0.07,
      continuousScrolling: true,
    };
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <div className="py-lg-22">
      {keycloak.authenticated && (
          <button onClick={handleLogout} className="logout-button">
            {intl.formatMessage({ id: "app.logout" })}
            {console.log("datos token ",keycloak.tokenParsed)}
            {console.log("datos keycloak ",keycloak)}
            {keycloak.tokenParsed ? `(${keycloak.tokenParsed.email ? keycloak.tokenParsed.email : keycloak.tokenParsed.preferred_username})` : ""}
          </button>
        )}
        <TeGustaLoQueVes />
        <GrandesEspacios />
        <TenemosCultura />
        <EventosYNoticias />
      </div>
    </Fragment>
  );
};

export default LandingJobs;
