import { useMediaQuery } from "react-responsive";
import FooterBrands from "components/falabella/jobs/FooterBrands";
import BrandCarousel from "components/falabella/jobs/BrandCarousel/BrandCarousel";
const directionButtons = (direction) => {
  return (
    <span
      aria-hidden="true"
      className={
        direction === "Next"
          ? "fe fe-arrow-right size-icon"
          : "fe fe-arrow-left size-icon"
      }
    ></span>
  );
};

const FooterInterno = (props) => {
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  return (
    <div id="footer">
      <div className="footer">
        <div style={{ height: "150px", width: "auto", overflow: "hidden" }}>
          <svg
            className="svg-vertical"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{
              height: "100%",
              width: "100%",
              verticalalign: "baseline",
            }}
          >
          <path
            fill={
              props.id === "FOOTER"
                ? "#fff" // Si props.id es "FOOTER", se establece el color de relleno en blanco (#fff)
                : props.id === "PASOS"
                ? "#e0e0e0" // Si props.id es "PASOS", se establece el color de relleno en gris (#e0e0e0)
                : "#111725" // De lo contrario, se establece el color de relleno en #111725
            }
            d="M-13.82,112.02 C240.69,-85.34 325.33,256.09 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none" }}
          />
          </svg>
        </div>
        {isLargeScreen ? (
          <FooterBrands />
        ) : (
          <div className="pb-15">
            <BrandCarousel
              height="9vw"
              controls
              nextIcon={directionButtons("Next")}
              prevIcon={directionButtons("prevew")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
FooterInterno.propTypes = {};

export default FooterInterno;
