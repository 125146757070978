import { Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Image, Card } from "react-bootstrap";
import { FilterContext } from "hooks/FilterContext";

const ItemViveExperienciaInterno = ({ item, extraclass,format }) => {
  const { setFilters } = useContext(FilterContext);
  const history = useHistory();
  const handleClick = async (e) => {
    e.preventDefault();
    const payload = {
      country: [],
      area: [...item.param],
      company: format,
      jobtype: [],
    };
    setFilters(payload);
    history.push("/explorar");
  };
  const GridView = () => {
    return (
      <div>
        <Link to="#" onClick={(e) => handleClick(e)}>
          <Card className={`mb-4 card-hover border-card-experiencia`}>
            <Image
              src={item.image}
              alt=""
              className="card-img-top p-4 border-card-experiencia-item"
            />
            <Card.Body className="">
              <p className="h4">{item.title}</p>
            </Card.Body>
          </Card>
        </Link>
      </div>
    );
  };

  return (
    <Fragment>
      <GridView />
    </Fragment>
  );
};

export default ItemViveExperienciaInterno;
