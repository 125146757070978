import { Col, Row, Image } from "react-bootstrap";

import arrow from "assets/images/img/arrow-right.png";
import { useMediaQuery } from "react-responsive";
import {FormattedMessage} from 'react-intl';

const Head = (param) => {
  let formato = param.format;
  let color;
  let textFormato;
  if(formato === "Sodimac"){
    color = {
      color: '#0073CE',
    };
    textFormato = "app.viveexperiencia.head.tequieressodimac";
  }
  if(formato === "Tottus"){
    color = {
      color: '#92be49',
    };
    textFormato = "app.viveexperiencia.head.tequierestottus";
  }

  if(formato === "Falabella Retail"){
    color = {
      color: '#CFD400',
    };
    textFormato = "app.viveexperiencia.head.tequieresfalabellaretail";
  }

  if(formato === "Mallplaza"){
    color = {
      color: '#E9004B',
    };
    textFormato = "app.viveexperiencia.head.tequieresmallplaza";
  }

  if(formato === "Banco Falabella"){
    color = {
      color: '#007836',
      
    };
    textFormato = "app.viveexperiencia.head.tequieresbancofalabella";
  }
  if(formato === "Falabella Financiero"){
    color = {
      color: '#007836',
    };
    textFormato = "app.viveexperiencia.head.tequieresfalabellafinanciero";
  }
  if(formato === "Falabella Soriana"){
    color = {
      color: '#007836',
    };
    textFormato = "app.viveexperiencia.head.tequieresfalabellasoriana";
  }
  if(formato === "Seguros Falabella"){
    color = {
      color: '#007836',
    };
    textFormato = "app.viveexperiencia.head.tequieressegurosfalabella";
  }
  if(formato === "Falabella Inmobiliario"){
    color = {
      color: '#515254',
    };
    textFormato = "app.viveexperiencia.head.tequieresfalabellainmobiliario";
  }
  if(formato === "Falabella Equipo Corporativo"){
    color = {
      color: '#92be49',
    };
    textFormato = "app.viveexperiencia.head.tequieresfalabellacorp";
  }
  const isLargeScreen = useMediaQuery({ minWidth: 996 });
  return (
    <Row className="mb-10 justify-content-center py-8">
      <Col lg={12} md={12} sm={12} className="text-center">
        <h3
          className={`mb-2 display-4 fw-bold text-color-footer  ${
            isLargeScreen ? "" : "classH3Size"
          }`}
        >
          <Image src={arrow} style={{ width: "7%" }} />
          <span style={color}><FormattedMessage id="app.viveexperiencia.head.ytu"/></span><FormattedMessage id={textFormato}/> 
          <br />
        </h3>
        <h3 className="mb-2 fs-2 text-white fw-bold ">
          <FormattedMessage id="app.viveexperiencia.head.descubre"/>
        </h3>
      </Col>
    </Row>
  );
};
export default Head;
