
import { Container, Row, Col, ListGroup,Card } from "react-bootstrap";
import {FormattedMessage} from 'react-intl';


const items = [
  {
    id: "1",
    formato:"Sodimac",
    number:"39.159",
    title : "app.tunosimportas.foot.persons",
    title2 : "app.tunosimportas.foot.persons2",
    image: require("assets/images/falabella/internos/sodimac/Ico3.png"),
  },
  {
    id: "2",
    formato:"Sodimac",
    number:"242",
    title : "app.tunosimportas.foot.tiendas",
    title2 : "app.tunosimportas.foot.tiendas2",
    image: require("assets/images/falabella/internos/sodimac/Ico2_1.png"),
  },
  {
    id: "3",
    formato:"Sodimac",
    number:"19",
    title : "app.tunosimportas.foot.centro",
    title2 : "app.tunosimportas.foot.centro2",
    image: require("assets/images/falabella/internos/sodimac/Ico1_1.png"),
  },
  {
    id: "4",
    formato:"Tottus",
    number:"155",
    title : "app.tunosimportas.foot.tiendas",
    title2 : "app.tunosimportas.foot.tiendas2",
    image: require("assets/images/falabella/internos/tottus/Ico_carro.png"),
  },
  {
    id: "5",
    formato:"Tottus",
    number:"5",
    title : "app.tunosimportas.foot.centro",
    title2 : "app.tunosimportas.foot.centro2",
    image: require("assets/images/falabella/internos/tottus/Ico_camion.png"),
  },
  {
    id: "6",
    formato:"Tottus",
    number:"9",
    title : "app.tunosimportas.foot.centropro",
    title2 : "app.tunosimportas.foot.centropro2",
    image: require("assets/images/falabella/internos/tottus/Ico_caja.png"),
  },
  {
    id: "7",
    formato:"Tottus",
    number:"1",
    title : "app.tunosimportas.foot.tiendagris",
    title2 : "app.tunosimportas.foot.tiendagris2",
    image: require("assets/images/falabella/internos/tottus/Ico_compras.png"),
  },
  {
    id: "9",
    formato:"Falabella Retail",
    number:"106",
    title : "app.tunosimportas.foot.tiendas",
    title2 : "app.tunosimportas.foot.tiendas2",
    image: require("assets/images/falabella/internos/retail/Ico1.png"),
  },
  {
    id: "10",
    formato:"Falabella Retail",
    number:"6",
    title : "app.tunosimportas.foot.centro",
    title2 : "app.tunosimportas.foot.centro2",
    image: require("assets/images/falabella/internos/retail/Ico2.png"),
  },
  {
    id: "11",
    formato:"Mallplaza",
    number:"762",
    title : "app.tunosimportas.foot.persons",
    title2 : "app.tunosimportas.foot.persons2",
    image: require("assets/images/falabella/internos/mallplaza/1.png"),
  },
  {
    id: "12",
    formato:"Mallplaza",
    number:"25",
    title : "app.tunosimportas.foot.centrourbano",
    title2 : "app.tunosimportas.foot.centrourbano2",
    image: require("assets/images/falabella/internos/mallplaza/2.png"),
  },
  {
    id: "13",
    formato:"Banco Falabella",
    number:"256",
    title : "app.tunosimportas.foot.sucursales",
    title2 : " ",
    image: require("assets/images/falabella/internos/bancofalabella/Ico_tienda_1.png"),
  },
  {
    id: "14",
    formato:"Falabella Inmobiliario",
    number:"301",
    title : "app.tunosimportas.foot.persons",
    title2 : "app.tunosimportas.foot.persons2",
    image: require("assets/images/falabella/internos/falabellainmobiliario/Ico_personas.png"),
  },
  {
    id: "15",
    formato:"Falabella Inmobiliario",
    number:"20",
    title : "app.tunosimportas.foot.sucursales",
    title2 : " ",
    image: require("assets/images/falabella/internos/falabellainmobiliario/Ico_tienda.png"),
  },
  {
    id: "16",
    formato:"Falabella Soriana",
    number:"293",
    title : "app.tunosimportas.foot.sucursales",
    title2 : " ",
    image: require("assets/images/falabella/internos/falabellasoriana/Ico_Stand.png"),
  },
  {
    id: "17",
    formato:"Falabella Soriana",
    number:"820",
    title : "app.tunosimportas.foot.persons",
    title2 : " ",
    image: require("assets/images/falabella/internos/falabellasoriana/Ico_personas.png"),
  },
  {
    id: "18",
    formato:"Seguros Falabella",
    number:"136",
    title : "app.tunosimportas.foot.sucursales",
    title2 : " ",
    image: require("assets/images/falabella/internos/bancofalabella/Ico_tienda_1.png"),
  },
  {
    id: "20",
    formato:"Mallplaza",
    number:"16",
    title : "app.tunosimportas.foot.ciudades",
    title2 : "app.tunosimportas.foot.ciudades2",
    image: require("assets/images/falabella/internos/mallplaza/3.png"),
  }
];

const ComponentFootInterno = (param) => {
  let formato = param.format;
  let color;
  if(formato === "Sodimac"){
    color = {
      color: '#0073CE',
      lineHeight: '10px',
    };
  }
  if(formato === "Tottus"){
    color = {
      color: '#92be49',
      lineHeight: '10px',
    };
  }
  if(formato === "Falabella.com"){
    color = {
      color: '#FF7600',
      lineHeight: '10px',
    };
  }

  if(formato === "Falabella Retail"){
    color = {
      color: '#CFD400',
      lineHeight: '10px',
    };
  }

  if(formato === "Mallplaza"){
    color = {
      color: '#E9004B',
      lineHeight: '10px',
    };
  }

  if(formato === "Banco Falabella"){
    color = {
      color: '#3dae2b',
      lineHeight: '10px',
    };
  }
  if(formato === "Falabella Inmobiliario"){
    color = {
      color: '#515254',
      lineHeight: '10px',
    };
  }
  if(formato === "Falabella Soriana"){
    color = {
      color: '#007836',
      lineHeight: '10px',
    };
  }
  if(formato === "Falabella Financiero"){
    color = {
      color: '#007836',
      lineHeight: '10px',
    };
  }
  if(formato === "Seguros Falabella"){
    color = {
      color: '#007836',
      lineHeight: '10px',
    };
  }

  if(formato === "Falabella Equipo Corporativo"){
    color = {
      color: '#515254',
      lineHeight: '10px',
    };
  }


  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col xl={9} lg={9} md={12}>
            <div className="py-8 py-lg-0 text-center">
              <ListGroup as="ul" bsPrefix="list-inline">
                {items.filter(function (dataSource) {
                  return dataSource.formato === formato;
                }).map((item, index) => (
                    <ListGroup.Item
                      as="li"
                      bsPrefix="list-inline-item text-dark fw-semi-bold lh-1 fs-4 me-3 mb-2 mb-md-0"
                    >

                        <Card className="bg-transparente">
                          <Card.Body>
                            <Card.Img src={item.image}  style={{"height" : "auto", "width" : "120px", textAlign: "center"}}/>
                          </Card.Body>
                          <Card.Body>
                          <Card.Text>
                            <h4 className="align-middle" style={color}>{item.number}</h4>
                          </Card.Text>
                          <Card.Text>
                            <h4 className="align-middle" style={color}><FormattedMessage id={item.title}/></h4>
                          </Card.Text>
                          <Card.Text>
                            <h4 className="align-middle" style={color}><FormattedMessage id={item.title2}/></h4>
                          </Card.Text>
                          </Card.Body>
                        </Card>
 
                    </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ComponentFootInterno;
