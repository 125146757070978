// import courses media files
import {FormattedMessage} from 'react-intl';
import imageRounded2 from 'assets/images/falabella/noticia2.png';
import imageRounded3 from 'assets/images/falabella/noticia3.png';

export const AllBlogData = [
	{
		id: 1,
		title: <FormattedMessage id="app.blog.data.item.1.titulo"/>,
		color: '#92BE49',
		text:  <FormattedMessage id="app.blog.data.item.1.text.1"/>,
		text2:  <FormattedMessage id="app.blog.data.item.1.text.2"/>,
		blog:  true,
	},
	{
		id: 2,
		image: imageRounded2,
		title: <FormattedMessage id="app.blog.data.item.2.titulo"/>,
		color: '#3E54A1',
		text:  <FormattedMessage id="app.blog.data.item.2.text.1"/>,
		text2: <FormattedMessage id="app.blog.data.item.2.text.2"/>,
		blog:  true,
	},
	{
		id: 3,
		image: imageRounded3,
		title: <FormattedMessage id="app.blog.data.item.3.titulo" href="https://bit.ly/3fP7IxK"
		style={{ color: "#5c596d" }} 
		target="_blank"
		rel="noopener noreferrer" >	</FormattedMessage>,
		color: '#BC2D55',
		text:  <a
		className="App-link"
		href="https://bit.ly/3fP7IxK"
		style={{ color: "#5c596d" }} 
		target="_blank"
		rel="noopener noreferrer"
	  ><FormattedMessage id="app.blog.data.item.3.text.1"/></a>,
		text2: <FormattedMessage id="app.blog.data.item.3.text.2" /> ,
		blog:  true,
	}
];

export default AllBlogData;
