/* eslint-disable no-unused-vars */
// import node module libraries
import { Fragment, useEffect } from "react";
import CarouselIndex from "./FalabellaTech/CarouselIndex";
import TeSumas from "./FalabellaTech/TeSumas";
import Aventura from "./FalabellaTech/Aventura";
import Disena from "./FalabellaTech/Disena";
import { useLocation } from "react-router-dom";
import Cargos from "./FalabellaTech/Cargos";
import Protagonista from "./FalabellaTech/Protagonista";
import Sabiasque from "./FalabellaTech/Sabiasque";
import { useIntl } from "react-intl";
import { useKeycloak } from "@react-keycloak/web";

const LandingJobs = (props) => {
  const { pathname, hash, key } = useLocation();
  const { keycloak } = useKeycloak();
  const intl = useIntl();
  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    document.body.className = "bg-white";
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    const options = {
      damping: 0.07,
      continuousScrolling: true,
    };
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <div>
      {keycloak.authenticated && (
          <button onClick={handleLogout} className="logout-button">
            {intl.formatMessage({ id: "app.logout" })}
            {keycloak.tokenParsed ? `(${keycloak.tokenParsed.email ? keycloak.tokenParsed.email : keycloak.tokenParsed.preferred_username})` : ""}
          </button>
        )}
        <CarouselIndex />
        <Aventura />
        <TeSumas />
        <Cargos />
        <Protagonista />
        <Sabiasque />
      </div>
    </Fragment>
  );
};

export default LandingJobs;
