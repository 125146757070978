import { v4 as uuid } from "uuid";

import {FormattedMessage} from 'react-intl';

const NavbarDefault = [
  {
    id: uuid(),
    menuitem: <FormattedMessage id="app.menu.format"/>,
    children: [
      {
        id: uuid(),
        menuitem: "Sodimac",
        menuAnchor: "",
        link: "/sodimac",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Tottus",
        menuAnchor: "",
        link: "/tottus",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Falabella Retail",
        menuAnchor: "",
        link: "/falabellaretail",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Mallplaza",
        menuAnchor: "",
        link: "/mallplaza",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Banco Falabella",
        menuAnchor: "",
        link: "/bancofalabella",
        icon: "",
        color: "",
      },
 
      {
        id: uuid(),
        menuitem: "Falabella Corporativo",
        menuAnchor: "",
        link: "/falabellacorp",
        icon: "",
        color: "",
      }
      ,
      {
        id: uuid(),
        menuitem: "Falabella Inmobiliario",
        menuAnchor: "",
        link: "/falabellainmobiliario",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Falabella Soriana",
        menuAnchor: "",
        link: "/falabellasoriana",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Falabella Financiero",
        menuAnchor: "",
        link: "/falabellafinanciero",
        icon: "",
        color: "",
      },
      {
        id: uuid(),
        menuitem: "Seguros Falabella",
        menuAnchor: "",
        link: "/segurosfalabella",
        icon: "",
        color: "",
      }
    ],
  }
];


export default NavbarDefault;

